import { IConviction } from '@models/create.model';
import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    fontFamily: 'Lato',
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    textAlign: 'center',
    borderWidth: 4,
    borderColor: 'black',
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 50,
  },
  signature: {
    borderWidth: 1,
    borderColor: 'black',
    width: 150,
    height: 50,
    marginTop: 10,
  },
});

Font.register({
  family: 'Open Sans',
  src: `/fonts/Open_Sans/OpenSans-Regular.ttf`,
});
Font.register({
  family: 'Lato',
  src: `/fonts/Lato/Lato-Regular.ttf`,
});
Font.register({
  family: 'Lato Italic',
  src: `/fonts/Lato/Lato-Italic.ttf`,
});
Font.register({
  family: 'Lato Bold',
  src: `/fonts/Lato/Lato-Bold.ttf`,
});

interface ConvictionProps {
  conviction: IConviction;
}

export const ConvictionOutput: FunctionComponent<ConvictionProps> = ({ conviction }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text>DECLARATION DE NON CONDAMNATION</Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text style={{ marginBottom: 10 }}>Je soussigné(e)</Text>
        <Text>Nom: {conviction.lastname}</Text>
        <Text style={{ marginBottom: 10 }}>Prénom: {conviction.firstname}</Text>
        <View style={{ marginBottom: 10, flex: 1, flexDirection: 'row' }}>
          <Text>Né(e) le: {moment(conviction.birthday).format('L')}</Text>
          <Text style={{ marginLeft: 20 }}>A: {conviction.placeOfBirth}</Text>
        </View>
        <Text>
          De (nom de naissance et prénoms du père): {conviction.father?.firstname} {conviction.father?.lastname}
        </Text>
        <Text>
          Et de (nom de naissance et prénoms de la mère): {conviction.mother?.firstname} {conviction.mother?.lastname}
        </Text>
      </View>
      <View style={{ marginBottom: 10 }}>
        <Text>
          Déclare sur l'honneur, conformément aux dispositions de l’article A. 123-51 du code de commerce, n'avoir fait
          l'objet d'aucune condamnation pénale ni de sanction civile ou administrative de nature à m'interdire{' '}
        </Text>
        <Text style={{ marginLeft: 10 }}>- soit d'exercer une activité commerciale,</Text>
        <Text style={{ marginLeft: 10 }}>- soit de gérer, d'administrer ou de diriger une personne morale.</Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text>Fait à {conviction.made?.city}</Text>
        <Text>Le {moment(conviction.made?.date).format('L')}</Text>
      </View>
      <View style={{ marginBottom: 20, alignSelf: 'flex-end' }}>
        <Text>Signature</Text>
        <View style={styles.signature} />
      </View>
      <View style={{ marginBottom: 10 }}>
        <Text>Rappel : Article L. 123-5 du code de commerce</Text>
        <Text>
          « Le fait de donner, de mauvaise foi, des indications inexactes ou incomplètes en vue d'une immatriculation,
          d'une radiation ou d'une mention complémentaire ou rectificative au registre du commerce et des sociétés est
          puni d'une amende de 4500 euros et d'un emprisonnement de six mois.
        </Text>
        <Text>
          Le tribunal compétent peut, en outre, priver l'intéressé, pendant un temps qui n'excède pas cinq ans, du droit
          de vote et d'éligibilité aux élections des tribunaux de commerce, chambres de commerce et d'industrie et
          conseils de prud'hommes. »
        </Text>
      </View>
    </Page>
  </Document>
);
