import { IAuthErrorResponse, IAuthResponse, IBusinessInformationsResponse } from '@models/auth.model';
import { IKeyPair } from '@models/keys.model';

export enum AuthActionTypes {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCESS = 'LOGIN_SUCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  ONBOARD_REQUEST = 'ONBOARD_REQUEST',
  ONBOARD_SUCESS = 'ONBOARD_SUCESS',
  ONBOARD_FAILURE = 'ONBOARD_FAILURE',

  CONFIRM_REQUEST = 'CONFIRM_REQUEST',
  CONFIRM_SUCESS = 'CONFIRM_SUCESS',
  CONFIRM_FAILURE = 'CONFIRM_FAILURE',

  FINALYSE_REQUEST = 'FINALYSE_REQUEST',
  FINALYSE_SUCESS = 'FINALYSE_SUCESS',
  FINALYSE_FAILURE = 'FINALYSE_FAILURE',

  SIRENE_REQUEST = 'SIRENE_REQUEST',
  SIRENE_SUCCESS = 'SIRENE_SUCCESS',
  SIRENE_FAILURE = 'SIRENE_FAILURE',

  LOGOUT = 'LOGOUT',

  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  CLEAR_ACCESS_TOKEN = 'CLEAR_ACCESS_TOKEN',
}

export interface LoginRequestAction {
  type: typeof AuthActionTypes.LOGIN_REQUEST;
}

export interface LoginSucessAction {
  type: typeof AuthActionTypes.LOGIN_SUCESS;
  response: IAuthResponse;
}

export interface LoginFailureAction {
  type: typeof AuthActionTypes.LOGIN_FAILURE;
  error: any;
}

export interface OnboardRequestAction {
  type: typeof AuthActionTypes.ONBOARD_REQUEST;
}

export interface OnboardSucessAction {
  type: typeof AuthActionTypes.ONBOARD_SUCESS;
}

export interface OnboardFailureAction {
  type: typeof AuthActionTypes.ONBOARD_FAILURE;
  error: any;
}

export interface ConfirmRequestAction {
  type: typeof AuthActionTypes.CONFIRM_REQUEST;
}

export interface ConfirmSucessAction {
  type: typeof AuthActionTypes.CONFIRM_SUCESS;
}

export interface ConfirmFailureAction {
  type: typeof AuthActionTypes.CONFIRM_FAILURE;
  error: any;
}

export interface FinalyseRequestAction {
  type: typeof AuthActionTypes.FINALYSE_REQUEST;
}

export interface FinalyseSucessAction {
  type: typeof AuthActionTypes.FINALYSE_SUCESS;
}

export interface FinalyseFailureAction {
  type: typeof AuthActionTypes.FINALYSE_FAILURE;
  error: any;
}

export interface FindCompanyDataRequestAction {
  type: typeof AuthActionTypes.SIRENE_REQUEST;
}
export interface FindCompanyDataSuccessAction {
  type: typeof AuthActionTypes.SIRENE_SUCCESS;
  sirene: IBusinessInformationsResponse;
}
export interface FindCompanyDataFailureAction {
  type: typeof AuthActionTypes.SIRENE_FAILURE;
  error: any;
}

export interface SetAccessTokenAction {
  type: typeof AuthActionTypes.SET_ACCESS_TOKEN;
  accessToken: string;
}

export interface ClearAccessTokenAction {
  type: typeof AuthActionTypes.CLEAR_ACCESS_TOKEN;
}

export interface LogoutAction {
  type: typeof AuthActionTypes.LOGOUT;
}

export type AuthAction =
  | LoginRequestAction
  | LoginSucessAction
  | LoginFailureAction
  | SetAccessTokenAction
  | ClearAccessTokenAction
  | LogoutAction
  | OnboardSucessAction
  | OnboardRequestAction
  | OnboardFailureAction
  | ConfirmRequestAction
  | ConfirmSucessAction
  | ConfirmFailureAction
  | FinalyseRequestAction
  | FinalyseSucessAction
  | FinalyseFailureAction
  | FindCompanyDataRequestAction
  | FindCompanyDataSuccessAction
  | FindCompanyDataFailureAction;

export interface IErrorItf {
  status?: string;
  message?: string;
}

export interface AuthState {
  accessToken?: string;
  status: {
    login: {
      inProgress: boolean;
      done: boolean;
    };
    onboarding: {
      inProgress: boolean;
      done: boolean;
    };
    confirmation: {
      inProgress: boolean;
      done: boolean;
    };
    finalize: {
      inProgress: boolean;
      done: boolean;
    };
    sirene: {
      inProgress: boolean;
      done: boolean;
    };
  };
  globalSupportKeyPair?: IKeyPair;
  currentUserKeyPair?: IKeyPair;
  error?: IErrorItf;
  sirene?: IBusinessInformationsResponse;
}
