import { CircularProgress, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  center: {
    position: 'fixed',
    left: '50%',
    top: '50%',
  },
}));
interface SpinnerProps {
  size?: number | string;
  center?: boolean;
  color?: 'inherit' | 'primary' | 'secondary';
  style?: CSSProperties;
}

const Spinner: FunctionComponent<SpinnerProps> = ({ size, center, color, style }) => {
  const classes = useStyles();

  return <CircularProgress {...(center && { className: classes.center })} color={color} size={size} style={style} />;
};

export default Spinner;
