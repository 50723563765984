import { IDocument } from '@models/agent.model';
import { AgentService, FileService } from '@store/agent/agent.service';
import { Config } from '../config';
import pdf from 'pdfform.js';
import React, { KeyboardEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';

interface IField {
  type: 'radio' | 'select' | 'boolean' | 'number' | 'string';
  options: string[];
}

interface CompleteFileModalProps {
  className?: string;
  isOpen: boolean;
  toggle: () => void;
  document: IDocument;
  handleGenerate: (blob: Blob) => void;
}

const CompleteFileModal = (props: CompleteFileModalProps) => {
  const { t } = useTranslation();

  const { isOpen, toggle, document, handleGenerate } = props;

  const [fields, setFields] = useState<{ [key: string]: IField[] }>({});
  const [fieldsTransform, setFieldsTransform] = useState<{ [key: string]: (string | boolean)[] }>({});
  const [templateBuffer, setTempateBuffer] = useState();

  useEffect(() => {
    (async () => {
      try {
        if (!isOpen) return;
        if (!document.template) return;

        const _templateBuffer = await FileService.getFile(Config.bucket.templatesFolder, document.template);
        if (!_templateBuffer) {
          return;
        }

        const _fields = pdf().list_fields(_templateBuffer);
        if (!_fields) {
          return;
        }

        setFields(_fields);
        setTempateBuffer(_templateBuffer as any);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [document, isOpen]);

  const translateSuffix = useMemo(() => {
    const templateSplit = document.template?.split('.');

    if (templateSplit?.length === 2) {
      const pathSplit = templateSplit[0].split('/');

      if (pathSplit?.length === 2) return `complete.${pathSplit[1]}`;
    }

    return 'complete';
  }, [document.template]);

  const onInput = (field: string, index: number, value: string) => {
    if (!fieldsTransform[field]) {
      fieldsTransform[field] = [];
    }
    fieldsTransform[field][index] = value;
  };

  // const onCheckbox = (field: string, index: number, value: boolean) => {
  //   if (!fieldsTransform[field]) {
  //     fieldsTransform[field] = [];
  //   }
  //   fieldsTransform[field][index] = value;
  // };

  const generatePDF = (event: MouseEvent | KeyboardEvent) => {
    Object.keys(fields).forEach((field) => {
      if (!fieldsTransform[field]) fieldsTransform[field] = [];
      fields[field].forEach((spec, index) => {
        fieldsTransform[field][index] = fieldsTransform[field][index] ? fieldsTransform[field][index] : '';
      });
    });

    const pdfTransformedBuffer: ArrayBuffer = pdf().transform(templateBuffer, fieldsTransform);

    let blob = new Blob([pdfTransformedBuffer], { type: 'application/pdf' });
    handleGenerate(blob);
  };

  return (
    <Modal style={{ width: '50%' }} centered={true} isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{document.name}</ModalHeader>
      <ModalBody>
        <Form>
          {Object.keys(fields).map((field, fIndex) => (
            <FormGroup key={`form-group-${fIndex}`}>
              {fields[field].map((spec, sIndex) => {
                if (spec.type === 'string') {
                  return (
                    <>
                      <Label>{t(`${translateSuffix}.${field}`)}</Label>
                      <Input
                        onChange={(e) => onInput(field, sIndex, e.currentTarget.value)}
                        key={`input-${fIndex}-${sIndex}`}
                        type="text"
                      />
                    </>
                  );
                } /* else if (spec.type === 'boolean') {
                  return (
                    <div style={{ marginLeft: 20 }}>
                      <Input
                        onChange={(e) => onCheckbox(field, sIndex, e.currentTarget.checked)}
                        key={`input-${fIndex}-${sIndex}`}
                        type="checkbox"
                      />
                      <Label>{t(`${translateSuffix}.${field}`)}</Label>
                    </div>
                  );
                } */
              })}
            </FormGroup>
          ))}
          <Button color="primary" block onClick={generatePDF}>
            {t('complete.generate')}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CompleteFileModal;
