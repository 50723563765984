import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { IResumeSkill } from '@models/create.model';
import Title from './Title';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Lato Bold',
    fontSize: 11,
    marginBottom: 10,
  },
  skills: {
    fontFamily: 'Lato',
    fontSize: 10,
    marginBottom: 10,
  },
});

interface SkillEntry {
  title: string;
  value: string;
}

const Skill = (props: SkillEntry) => (
  <View>
    <Text style={styles.title}>{props.title}</Text>
    <Text style={styles.skills}>{props.value}</Text>
  </View>
);

interface SkillsProps {
  skills: IResumeSkill[];
}

const Skills = (props: SkillsProps) => (
  <View>
    <Title>Skills</Title>
    {props.skills.map((skill, index) => (
      <Skill key={`skill-${index}`} title={skill.title} value={skill.value} />
    ))}
  </View>
);

export default Skills;
