import { CONVICTION_MOCK } from '@assets/mock/conviction';
import { REGISTRATION_MOCK } from '@assets/mock/registration';
import { RESUME_MOCK } from '@assets/mock/resume';
import { ConvictionOutput } from '@components/pdf/Conviction';
import { RegistrationOutput } from '@components/pdf/Registration';
import { ResumeOutput } from '@components/pdf/resume';
import { Button, Card, InputAdornment, TextField } from '@material-ui/core';
import { IAddress, IDocument } from '@models/agent.model';
import { IConviction, IRegistration, IResume } from '@models/create.model';
import { pdf } from '@react-pdf/renderer';
import { AppState } from '@store/configuration';
import { FileUtils } from '@tools/file.utils';
import { ValidationUtils } from '@tools/validation';
import moment from 'moment';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaAt, FaGift, FaLinkedin, FaMinus, FaPhone, FaPlus, FaTwitter } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';

interface CreateFileModalProps {
  isOpen: boolean;
  toggle: () => void;
  document: IDocument;
  handleCreate: (blob: Blob) => void;
}

const CreaetFileModal: FunctionComponent<CreateFileModalProps> = ({ document, isOpen, toggle, handleCreate }) => {
  return (
    <Modal style={{ width: '75%', maxWidth: '75%' }} centered={true} isOpen={isOpen} toggle={toggle}>
      {document.name === 'CV' && <CreateResumeForm handleCreate={handleCreate} />}
      {document.name === 'Déclaration de non-condamnation' && <CreateConvictionForm handleCreate={handleCreate} />}
      {document.name === 'Formulaire enregistrement' && <CreateRegistrationForm handleCreate={handleCreate} />}
    </Modal>
  );
};

interface CreateRegistrationFormProps {
  handleCreate: (blob: Blob) => void;
}

const CreateRegistrationForm: FunctionComponent<CreateRegistrationFormProps> = ({ handleCreate }) => {
  const { t } = useTranslation();
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);

  const initialValues = useMemo<IRegistration>(
    () => ({
      address: currentAgent?.company?.profile?.address as IAddress,
      firstname: currentAgent?.legalRepresentant?.firstname,
      lastname: currentAgent?.legalRepresentant?.lastname,
      birthday: currentAgent?.legalRepresentant?.birthday,
      placeOfBirth: currentAgent?.legalRepresentant?.placeOfBirth,
      made: {
        city: currentAgent?.company?.profile?.address?.city,
        date: moment().format('L'),
      },
    }),
    [currentAgent],
  );

  const { register, handleSubmit, errors } = useForm<IRegistration>({
    // defaultValues: initialValues
    defaultValues: REGISTRATION_MOCK,
  });

  const generatePDF = async (registration: IRegistration) => {
    try {
      const registrationComponent = <RegistrationOutput registration={registration} />;

      const createdPdf = pdf(registrationComponent);
      const blob = await createdPdf.toBlob();

      handleCreate(blob as File);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <form className="form-container" onSubmit={handleSubmit(generatePDF)}>
      <div className="inputs-container">
        <div style={{ display: 'flex' }}>
          <Card style={{ width: '50%' }}>
            <div className="subtitle">{t('forms.conviction.person')}</div>

            <TextField
              inputRef={register({ required: true })}
              name="firstname"
              label={t('forms.conviction.firstname.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.firstname?.type === 'required'}
              helperText={errors.firstname && <p>{t('forms.conviction.firstname.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="lastname"
              label={t('forms.conviction.lastname.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.lastname?.type === 'required'}
              helperText={errors.lastname && <p>{t('forms.conviction.lastname.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="birthday"
              type="date"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaGift />
                  </InputAdornment>
                ),
              }}
              label={t('forms.conviction.birthday.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.birthday?.type === 'required'}
              helperText={errors.birthday && <p>{t('forms.conviction.birthday.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="placeOfBirth"
              label={t('forms.conviction.placeOfBirth.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.placeOfBirth?.type === 'required'}
              helperText={errors.placeOfBirth && <p>{t('forms.conviction.placeOfBirth.required')}</p>}
            />
          </Card>

          <Card style={{ width: '50%' }}>
            <TextField
              inputRef={register({ required: true })}
              name="address.line1"
              label={t('forms.conviction.address.line1.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.address?.line1?.type === 'required'}
              helperText={errors.address?.line1 && <p>{t('forms.conviction.address.line1.required')}</p>}
            />

            <TextField
              inputRef={register}
              name="address.line2"
              label={t('forms.conviction.address.line2.label')}
              variant="outlined"
            />

            <TextField
              inputRef={register({ required: true })}
              name="address.zipCode"
              label={t('forms.conviction.address.zipCode.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.address?.zipCode?.type === 'required'}
              helperText={errors.address?.zipCode && <p>{t('forms.conviction.address.zipCode.required')}</p>}
            />

            <div style={{ display: 'flex' }}>
              <TextField
                style={{ width: '50%' }}
                inputRef={register({ required: true })}
                name="address.city"
                label={t('forms.conviction.address.city.label')}
                variant="outlined"
                InputLabelProps={{ required: true }}
                error={errors.address?.city?.type === 'required'}
                helperText={errors.address?.city && <p>{t('forms.conviction.address.city.required')}</p>}
              />

              <TextField
                style={{ width: '50%' }}
                inputRef={register({ required: true })}
                name="address.country"
                label={t('forms.conviction.address.country.label')}
                variant="outlined"
                InputLabelProps={{ required: true }}
                error={errors.address?.country?.type === 'required'}
                helperText={errors.address?.country && <p>{t('forms.conviction.address.country.required')}</p>}
              />
            </div>
          </Card>
        </div>

        <Card>
          <TextField
            inputRef={register({ required: true })}
            name="made.city"
            label={t('forms.conviction.made.city.label')}
            variant="outlined"
            InputLabelProps={{ required: true }}
            error={errors.made?.city?.type === 'required'}
            helperText={errors.made?.city && <p>{t('forms.conviction.made.city.required')}</p>}
          />
        </Card>
      </div>

      <Button type="submit" color="primary" variant="outlined">
        {t('forms.conviction.submit.label')}
      </Button>
    </form>
  );
};

interface CreateConvictionFormProps {
  handleCreate: (blob: Blob) => void;
}

const CreateConvictionForm: FunctionComponent<CreateConvictionFormProps> = ({ handleCreate }) => {
  const { t } = useTranslation();
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);

  const initialValues = useMemo<IConviction>(
    () => ({
      address: currentAgent?.company?.profile?.address as IAddress,
      firstname: currentAgent?.legalRepresentant?.firstname,
      lastname: currentAgent?.legalRepresentant?.lastname,
      birthday: currentAgent?.legalRepresentant?.birthday,
      placeOfBirth: currentAgent?.legalRepresentant?.placeOfBirth,
      made: {
        city: currentAgent?.company?.profile?.address?.city,
        date: moment().format('L'),
      },
    }),
    [currentAgent],
  );

  const { register, handleSubmit, errors } = useForm<IConviction>({
    // defaultValues: initialValues
    defaultValues: CONVICTION_MOCK,
  });

  const generatePDF = async (conviction: IConviction) => {
    try {
      const convictionComponent = <ConvictionOutput conviction={conviction} />;

      const createdPdf = pdf(convictionComponent);
      const blob = await createdPdf.toBlob();

      handleCreate(blob as File);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <form className="form-container" onSubmit={handleSubmit(generatePDF)}>
      <div className="inputs-container">
        <div style={{ display: 'flex' }}>
          <Card style={{ width: '50%' }}>
            <div className="subtitle">{t('forms.conviction.person')}</div>

            <TextField
              inputRef={register({ required: true })}
              name="firstname"
              label={t('forms.conviction.firstname.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.firstname?.type === 'required'}
              helperText={errors.firstname && <p>{t('forms.conviction.firstname.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="lastname"
              label={t('forms.conviction.lastname.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.lastname?.type === 'required'}
              helperText={errors.lastname && <p>{t('forms.conviction.lastname.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="birthday"
              type="date"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaGift />
                  </InputAdornment>
                ),
              }}
              label={t('forms.conviction.birthday.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.birthday?.type === 'required'}
              helperText={errors.birthday && <p>{t('forms.conviction.birthday.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="placeOfBirth"
              label={t('forms.conviction.placeOfBirth.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.placeOfBirth?.type === 'required'}
              helperText={errors.placeOfBirth && <p>{t('forms.conviction.placeOfBirth.required')}</p>}
            />
          </Card>

          <Card style={{ width: '50%' }}>
            <TextField
              inputRef={register({ required: true })}
              name="address.line1"
              label={t('forms.conviction.address.line1.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.address?.line1?.type === 'required'}
              helperText={errors.address?.line1 && <p>{t('forms.conviction.address.line1.required')}</p>}
            />

            <TextField
              inputRef={register}
              name="address.line2"
              label={t('forms.conviction.address.line2.label')}
              variant="outlined"
            />

            <TextField
              inputRef={register({ required: true })}
              name="address.zipCode"
              label={t('forms.conviction.address.zipCode.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.address?.zipCode?.type === 'required'}
              helperText={errors.address?.zipCode && <p>{t('forms.conviction.address.zipCode.required')}</p>}
            />

            <div style={{ display: 'flex' }}>
              <TextField
                style={{ width: '50%' }}
                inputRef={register({ required: true })}
                name="address.city"
                label={t('forms.conviction.address.city.label')}
                variant="outlined"
                InputLabelProps={{ required: true }}
                error={errors.address?.city?.type === 'required'}
                helperText={errors.address?.city && <p>{t('forms.conviction.address.city.required')}</p>}
              />

              <TextField
                style={{ width: '50%' }}
                inputRef={register({ required: true })}
                name="address.country"
                label={t('forms.conviction.address.country.label')}
                variant="outlined"
                InputLabelProps={{ required: true }}
                error={errors.address?.country?.type === 'required'}
                helperText={errors.address?.country && <p>{t('forms.conviction.address.country.required')}</p>}
              />
            </div>
          </Card>
        </div>

        <Card>
          <div className="subtitle">{t('forms.conviction.parents')}</div>

          <div style={{ display: 'flex' }}>
            <TextField
              style={{ width: '50%' }}
              inputRef={register({ required: true })}
              name="father.firstname"
              label={t('forms.conviction.father.firstname.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.father?.firstname?.type === 'required'}
              helperText={errors.father?.firstname && <p>{t('forms.conviction.father.firstname.required')}</p>}
            />

            <TextField
              style={{ width: '50%' }}
              inputRef={register({ required: true })}
              name="father.lastname"
              label={t('forms.conviction.father.lastname.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.father?.lastname?.type === 'required'}
              helperText={errors.father?.lastname && <p>{t('forms.conviction.father.lastname.required')}</p>}
            />
          </div>

          <div style={{ display: 'flex' }}>
            <TextField
              style={{ width: '50%' }}
              inputRef={register({ required: true })}
              name="mother.firstname"
              label={t('forms.conviction.mother.firstname.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.mother?.firstname?.type === 'required'}
              helperText={errors.mother?.firstname && <p>{t('forms.conviction.mother.firstname.required')}</p>}
            />

            <TextField
              style={{ width: '50%' }}
              inputRef={register({ required: true })}
              name="mother.lastname"
              label={t('forms.conviction.mother.lastname.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.mother?.lastname?.type === 'required'}
              helperText={errors.mother?.lastname && <p>{t('forms.conviction.mother.lastname.required')}</p>}
            />
          </div>
        </Card>

        <Card>
          <TextField
            inputRef={register({ required: true })}
            name="made.city"
            label={t('forms.conviction.made.city.label')}
            variant="outlined"
            InputLabelProps={{ required: true }}
            error={errors.made?.city?.type === 'required'}
            helperText={errors.made?.city && <p>{t('forms.conviction.made.city.required')}</p>}
          />
        </Card>
      </div>

      <Button type="submit" color="primary" variant="outlined">
        {t('forms.conviction.submit.label')}
      </Button>
    </form>
  );
};

interface CreateCVFormProps {
  handleCreate: (blob: Blob) => void;
}

const CreateResumeForm = ({ handleCreate }: CreateCVFormProps) => {
  const { t } = useTranslation();
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);

  const initialValues = useMemo<IResume>(
    () => ({
      personal: {
        name: [currentAgent?.legalRepresentant?.firstname, currentAgent?.legalRepresentant?.lastname].join(' '),
        job: currentAgent?.legalRepresentant?.role as string,
        email: currentAgent?.legalRepresentant?.email as string,
        address: currentAgent?.company?.profile?.address as IAddress,
        nationality: currentAgent?.legalRepresentant?.nationality,
      },
      educations: [],
      experiences: [],
      skills: [],
    }),
    [currentAgent],
  );

  const { register, handleSubmit, errors } = useForm<IResume>({
    // defaultValues: initialValues
    defaultValues: RESUME_MOCK,
  });

  const generatePDF = async (resume: IResume) => {
    try {
      if ((resume.personal.picture as FileList)?.item(0)) {
        const file = (resume.personal.picture as FileList).item(0) as File;
        const fileBase64 = (await FileUtils.toBase64(file)) as string;

        resume.personal = { ...resume.personal, picture: fileBase64 };
      }

      const resumeComponent = (
        <ResumeOutput
          author={[currentAgent?.legalRepresentant?.firstname, currentAgent?.legalRepresentant?.lastname].join(' ')}
          keywords="cv resume job"
          title="CV"
          resume={resume}
        />
      );

      const createdPdf = pdf(resumeComponent);
      const blob = await createdPdf.toBlob();

      handleCreate(blob as File);
    } catch (err) {
      console.error(err);
    }
  };

  const [educationsSize, setEducationsSize] = useState(2);
  const [experiencesSize, setExperiencesSize] = useState(3);
  const [skillsSize, setSkillsSize] = useState(3);

  return (
    <form className="form-container" onSubmit={handleSubmit(generatePDF)}>
      <div className="inputs-container">
        <div className="personal">
          <Card>
            <TextField
              inputRef={register({ required: true })}
              name="personal.name"
              defaultValue={initialValues.personal?.name}
              label={t('forms.resume.person.name.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.personal?.name?.type === 'required'}
              helperText={errors.personal?.name && <p>{t('forms.resume.person.name.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="personal.job"
              defaultValue={initialValues.personal?.job}
              label={t('forms.resume.person.job.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.personal?.job?.type === 'required'}
              helperText={errors.personal?.job && <p>{t('forms.resume.person.job.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="personal.email"
              defaultValue={initialValues.personal?.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaAt />
                  </InputAdornment>
                ),
              }}
              label={t('forms.resume.person.email.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.personal?.email?.type === 'required'}
              helperText={errors.personal?.email && <p>{t('forms.resume.person.email.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="personal.phone"
              defaultValue={initialValues.personal?.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaPhone />
                  </InputAdornment>
                ),
              }}
              label={t('forms.resume.person.phone.label')}
              variant="outlined"
              error={errors.personal?.phone?.type === 'required'}
              helperText={errors.personal?.phone && <p>{t('forms.resume.person.phone.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="personal.birthday"
              type="date"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaGift />
                  </InputAdornment>
                ),
              }}
              label={t('forms.resume.person.birthday.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.personal?.birthday?.type === 'required'}
              helperText={errors.personal?.birthday && <p>{t('forms.resume.person.birthday.required')}</p>}
            />

            <TextField
              inputRef={register}
              name="personal.nationality"
              label={t('forms.resume.person.nationality.label')}
              variant="outlined"
            />

            <TextField
              inputRef={register}
              type="file"
              inputProps={{ accept: 'image/*' }}
              InputLabelProps={{ shrink: true }}
              name="personal.picture"
              label={t('forms.resume.person.picture.label')}
              variant="outlined"
            />
          </Card>
          <Card>
            <TextField
              inputRef={register({ required: true })}
              name="personal.address.line1"
              label={t('forms.resume.person.address.line1.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.personal?.address?.line1?.type === 'required'}
              helperText={errors.personal?.address?.line1 && <p>{t('forms.resume.person.address.line1.required')}</p>}
            />

            <TextField
              inputRef={register}
              name="personal.address.line2"
              label={t('forms.resume.person.address.line2.label')}
              variant="outlined"
            />

            <TextField
              inputRef={register({ required: true })}
              name="personal.address.zipCode"
              label={t('forms.resume.person.address.zipCode.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.personal?.address?.zipCode?.type === 'required'}
              helperText={
                errors.personal?.address?.zipCode && <p>{t('forms.resume.person.address.zipCode.required')}</p>
              }
            />

            <div style={{ display: 'flex' }}>
              <TextField
                style={{ width: '50%' }}
                inputRef={register({ required: true })}
                name="personal.address.city"
                label={t('forms.resume.person.address.city.label')}
                variant="outlined"
                InputLabelProps={{ required: true }}
                error={errors.personal?.address?.city?.type === 'required'}
                helperText={errors.personal?.address?.city && <p>{t('forms.resume.person.address.city.required')}</p>}
              />

              <TextField
                style={{ width: '50%' }}
                inputRef={register({ required: true })}
                name="personal.address.country"
                label={t('forms.resume.person.address.country.label')}
                variant="outlined"
                InputLabelProps={{ required: true }}
                error={errors.personal?.address?.country?.type === 'required'}
                helperText={
                  errors.personal?.address?.country && <p>{t('forms.resume.person.address.country.required')}</p>
                }
              />
            </div>

            <TextField
              inputRef={register({ validate: ValidationUtils.isURL })}
              name="personal.linkedin"
              label={t('forms.resume.person.linkedin.label')}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaLinkedin />
                  </InputAdornment>
                ),
              }}
              error={errors.personal?.linkedin?.type === 'validate'}
              helperText={errors.personal?.linkedin && <p>{t('forms.resume.person.linkedin.isURL')}</p>}
            />

            <TextField
              inputRef={register}
              name="personal.twitter"
              label={t('forms.resume.person.twitter.label')}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaTwitter />
                  </InputAdornment>
                ),
              }}
              error={errors.personal?.twitter?.type === 'validate'}
              helperText={errors.personal?.twitter && <p>{t('forms.resume.person.twitter.isURL')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="personal.goal"
              InputLabelProps={{ required: true }}
              label={t('forms.resume.person.goal.label')}
              variant="outlined"
              multiline
              error={errors.personal?.goal?.type === 'required'}
              helperText={errors.personal?.goal && <p>{t('forms.resume.person.goal.required')}</p>}
            />
          </Card>
        </div>

        <div className="skills">
          <div className="subtitle">
            <div>{t('forms.resume.skills.label')}</div>
            <div>
              <FaPlus onClick={() => setSkillsSize(skillsSize + 1)} />
              <FaMinus onClick={() => setSkillsSize(skillsSize - 1)} />
            </div>
          </div>
          {createArrayWithNumbers(skillsSize).map((index) => (
            <Card key={index}>
              <TextField
                inputRef={register({ required: true })}
                name={`skills[${index - 1}].title`}
                label={t('forms.resume.skills.title.label')}
                variant="outlined"
                error={errors.skills && errors.skills[index - 1]?.title?.type === 'required'}
                helperText={
                  errors.skills && errors.skills[index - 1]?.title && <p>{t('forms.resume.skills.title.required')}</p>
                }
              />

              <TextField
                multiline
                inputRef={register({ required: true })}
                name={`skills[${index - 1}].value`}
                label={t('forms.resume.skills.value.label')}
                variant="outlined"
                error={errors.skills && errors.skills[index - 1]?.value?.type === 'required'}
                helperText={
                  errors.skills && errors.skills[index - 1]?.value && <p>{t('forms.resume.skills.value.required')}</p>
                }
              />
            </Card>
          ))}
        </div>

        <div className="experiences">
          <div className="subtitle">
            <div>{t('forms.resume.experiences.label')}</div>
            <div>
              <FaPlus onClick={() => setExperiencesSize(experiencesSize + 1)} />
              <FaMinus onClick={() => setExperiencesSize(experiencesSize - 1)} />
            </div>
          </div>
          {createArrayWithNumbers(experiencesSize).map((index) => (
            <Card key={index}>
              <TextField
                inputRef={register({ required: true })}
                name={`experiences[${index - 1}].company`}
                label={t('forms.resume.experiences.company.label')}
                variant="outlined"
                error={errors.experiences && errors.experiences[index - 1]?.company?.type === 'required'}
                helperText={
                  errors.experiences &&
                  errors.experiences[index - 1]?.company && <p>{t('forms.resume.experiences.company.required')}</p>
                }
              />

              <TextField
                inputRef={register({ required: true })}
                name={`experiences[${index - 1}].position`}
                label={t('forms.resume.experiences.position.label')}
                variant="outlined"
                error={errors.experiences && errors.experiences[index - 1]?.position?.type === 'required'}
                helperText={
                  errors.experiences &&
                  errors.experiences[index - 1]?.position && <p>{t('forms.resume.experiences.position.required')}</p>
                }
              />

              <TextField
                inputRef={register({ required: true })}
                name={`experiences[${index - 1}].duration`}
                label={t('forms.resume.experiences.duration.label')}
                variant="outlined"
                error={errors.experiences && errors.experiences[index - 1]?.duration?.type === 'required'}
                helperText={
                  errors.experiences &&
                  errors.experiences[index - 1]?.duration && <p>{t('forms.resume.experiences.duration.required')}</p>
                }
              />

              <TextField
                inputRef={register({ required: true })}
                name={`experiences[${index - 1}].details`}
                label={t('forms.resume.experiences.details.label')}
                variant="outlined"
                multiline
                error={errors.experiences && errors.experiences[index - 1]?.details?.type === 'required'}
                helperText={
                  errors.experiences &&
                  errors.experiences[index - 1]?.details && <p>{t('forms.resume.experiences.details.required')}</p>
                }
              />
            </Card>
          ))}
        </div>

        <div className="educations">
          <div className="subtitle">
            <div>{t('forms.resume.educations.label')}</div>
            <div>
              <FaPlus onClick={() => setEducationsSize(educationsSize + 1)} />
              <FaMinus onClick={() => setEducationsSize(educationsSize - 1)} />
            </div>
          </div>
          {createArrayWithNumbers(educationsSize).map((index) => (
            <Card key={index}>
              <TextField
                inputRef={register({ required: true })}
                name={`educations[${index - 1}].school`}
                label={t('forms.resume.educations.school.label')}
                variant="outlined"
                error={errors.educations && errors.educations[index - 1]?.school?.type === 'required'}
                helperText={
                  errors.educations &&
                  errors.educations[index - 1]?.school && <p>{t('forms.resume.educations.school.required')}</p>
                }
              />

              <TextField
                inputRef={register({ required: true })}
                name={`educations[${index - 1}].degree`}
                label={t('forms.resume.educations.degree.label')}
                variant="outlined"
                error={errors.educations && errors.educations[index - 1]?.degree?.type === 'required'}
                helperText={
                  errors.educations &&
                  errors.educations[index - 1]?.degree && <p>{t('forms.resume.educations.degree.required')}</p>
                }
              />

              <TextField
                inputRef={register({ required: true })}
                name={`educations[${index - 1}].year`}
                label={t('forms.resume.educations.year.label')}
                variant="outlined"
                error={errors.educations && errors.educations[index - 1]?.year?.type === 'required'}
                helperText={
                  errors.educations &&
                  errors.educations[index - 1]?.year && <p>{t('forms.resume.educations.year.required')}</p>
                }
              />
            </Card>
          ))}
        </div>
      </div>

      <Button type="submit" color="primary" variant="outlined">
        {t('forms.resume.submit.label')}
      </Button>
    </form>
  );
};

const createArrayWithNumbers = (length: number) => {
  return Array.from({ length }, (_, k) => k + 1);
};

export default CreaetFileModal;
