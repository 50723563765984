import React, { FunctionComponent } from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { FaCheck } from 'react-icons/fa';

export interface ProgressProps extends CircularProgressProps {
  value: number;
}

const Progress: FunctionComponent<ProgressProps> = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={3}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        {props.value !== 100 && (
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        )}
        {props.value === 100 && <FaCheck />}
      </Box>
    </Box>
  );
};

export default Progress;
