import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';
import { IResumeEducation } from '@models/create.model';
import Title from './Title';

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  school: {
    fontFamily: 'Lato Bold',
    fontSize: 10,
  },
  degree: {
    fontFamily: 'Lato',
    fontSize: 10,
  },
  candidate: {
    fontFamily: 'Lato Italic',
    fontSize: 10,
  },
});

interface EducationProps {
  educations: IResumeEducation[];
}

const Education: FunctionComponent<EducationProps> = ({ educations }) => (
  <View style={styles.container}>
    <Title>Education</Title>
    {educations.map((education, index) => (
      <View key={`education-${index}`} style={styles.container}>
        <Text style={styles.school}>{education.school}</Text>
        <Text style={styles.degree}>{education.degree}</Text>
        <Text style={styles.candidate}>{education.year}</Text>
      </View>
    ))}
  </View>
);

export default Education;
