export class AbstractError extends Error {
  protected display() {
    // appStore.dispatch(setError(this.message))
  }
}

export class AuthError extends AbstractError {
  constructor(public status: number, public message: string) {
    super(message);
    this.message = `${status} : ${this.message}`;
    this.display();
  }
}
