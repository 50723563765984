import { IOption, IStep } from '@models/agent.model';
import { InputType } from 'reactstrap/es/Input';

export const ProspectUtils = {
  getValue(
    type: InputType,
    value: string | string[] | boolean,
    questionKey: number,
    onboardingStep: Record<number, IStep>,
    t: any,
  ): string {
    if (Array.isArray(value)) {
      const options = onboardingStep[questionKey].input.options;
      return value
        .map((val: string) => (options ? t(options.find((opt: IOption) => opt.value === val)?.label ||'') : val))
        .join(', ');
    }
    if (type === 'radio' || typeof value === 'boolean') {
      const stringValue = typeof value === 'boolean' ? (value === true ? 'true' : 'false') : value;
      return getOptionLabel(questionKey, stringValue, onboardingStep, t);
    }
    return value;
  },

  getFormattedInfo(label: string, value: string) {
    return { label, value };
  },
};

const getOptionLabel = (questionKey: number, value: string, onboardingStep: Record<number, IStep>, t: any): string => {
  const options = onboardingStep[questionKey].input.options;
  const label = t(options?.find((opt: IOption) => opt.value === value)?.label || '');
  return label ?? '';
};
