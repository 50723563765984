import DragAndDrop from '@components/DragAndDrop';
import React, { FunctionComponent } from 'react';
import { Modal, ModalBody } from 'reactstrap';

interface UploadFileModalProps {
  className?: string;
  isOpen: boolean;
  accept?: string;
  toggle: () => void;
  handleUpload: (file: File) => void;
}

const UploadFileModal: FunctionComponent<UploadFileModalProps> = ({ isOpen, toggle, handleUpload, accept }) => (
  <Modal style={{ width: '50%' }} centered={true} isOpen={isOpen} toggle={toggle}>
    <ModalBody>
      <DragAndDrop handleDrop={handleUpload} accept={accept} />
    </ModalBody>
  </Modal>
);

export default UploadFileModal;
