import { StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';
import { Colors } from '../../../tools/constants';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Lato Bold',
    fontSize: 14,
    color: Colors.treezor.blue,
    marginBottom: 10,
    textTransform: 'uppercase',
  },
});

interface TitleProps {
  children: any;
}

const Title = (props: TitleProps) => <Text style={styles.title}>{props.children}</Text>;

export default Title;
