/* eslint-disable react-hooks/exhaustive-deps */
import Spinner from '@components/ui/Spinner';
import useToggle from '@hooks/useToggle';
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import moment from 'moment';
import React, { CSSProperties, FunctionComponent, useMemo, useState } from 'react';
import { Controller, RegisterOptions, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaEye, FaGift, FaLock, FaPlus, FaTimes } from 'react-icons/fa';
import { ButtonGroup } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import _ from 'lodash';

const styles: { [index: string]: CSSProperties } = {
  form: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' },
  input: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '45%',
    maxWidth: '45%',
    minWidth: '45%',
    margin: 10,
  },
};
export interface UIFormProps {
  label?: string;
  inputs: IInput[];
  defaultValues?: any;
  error?: any;
  onSubmit: (values: any) => void;
  beforeSubmit?: (values: any) => string | undefined;
  isLoading?: boolean;
  validLabel?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  style?: CSSProperties;
  inputStyle?: CSSProperties;
}

export interface IInput {
  name: string;
  password?: boolean;
  defaultValue?: any;
  label: string;
  rules?: RegisterOptions;
  readOnly?: boolean;
  list?: boolean;
  objecstList?: {
    items: IInput[];
  };
  type?: 'number' | 'text' | 'boolean' | 'date' | 'slider' | 'label';
  select?: { options: IInputOption[]; multiple?: boolean };
  radio?: { options: IInputOption[] };
}

export interface IInputOption {
  label: string;
  value: string | number | boolean;
}

export const UIForm: FunctionComponent<UIFormProps> = ({
  inputs,
  style = styles.form,
  label,
  beforeSubmit,
  inputStyle = styles.input,
  variant = 'outlined',
  defaultValues = {},
  onSubmit,
  validLabel,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>();

  const [showPassword, togglePassword] = useToggle(false);

  inputs.forEach((input) => {
    if (input.objecstList || input.select?.multiple) defaultValues[input.name] = defaultValues[input.name] || [];
  });

  const submit = (values: any) => {
    setError(undefined);
    let message: string | undefined;

    if (beforeSubmit) {
      message = beforeSubmit(values);
      setError(message);
    }

    if (!message) onSubmit(values);
  };

  const hookForm = useForm({
    defaultValues,
  });

  return (
    <Grid container style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 10 }}>
      {label && (
        <Typography variant="h4" style={{ fontVariantCaps: 'small-caps' }}>
          {label}
        </Typography>
      )}

      <Grid style={style}>
        {inputs.map((input) => {
          if (input.list)
            return (
              <Controller
                key={input.name}
                name={input.name}
                control={hookForm.control}
                render={({ onChange, value }) => (
                  <ChipInput
                    style={inputStyle}
                    label={input.label}
                    variant={variant as any}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{t('forms.core.enterToAdd')}</InputAdornment>,
                    }}
                    defaultValue={value}
                    onChange={(chips) => onChange(chips)}
                  />
                )}
              />
            );

          if (input.objecstList)
            return (
              <InputObjecstList
                key={input.name}
                variant={variant as any}
                input={input}
                hookForm={hookForm}
                style={inputStyle}
              />
            );

          if (input.type === 'label')
            return (
              <Typography key={input.label} style={{ width: '100%' }} variant="h5">
                {input.label}
              </Typography>
            );

          if (input.type === 'boolean')
            return (
              <Controller
                key={input.name}
                name={input.name}
                control={hookForm.control}
                render={({ value, onChange }) => (
                  <Grid container direction="row" alignItems="center" justify="space-between" style={inputStyle}>
                    <InputLabel id={`${input.name}-radio`}>{input.label}</InputLabel>
                    <Switch
                      checked={Boolean(value)}
                      onChange={(event) => onChange(event.currentTarget.checked)}
                      color="primary"
                    />
                  </Grid>
                )}
              />
            );

          if (input.select)
            return (
              <Controller
                key={input.name}
                name={input.name}
                control={hookForm.control}
                render={({ value, onChange }) => (
                  <FormControl variant={variant as any} style={inputStyle}>
                    <InputLabel id={`${input.name}-select`}>{input.label}</InputLabel>
                    <Select
                      defaultValue={Array.isArray(value) ? value : []}
                      labelId={`${input.name}-select`}
                      label={input.label}
                      multiple={true}
                      variant={variant as any}
                      value={value}
                      onChange={onChange}>
                      {input.select?.options?.map((option) => (
                        <MenuItem key={`${option.value}`} value={option.value as string}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            );

          if (input.radio)
            return (
              <Controller
                key={input.name}
                name={input.name}
                control={hookForm.control}
                render={({ value, onChange }) => (
                  <Grid container direction="row" alignItems="center" justify="space-between" style={inputStyle}>
                    <InputLabel id={`${input.name}-radio`}>{input.label}</InputLabel>
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                      {input.radio?.options?.map((option) => (
                        <Button
                          key={`${option.value}`}
                          color="primary"
                          {...(value === option.value && { variant: 'contained' })}
                          onClick={() => onChange(option.value)}>
                          {option.label}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Grid>
                )}
              />
            );

          if (input.type === 'slider')
            return (
              <Controller
                key={input.name}
                name={input.name}
                control={hookForm.control}
                render={({ value, onChange }) => (
                  <Grid container direction="row" style={inputStyle}>
                    <Grid style={{ marginBottom: 10 }}>
                      <InputLabel>{input.label}</InputLabel>
                    </Grid>
                    <Grid container justify="space-between">
                      <Grid item xs={1}>
                        B2C
                      </Grid>
                      <Grid item xs={9}>
                        <Slider
                          value={Number(value)}
                          onChange={(_, value) => {
                            onChange(value);
                          }}
                          min={0}
                          max={100}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        B2B
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              />
            );

          return (
            <Controller
              key={input.name}
              name={input.name}
              rules={input.rules}
              control={hookForm.control}
              render={({ value, onChange }) => (
                <TextField
                  style={inputStyle}
                  type={input.password && !showPassword ? 'password' : input.type || 'text'}
                  defaultValue={
                    input.type === 'number'
                      ? Number(value)
                      : input.type === 'date'
                      ? moment(value).format('YYYY-MM-DD')
                      : value
                  }
                  onChange={(event) => {
                    if (input.type === 'number') onChange(Number(event.currentTarget.value));
                    else if (input.type === 'date') onChange(Number(new Date(event.currentTarget.value).getTime()));
                    else onChange(event.currentTarget.value);
                  }}
                  {...(input.type === 'date' && {
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <FaGift />
                        </InputAdornment>
                      ),
                    },
                  })}
                  {...(input.password && {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end" onClick={togglePassword}>
                          {showPassword ? <FaLock /> : <FaEye />}
                        </InputAdornment>
                      ),
                    },
                  })}
                  disabled={input.readOnly}
                  label={input.label}
                  variant={variant as any}
                  InputLabelProps={{ required: input.rules?.required as boolean }}
                  error={!!_.get(hookForm.errors, input.name)}
                  helperText={
                    <span className="helperText">{_.get(hookForm.errors, input.name)?.message || t('required')}</span>
                  }
                />
              )}
            />
          );
        })}
      </Grid>

      {error && (
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      )}

      <Button
        style={{ marginTop: 20, width: 300, borderRadius: 12.6, boxShadow: '0 5px 8px 0 rgba(50, 142, 229, 0.3)' }}
        color="primary"
        variant="contained"
        onClick={hookForm.handleSubmit(submit)}>
        {validLabel || t('forms.core.save')}
        {isLoading && <Spinner size={20} color="inherit" style={{ marginLeft: 20 }} />}
      </Button>
    </Grid>
  );
};

interface InputObjecstListProps {
  input: IInput;
  hookForm: any;
  style: any;
  variant?: 'outlined' | 'standard' | 'filled';
}

const InputObjecstList: FunctionComponent<InputObjecstListProps> = ({
  input,
  hookForm,
  style,
  variant = 'outlined',
}) => {
  const { fields, append, remove } = useFieldArray({
    control: hookForm.control,
    name: input.name,
  });

  const initialField = useMemo(
    () =>
      input.objecstList?.items?.map((item) => item.name).reduce((acc, param) => ({ ...acc, [param]: '' }), {}) as any,
    [],
  );

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Typography variant="h6">{input.label}</Typography>
      {fields.map((row, index) => (
        <Grid key={row.id} container justify="center" alignItems="center">
          {input.objecstList?.items.map((item: IInput) => (
            <TextField
              key={`${input.name}[${index}].${item.name}`}
              name={`${input.name}[${index}].${item.name}`}
              defaultValue={row[item.name]}
              inputRef={hookForm.register()}
              style={style}
              type={item.type}
              {...(item.type === 'date' && {
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaGift />
                    </InputAdornment>
                  ),
                },
              })}
              disabled={item.readOnly}
              label={item.label}
              variant={variant as any}
              InputLabelProps={{ required: item.rules?.required as boolean }}
            />
          ))}

          <Button onClick={() => remove(index)}>
            <FaTimes size={20} />
          </Button>
        </Grid>
      ))}
      <Button onClick={() => append(initialField)}>
        <FaPlus size={20} />
      </Button>
    </Grid>
  );
};
