/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, GridProps } from '@material-ui/core';
import React, { createRef, FunctionComponent, RefObject, useEffect } from 'react';

interface DraggableProps extends GridProps {
  onFileDrop: (file: File) => void;
  accept?: string;
  disableClick?: boolean;
}

const Draggable: FunctionComponent<DraggableProps> = ({
  onFileDrop,
  accept = '.pdf, .pptx, .xlsx, .xls, .doc, .docx, .png, .jpeg, .jpg, .zip',
  disableClick,
  ...rest
}) => {
  const dropRef: RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    let div = dropRef.current as HTMLDivElement;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', drop);

    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', drop);
    };
  }, [dropRef]);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const drop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      onFileDrop(e.dataTransfer.files.item(0) as File);
      e.dataTransfer.clearData();
    }
  };

  const browseFile = () => {
    const input = window.document.createElement('input');
    input.type = 'file';
    input.multiple = false;
    input.accept = accept;
    input.style.display = 'none';
    input.onchange = () => {
      if (input.files && input.files.length > 0) {
        onFileDrop(input.files.item(0) as File);
        window.document.body.removeChild(input);
      }
    };

    window.document.body.appendChild(input);
    input.click();
  };

  return <Grid {...rest} ref={dropRef as any} {...(!disableClick && { onClick: browseFile })} />;
};

export default Draggable;
