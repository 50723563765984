import Spinner from '@components/ui/Spinner';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import { IAgent } from '@models/agent.model';
import { updateAgent, resetStatus } from '@store/agent/agent.action';
import { AppState } from '@store/configuration';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const ChangeProcess = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const _currentAgent = useSelector((state: AppState) => state.agent.currentAgent);
  const updateStatus = useSelector((state: AppState) => state.agent.status.update);
  const [currentAgent, setCurentAgent] = useState<IAgent | undefined>(_currentAgent);
  const [currentProcess, setCurrentProcess] = useState(currentAgent?.currentProcess);

  const onStepSwitched = (event: ChangeEvent<HTMLInputElement>) => {
    setCurentAgent({
      ...currentAgent,
      steps: currentAgent?.steps?.map((step) => {
        if (step.label === event.target.name) {
          return { ...step, disabled: !event.target.checked };
        }
        return step;
      }),
    });
  };

  const updateProcess = () => {
    dispatch(
      updateAgent({
        steps: currentAgent?.steps,
        status: currentProcess === 'client' ? 'AGENT' : 'PROSPECT',
        currentProcess,
      }),
    );
  };

  useEffect(() => {
    if (updateStatus.done) {
      dispatch(resetStatus());
      history.goBack();
    }
  }, [updateStatus.done, history]);

  const cancel = () => {
    history.goBack();
  };

  return (
    <Grid container direction="column" style={{ padding: 50 }}>
      <Grid container direction="column">
        <Typography variant="h6">{t('pages.documents.changeProcess.title')}</Typography>
        <ButtonGroup variant="contained" fullWidth>
          {currentAgent?.availableProcess?.map((process) => (
            <Button
              fullWidth
              color={process === currentProcess ? 'primary' : 'default'}
              onClick={() => setCurrentProcess(process)}>
              {process}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>

      <Grid container direction="column" style={{ marginTop: 10 }}>
        {currentProcess && (
          <FormControl component="fieldset">
            <Typography variant="h6">{t('pages.documents.changeProcess.form.step')}</Typography>
            <FormGroup>
              {currentAgent?.steps &&
                currentAgent?.steps
                  .filter((step) => step.process === currentProcess)
                  .map((step) => (
                    <FormControlLabel
                      control={
                        <Switch checked={!step.disabled} onChange={onStepSwitched} name={step.label} color="primary" />
                      }
                      label={step.label}
                    />
                  ))}
            </FormGroup>
          </FormControl>
        )}
      </Grid>

      <Button color="default" variant="contained" onClick={cancel}>
        {t('pages.documents.changeProcess.form.cancel')}
      </Button>
      <Button color="primary" variant="contained" onClick={updateProcess}>
        {t('pages.documents.changeProcess.form.updateNewProcess')}
        {updateStatus.inProgress && <Spinner />}
      </Button>
    </Grid>
  );
};

export default ChangeProcess;
