import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import linkedinIcon from '../../../assets/images/linkedin.png';
import twitterIcon from '../../../assets/images/twitter.png';
import { IResume } from '@models/create.model';
import Education from './Education';
import Experience from './Experience';
import Goal from './Goal';
import Personal from './Header';
import Skills from './Skills';

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  } as any,
  rightColumn: {
    paddingLeft: 15,
    paddingTop: 15,
    flexDirection: 'column',
    flex: 1,
  },
  leftColumn: {
    flexDirection: 'column',
    backgroundColor: '#dcdcdc',
    width: 170,
    paddingTop: 20,
    paddingRight: 15,
    paddingLeft: 15,
    marginTop: 10,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  } as any,
  image: {
    marginBottom: 10,
    alignSelf: 'center',
    width: 84,
    height: 108,
  },
  informations: {
    fontSize: 10,
    fontFamily: 'Lato',
  },
  icon: {
    width: 40,
    height: 40,
    margin: 10,
    backgroundColor: 'transparent',
  },
  footer: {
    fontSize: 12,
    fontFamily: 'Lato Bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 25,
    paddingTop: 10,
  } as any,
});

Font.register({
  family: 'Open Sans',
  src: `/fonts/Open_Sans/OpenSans-Regular.ttf`,
});
Font.register({
  family: 'Lato',
  src: `/fonts/Lato/Lato-Regular.ttf`,
});
Font.register({
  family: 'Lato Italic',
  src: `/fonts/Lato/Lato-Italic.ttf`,
});
Font.register({
  family: 'Lato Bold',
  src: `/fonts/Lato/Lato-Bold.ttf`,
});

interface ResumeProps {
  size: string;
  resume: IResume;
}

export const Resume: FunctionComponent<ResumeProps> = ({ resume }) => (
  <Page size="A4" style={styles.page}>
    <Personal header={resume.personal} />
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        {resume.personal?.picture && resume.personal?.picture !== '' && (
          <Image src={resume.personal?.picture as string} allowDangerousPaths style={styles.image} />
        )}

        <View style={styles.informations}>
          <Text>Né(e) le {moment(resume.personal.birthday).format('L')}</Text>
          <Text>{moment(resume.personal.birthday).from(moment(), true)}</Text>
          {resume.personal.nationality && <Text>Nationnalité {resume.personal.nationality}</Text>}

          <View style={{ marginTop: 10, marginBottom: 10 }}>
            <Text>{resume.personal.address.line1}</Text>
            {/* {resume.personal.address.line2 && <Text>{resume.personal.address.line2}</Text>} */}
            <Text>
              {resume.personal.address.zipCode} {resume.personal.address.city}
            </Text>
            <Text>{resume.personal.address.country}</Text>
          </View>
        </View>

        <Education educations={resume.educations} />
        <Skills skills={resume.skills} />
      </View>
      <View style={styles.rightColumn}>
        <Goal goal={resume.personal.goal as string} />
        <Experience experiences={resume.experiences} />
      </View>
    </View>
    <View fixed style={styles.footer}>
      {resume.personal.twitter && (
        <Link src={resume.personal.twitter}>
          <Text>
            <Image style={styles.icon} src={linkedinIcon} allowDangerousPaths />
          </Text>
        </Link>
      )}
      {resume.personal.linkedin && (
        <Link src={resume.personal.linkedin}>
          <Text>
            <Image style={styles.icon} src={twitterIcon} allowDangerousPaths />
          </Text>
        </Link>
      )}
    </View>
  </Page>
);

interface ResumeOutputProps {
  author?: string;
  keywords?: string;
  subject?: string;
  title?: string;
  resume?: IResume;
}

export const ResumeOutput: FunctionComponent<ResumeOutputProps> = ({ author, keywords, subject, title, resume }) => (
  <Document author={author} keywords={keywords} subject={subject} title={title}>
    <Resume size="A4" resume={resume as IResume} />
  </Document>
);
