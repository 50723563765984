import { SodiumPlus } from 'sodium-plus';

export class SymmetricTreezorEncryption {
    static instance: SymmetricTreezorEncryption;
    sodium: any;

    constructor() {
        if (SymmetricTreezorEncryption.instance) {
            return SymmetricTreezorEncryption.instance;
        }

        SymmetricTreezorEncryption.instance = this;
    }

    async init() {
        if (!this.sodium) {
            this.sodium = await SodiumPlus.auto();
        }
    }

    /**
     * @param {string} key The hex key to encrypt
     * @param {string} passphrase The passphrase used to to encrypt key
     * 
     * @return {Promise<{encryptedKey: string, nonce: string, salt: string}>} The hex key encryted with passphrase, hex salt and hex nonce used  
     */
    async encryptKey(key: string, passphrase: string): Promise<string> {
        try {
            const saltBin = await this.sodium.randombytes_buf(16);
            const saltHex = await this.sodium.sodium_bin2hex(saltBin);

            const nonceBin = await this.sodium.randombytes_buf(24);
            const nonceHex = await this.sodium.sodium_bin2hex(nonceBin);

            const passwordKey = await this.sodium.crypto_pwhash(
                32,
                passphrase,
                saltBin,
                this.sodium.CRYPTO_PWHASH_OPSLIMIT_INTERACTIVE,
                this.sodium.CRYPTO_PWHASH_MEMLIMIT_INTERACTIVE
            );

            const encryptedKeyBin = await this.sodium.crypto_secretbox(
                this.sodium.sodium_hex2bin(key),
                nonceBin,
                passwordKey
            );

            const encryptedKeyHex = await this.sodium.sodium_bin2hex(encryptedKeyBin);

            return `${saltHex}.${encryptedKeyHex}.${nonceHex}`;
        } catch (error) {
            console.error(error);
            throw new Error('[Symmetric] Encrypt Key');
        }
    }

    /**
     * @param {string} encryptedKey The hex key to decrypt
     * @param {string} passphrase The passphrase used to encrypt this encrypted key
     * 
     * @return {Promise<string>} The hex key encryted with passphrase, hex salt and hex nonce used  
     */
    async decryptKey(encryptedKey: string, passphrase: string): Promise<string> {
        try {
            const [salt, key, nonce] = encryptedKey.split('.');

            if (!salt || !key || !nonce)
                throw new Error('Encrypted Key malformed. Expected Format : [salt].[key].[nonce]');

            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.sodium.CRYPTO_AUTH_BYTES;
            const passwordKey = await this.sodium.crypto_pwhash(
                32,
                passphrase,
                await this.sodium.sodium_hex2bin(salt),
                this.sodium.CRYPTO_PWHASH_OPSLIMIT_INTERACTIVE,
                this.sodium.CRYPTO_PWHASH_MEMLIMIT_INTERACTIVE
            );

            const decryptedKeyBin = await this.sodium.crypto_secretbox_open(
                this.sodium.sodium_hex2bin(key),
                await this.sodium.sodium_hex2bin(nonce),
                passwordKey
            );

            const decryptedKeyHex = await this.sodium.sodium_bin2hex(decryptedKeyBin);

            return decryptedKeyHex
        } catch (error) {
            console.error(error);
            throw new Error('[Symmetric] Decrypt Key');
        }
    }
}
