import { AgentStatus, DocumentStatus, IAddCommentRequest, IAgent, INotification } from '@models/agent.model';
import { IResponse } from '@models/core.model';
import { AppState, appStore } from '@store/configuration';
import { AuthError } from '@tools/AppError';
import { Colors, StatusCategory } from '@tools/constants';
import { FileUtils } from '@tools/file.utils';
import { Config } from '../../config';

const AgentService = {
  all: async (status?: AgentStatus, page = 1): Promise<IAgent[]> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const params = new URLSearchParams();
    page && params.set('pageNumber', `${page}`);
    status && params.set('status', status);

    const getAllAgentsResponse = await fetch(`${Config.api.coreOnboarding}/agent?${params.toString()}`, { headers });

    if (!getAllAgentsResponse.ok) {
      throw new AuthError(getAllAgentsResponse.status, JSON.stringify(getAllAgentsResponse.body));
    }

    const agents = (await getAllAgentsResponse.json()) as IAgent[];

    agents.map((agent) => AgentService.populate(agent));

    return agents;
  },
  get: async (agentId: string): Promise<IAgent> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const url = `${Config.api.coreOnboarding}/agent/${agentId}`;

    const getAgentResponse = await fetch(url, { headers });

    if (!getAgentResponse.ok) {
      throw new AuthError(getAgentResponse.status, JSON.stringify(getAgentResponse.body));
    }

    const agent = (await getAgentResponse.json()) as IAgent;

    return AgentService.populate(agent);
  },
  findByCurrentUser: async (): Promise<IAgent> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const url = `${Config.api.coreOnboarding}/agent/userId/${state.user.currentUser?.id}`;

    const getAgentResponse = await fetch(url, { headers });

    if (!getAgentResponse.ok) {
      throw new AuthError(getAgentResponse.status, JSON.stringify(getAgentResponse.body));
    }

    const agent = (await getAgentResponse.json()) as IAgent;

    return AgentService.populate(agent);
  },
  post: async (agentRequest: IAgent): Promise<IAgent> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const postAgentResponse = await fetch(`${Config.api.coreOnboarding}/agent`, {
      method: 'POST',
      headers,
      body: JSON.stringify(agentRequest),
    });

    if (!postAgentResponse.ok) {
      throw new AuthError(postAgentResponse.status, JSON.stringify(postAgentResponse.body));
    }

    const agent = (await postAgentResponse.json()) as IAgent;

    return AgentService.populate(agent);
  },
  put: async (agentRequest: IAgent): Promise<IAgent> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const putAgentResponse = await fetch(`${Config.api.coreOnboarding}/agent/${agentRequest?.agentId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(AgentService.unpopulate({ ...agentRequest, agentId: undefined })),
    });

    if (!putAgentResponse.ok) {
      throw new AuthError(putAgentResponse.status, JSON.stringify(putAgentResponse.body));
    }

    const agent = (await putAgentResponse.json()) as IAgent;

    return AgentService.populate(agent);
  },
  putComment: async (commentRequest: IAddCommentRequest): Promise<IAgent> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const putMessageResponse = await fetch(
      `${Config.api.coreOnboarding}/agent/${state.agent.currentAgent?.agentId}/comment`,
      {
        method: 'PUT',
        headers,
        body: JSON.stringify(commentRequest),
      },
    );

    if (!putMessageResponse.ok) {
      throw new AuthError(putMessageResponse.status, JSON.stringify(putMessageResponse.body));
    }

    const agent = (await putMessageResponse.json()) as IAgent;

    return AgentService.populate(agent);
  },
  populate: (agent: IAgent): IAgent => {
    const state: AppState = appStore.getState();
    const currentUser = state.user.currentUser;

    agent?.steps?.forEach((step) => {
      let countDone = 0,
        countTodoAgent = 0,
        countTodoSupport = 0;
      step.documents?.forEach((document) => {
        document.permission = {
          template: Boolean(document.template && document.type !== 'URL') && document.status !== 'REMOVED',
          download:
            Boolean((document.path || document.default) && document.type !== 'URL') && document.status !== 'REMOVED',
          preview:
            Boolean(
              document.type === 'URL' ||
                FileUtils.isPreviewable(document.mimeType) ||
                FileUtils.isPreviewable(document.default),
            ) && document.status !== 'REMOVED',
          upload:
            (currentUser?.type === 'support-user' || (document.source === 'CLIENT' && currentUser?.type === 'user')) &&
            (document.type === 'CREATE' || document.type === 'GENERATE' || document.type === 'UPLOAD'),
          link:
            (currentUser?.type === 'support-user' || (document.source === 'CLIENT' && currentUser?.type === 'user')) &&
            document.type === 'URL',
          edit:
            (currentUser?.type === 'support-user' || (document.source === 'CLIENT' && currentUser?.type === 'user')) &&
            (document.type === 'CREATE' || document.type === 'GENERATE') &&
            document.status !== 'TO_PROVIDE',
          trash:
            Boolean(document.path) &&
            (currentUser?.type === 'support-user' ||
              (currentUser?.type === 'user' && document.status === 'TO_VERIFY')) &&
            document.status !== 'TO_PROVIDE',
        };

        document.agentInfo = { ...document.changes.find((change) => change.source === 'CLIENT') };
        document.supportInfo = { ...document.changes.find((change) => change.source === 'SUPPORT') };
        document.complianceInfo = { ...document.changes.find((change) => change.source === 'COMPLIANCE') };

        if (StatusCategory.done.includes(document.complianceInfo?.status as DocumentStatus)) {
          countDone++;
          document.statusColor = Colors.status.done;
        } else if (
          StatusCategory.todoSupport.includes(document.supportInfo?.status as DocumentStatus) ||
          StatusCategory.todoCompliance.includes(document.complianceInfo?.status as DocumentStatus)
        ) {
          countTodoSupport++;
          document.statusColor = Colors.status.todoSupport;
        } else {
          countTodoAgent++;
          document.statusColor = Colors.status.todoAgent;
        }
      });
      step.count = {
        todoAgent: countTodoAgent,
        todoSupport: countTodoSupport,
        done: countDone,
      };

      step.startFrom =
        step.documents &&
        Math.min(
          ...step.documents.map((document) =>
            Math.min(...document.changes.map((change) => change.date || Number.MAX_SAFE_INTEGER)),
          ),
        );
    });

    const notifications: INotification[] = [];

    if (!agent.steps) return agent;

    agent.steps
      .filter((step) => step.process === agent.currentProcess && !step.disabled)
      .forEach((step) => {
        step.documents?.forEach((document) => {
          document.changes
            .filter((change) => change.action)
            .forEach((change) =>
              notifications.push({
                action: change.action,
                document: String(document.name),
                date: change.date,
                source: change.source,
                timestamp: Number(change.date),
                step: String(step.label),
              }),
            );
        });
      });

    notifications.sort((notifA, notifB) => Number(notifB?.timestamp) - Number(notifA?.timestamp));

    agent.notifications = notifications.slice(0, 10);

    agent.createdAt =
      agent.createdAt || Math.min(...agent.steps.map((step) => step.startFrom || Number.MAX_SAFE_INTEGER));

    return agent;
  },
  unpopulate: (agent: IAgent): IAgent => {
    agent?.steps?.forEach((step) => {
      step.documents?.forEach((document) => {
        delete document.permission;
        delete document.statusColor;
        delete document.agentInfo;
        delete document.supportInfo;
        delete document.complianceInfo;
        delete document.commentsIsExpand;
      });
      delete step.count;
      delete step.expand;
    });

    return agent;
  },
  delete: async (agentRequest: IAgent): Promise<IResponse> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const deleteAgentResponse = await fetch(`${Config.api.coreOnboarding}/agent/${agentRequest.agentId}`, {
      method: 'DELETE',
      headers,
    });

    if (!deleteAgentResponse.ok) {
      throw new AuthError(deleteAgentResponse.status, JSON.stringify(deleteAgentResponse.body));
    }

    return await deleteAgentResponse.json();
  },
  updateProspectStatus: async (agentId: string, status: AgentStatus): Promise<IAgent> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const updateProspectStatusResponse = await fetch(`${Config.api.coreOnboarding}/prospect/status`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({
        agentId: agentId,
        status: status,
      }),
    });

    if (!updateProspectStatusResponse.ok) {
      throw new AuthError(updateProspectStatusResponse.status, JSON.stringify(updateProspectStatusResponse.body));
    }

    return await updateProspectStatusResponse.json();
  },
};

const AWSService = {
  getPresignedFile: async (method: 'get' | 'put' | 'delete', agentId: string, documentId?: string): Promise<string> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const body = { agentId, documentId };

    const filePresignedResponse = await fetch(`${Config.api.coreOnboarding}/agent/file/presigned/${method}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });

    if (!filePresignedResponse.ok) {
      throw new AuthError(filePresignedResponse.status, JSON.stringify(filePresignedResponse.body));
    }

    const fileSigned = (await filePresignedResponse.json()) as string;

    return fileSigned;
  },
};

const FileService = {
  getFile: async (agentId: string, documentId: string) => {
    const filePresigned = await AWSService.getPresignedFile('get', agentId, documentId);

    if (!filePresigned) {
      throw new Error('template not fount');
    }

    const templateResponse = await fetch(filePresigned);

    if (!templateResponse.ok) {
      throw new AuthError(templateResponse.status, JSON.stringify(templateResponse.body));
    }
    const templateBuffer = await templateResponse.arrayBuffer();

    return templateBuffer;
  },
  putFile: async (file: File, agentId: string, documentId?: string) => {
    const filePresigned = await AWSService.getPresignedFile('put', agentId, documentId);

    if (!filePresigned) {
      throw new Error('template not fount');
    }

    const templateResponse = await fetch(filePresigned, {
      method: 'PUT',
      body: file,
    });

    if (!templateResponse.ok) {
      throw new AuthError(templateResponse.status, JSON.stringify(templateResponse.body));
    }
    const templateBuffer = await templateResponse.arrayBuffer();

    return templateBuffer;
  },
  deleteFile: async (agentId: string, documentId: string) => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const body = { agentId, documentId };

    const deleteResponse = await fetch(`${Config.api.coreOnboarding}/agent/file`, {
      method: 'DELETE',
      headers,
      body: JSON.stringify(body),
    });

    if (!deleteResponse.ok) {
      throw new AuthError(deleteResponse.status, JSON.stringify(deleteResponse.body));
    }
  },
};

export { AgentService, AWSService, FileService };
