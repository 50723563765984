import { IRules } from '@models/core.model';
import { appStore } from '@store/configuration';
import { AuthError } from '@tools/AppError';
import { Config } from '../../config';

const RulesService = {
  getCurrentRules: async (): Promise<IRules> => {
    const state = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const currentRulesResponse = await fetch(`${Config.api.coreOnboarding}/rules/current`, { headers });

    if (!currentRulesResponse.ok) {
      throw new AuthError(currentRulesResponse.status, JSON.stringify(currentRulesResponse.body));
    }

    const currentRules = (await currentRulesResponse.json()) as IRules;

    return currentRules;
  },
};

export { RulesService };
