import { LanguageChoice } from '@components/LanguageChoice';
import { logout } from '@store/auth/auth.action';
import { AppState } from '@store/configuration';
import React, { FunctionComponent } from 'react';
import { FaChevronRight, FaSignOutAlt, FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Nav, Navbar, NavbarBrand } from 'reactstrap';
import { Config } from '../../config';

interface ILayoutNavbarProps {}

const LayoutNavbar: FunctionComponent<ILayoutNavbarProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);

  const doLogout = () => {
    dispatch(logout());
    history.push('/login');
  };
  const goProfile = () =>
    currentUser?.type === 'support-user' ? history.push('/support-users') : history.push('/profile');

  return (
    <Navbar className="layout-navbar" expand="md">
      <NavbarBrand href="/">
        {Config.support.logo && <img src={Config.support.logo} width="100" alt="support logo" />}
        {!Config.support.logo && <div className="support-name">{Config.support.name}</div>}
      </NavbarBrand>
      <Nav className="mr-auto" navbar>
        {currentAgent?.company?.profile?.name && (
          <div className="current-agent-name">
            <FaChevronRight />
            {currentAgent?.company?.profile?.name}
          </div>
        )}
      </Nav>
      {(currentUser?.type === 'support-user' ||
        (currentUser?.type === 'user' && currentAgent?.status !== 'PRE_PROSPECT')) && (
        <Button onClick={goProfile}>
          <FaUser />
        </Button>
      )}
      <Button onClick={doLogout}>
        <FaSignOutAlt />
      </Button>
      <LanguageChoice />
    </Navbar>
  );
};

export default LayoutNavbar;
