import ErrorAlert from '@components/ErrorAlert';
import LayoutNavbar from '@components/layout/LayoutNavbar';
import LayoutSidebar from '@components/layout/LayoutSidebar';
import React, { useState } from 'react';
import { PageConfigContext } from '@components/layout/PageConfigContext';

interface IAppLayoutProps {
  children: any;
}

const AppLayout = (props: IAppLayoutProps) => {
  const [showNavBar, setShowNavBar] = useState(true);
  const [showSideBar, setShowSideBar] = useState(true);

  const [expand, setExpand] = useState<boolean>(false);
  const toggleExpand = () => setExpand(!expand);

  return (
    <PageConfigContext.Provider
      value={{
        setNavBarVisibility: (visibility: boolean) => setShowNavBar(visibility),
        setSideBarVisibility: (visibility: boolean) => setShowSideBar(visibility),
      }}>
      <ErrorAlert />
      <div className="layout-container">
        {/* --------- Navbar (top) ----------- */}
        {showNavBar && <LayoutNavbar />}
        <div className="layout-body">
          {/* -------- Sidebar (left) ------------*/}
          {showSideBar && (
            <LayoutSidebar
              isExpand={expand}
              toggleExpand={() => toggleExpand()}
              className={expand ? 'layout-sidebar-expand' : 'layout-sidebar'}
            />
          )}

          {/* -------- Content (right) ------------*/}
          <div className={showSideBar ? 'layout-page' : ''}>{props.children}</div>
        </div>
      </div>
    </PageConfigContext.Provider>
  );
};

export default AppLayout;
