import { DocumentStatus, IDocument, IDocumentChange } from '@models/agent.model';
import { DocumentMachine, DocumentMachineAction, DocumentMachineState } from '@models/machine.model';
import { removeDocumentFile, updateDocument, updateDocumentLink, uploadDocumentFile } from '@store/agent/agent.action';
import { appStore } from '@store/configuration';
import { action, createMachine, guard, immediate, reduce, state, transition } from 'robot3';

// Guards
const toProvide = (context: IDocument) => context.status === 'TO_PROVIDE' || context.status === 'REMOVED';
const toSign = (context: IDocument) => (context.required?.sign as boolean) && context.status === 'ACCEPTED';
const toVerify = (context: IDocument) => context.status === 'TO_VERIFY';
const toApprove = (context: IDocument) => context.status === 'TO_APPROVE';
const accepted = (context: IDocument) => context.status === 'ACCEPTED';
const agentSigned = (context: IDocument) => context.status === 'AGENT_SIGNED';
const supportSigned = (context: IDocument) => context.status === 'SUPPORT_SIGNED';
const done = (context: IDocument) => context.status === 'DONE';

// Reducers
const addChange = (context: IDocument, change: IDocumentChange): IDocument => ({
  ...context,
  changes: [
    {
      source: change.source,
      action: change.action,
      date: Date.now(),
      done: Boolean(change.done),
      status: change.status,
    },
    ...context.changes,
  ],
});
const updateStatus = (context: IDocument, status: DocumentStatus) => ({
  ...context,
  status,
});

// Actions
const dispatchUploadDocumentFile = async (context: IDocument, payload: any) =>
  await appStore.dispatch(uploadDocumentFile(payload.file, context) as any);
const dispatchUpdateDocumentLink = (context: IDocument, payload: any) =>
  appStore.dispatch(updateDocumentLink(payload.link, context) as any);
const dispatchUpdateDocument = (context: IDocument) => appStore.dispatch(updateDocument(context) as any);
const dispatchRemoveDocument = (context: IDocument) => appStore.dispatch(removeDocumentFile(context) as any);

// Joker Transitions

const jokerTransitions = [
  transition(
    DocumentMachineAction.COMPLIANCE_REMOVE,
    DocumentMachineState.PROVIDE,
    reduce((context: IDocument) =>
      addChange(context, { source: 'CLIENT', status: context.source === 'CLIENT' ? 'TO_PROVIDE' : 'PENDING' }),
    ),
    reduce((context: IDocument) =>
      addChange(context, { source: 'SUPPORT', status: context.source === 'SUPPORT' ? 'TO_PROVIDE' : 'PENDING' }),
    ),
    reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', action: 'REMOVE', status: 'REMOVED' })),
    reduce((context: IDocument) => updateStatus(context, 'REMOVED')),
    action(dispatchRemoveDocument),
  ),
  transition(
    DocumentMachineAction.AGENT_REMOVE,
    DocumentMachineState.PROVIDE,
    reduce((context: IDocument) =>
      addChange(context, {
        source: 'CLIENT',
        action: 'REMOVE',
        status: context.source === 'CLIENT' ? 'TO_PROVIDE' : 'PENDING',
      }),
    ),
    reduce((context: IDocument) =>
      addChange(context, { source: 'SUPPORT', status: context.source === 'SUPPORT' ? 'TO_PROVIDE' : 'PENDING' }),
    ),
    reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'PENDING' })),
    reduce((context: IDocument) => updateStatus(context, 'REMOVED')),
    action(dispatchRemoveDocument),
  ),
];

const context = (context: IDocument) => context;
export const createDocumentStateMachine = () =>
  createMachine<DocumentMachine, IDocument>(
    {
      idle: state(
        immediate(DocumentMachineState.DONE, guard(done)),
        immediate(DocumentMachineState.COMPLIANCE, guard(toApprove)),
        immediate(DocumentMachineState.SIGN, guard(toSign)),
        immediate(DocumentMachineState.ACCEPTANCE, guard(toVerify)),
        immediate(DocumentMachineState.PROVIDE, guard(toProvide)),
      ),
      provide: state(
        transition(
          DocumentMachineAction.AGENT_READ,
          DocumentMachineState.PROVIDE,
          reduce((context: IDocument) =>
            addChange(context, { source: 'CLIENT', action: 'READ', done: true, status: 'READ' }),
          ),
          reduce((context: IDocument) =>
            addChange(context, {
              source: 'SUPPORT',
              status: context.type === 'UPLOAD' ? 'UPLOADED' : 'LINKED',
              done: true,
            }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'N/A' })),
          reduce((context: IDocument) => updateStatus(context, 'DONE')),
          action(dispatchUpdateDocument),
        ),
        transition(
          DocumentMachineAction.AGENT_UPLOAD,
          DocumentMachineState.PROVIDE,
          reduce((context: IDocument) =>
            addChange(context, { source: 'CLIENT', action: 'UPLOAD', done: true, status: 'UPLOADED' }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'SUPPORT', status: 'TO_VERIFY' })),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'PENDING' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_VERIFY')),
          action(dispatchUploadDocumentFile),
        ),
        transition(
          DocumentMachineAction.AGENT_LINK,
          DocumentMachineState.PROVIDE,
          reduce((context: IDocument) =>
            addChange(context, { source: 'CLIENT', action: 'LINK', done: true, status: 'LINKED' }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'SUPPORT', status: 'TO_VERIFY' })),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'PENDING' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_VERIFY')),
          action(dispatchUpdateDocumentLink),
        ),
        transition(
          DocumentMachineAction.SUPPORT_UPLOAD,
          DocumentMachineState.COMPLIANCE,
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', done: true, status: 'DONE' })),
          reduce((context: IDocument) =>
            addChange(context, { source: 'SUPPORT', action: 'UPLOAD', done: true, status: 'UPLOADED' }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'TO_APPROVE' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_APPROVE')),
          action(dispatchUploadDocumentFile),
        ),
        transition(
          DocumentMachineAction.SUPPORT_LINK,
          DocumentMachineState.COMPLIANCE,
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', done: true, status: 'DONE' })),
          reduce((context: IDocument) =>
            addChange(context, { source: 'SUPPORT', action: 'LINK', done: true, status: 'LINKED' }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'TO_APPROVE' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_APPROVE')),
          action(dispatchUpdateDocumentLink),
        ),
        transition(
          DocumentMachineAction.COMPLIANCE_UNDO,
          DocumentMachineState.COMPLIANCE,
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', done: true, status: 'DONE' })),
          reduce((context: IDocument) => addChange(context, { source: 'SUPPORT', done: true, status: 'DONE' })),
          reduce((context: IDocument) =>
            addChange(context, { source: 'COMPLIANCE', action: 'UNDO', status: 'TO_APPROVE' }),
          ),
          reduce((context: IDocument) => updateStatus(context, 'TO_APPROVE')),
          action(dispatchUpdateDocument),
        ),
        transition(
          DocumentMachineAction.SUPPORT_UNDO,
          DocumentMachineState.ACCEPTANCE,
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', done: true, status: 'DONE' })),
          reduce((context: IDocument) => addChange(context, { source: 'SUPPORT', status: 'TO_VERIFY' })),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'PENDING' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_VERIFY')),
          action(dispatchUpdateDocument),
        ),
        ...jokerTransitions,
      ),
      acceptance: state(
        transition(
          DocumentMachineAction.AGENT_UPLOAD,
          DocumentMachineState.ACCEPTANCE,
          reduce((context: IDocument) =>
            addChange(context, { source: 'CLIENT', action: 'UPLOAD', done: true, status: 'UPLOADED' }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'SUPPORT', status: 'TO_VERIFY' })),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'PENDING' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_VERIFY')),
          action(dispatchUploadDocumentFile),
        ),
        transition(
          DocumentMachineAction.AGENT_LINK,
          DocumentMachineState.ACCEPTANCE,
          reduce((context: IDocument) =>
            addChange(context, { source: 'CLIENT', action: 'LINK', done: true, status: 'LINKED' }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'SUPPORT', status: 'TO_VERIFY' })),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'PENDING' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_VERIFY')),
          action(dispatchUpdateDocumentLink),
        ),

        transition(
          DocumentMachineAction.SUPPORT_ACCEPT,
          DocumentMachineState.SIGN,
          guard(toSign),
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', done: true, status: 'TO_SIGN' })),
          reduce((context: IDocument) =>
            addChange(context, { source: 'SUPPORT', action: 'ACCEPT', done: true, status: 'ACCEPTED' }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'TO_APPROVE' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_SIGN')),
          action(dispatchUpdateDocument),
        ),
        transition(
          DocumentMachineAction.SUPPORT_ACCEPT,
          DocumentMachineState.COMPLIANCE,
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', done: true, status: 'DONE' })),
          reduce((context: IDocument) =>
            addChange(context, { source: 'SUPPORT', action: 'ACCEPT', done: true, status: 'ACCEPTED' }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'TO_APPROVE' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_APPROVE')),
          action(dispatchUpdateDocument),
        ),
        transition(
          DocumentMachineAction.SUPPORT_REFUSE,
          DocumentMachineState.PROVIDE,
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', status: 'TO_PROVIDE' })),
          reduce((context: IDocument) =>
            addChange(context, { source: 'SUPPORT', action: 'REFUSE', status: 'REFUSED' }),
          ),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'PENDING' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_PROVIDE')),
          action(dispatchUpdateDocument),
        ),
        ...jokerTransitions,
      ),
      sign: state(
        transition(
          DocumentMachineAction.SUPPORT_SIGN,
          DocumentMachineState.COMPLIANCE,
          guard(agentSigned),
          reduce((context: IDocument) => addChange(context, { source: 'SUPPORT', action: 'SIGN', status: 'SIGNED' })),
        ),
        transition(
          DocumentMachineAction.SUPPORT_SIGN,
          DocumentMachineState.SIGN,
          guard(accepted),
          reduce((context: IDocument) =>
            addChange(context, { source: 'SUPPORT', action: 'SIGN', status: 'SUPPORT_SIGNED' }),
          ),
        ),
        transition(
          DocumentMachineAction.AGENT_SIGN,
          DocumentMachineState.COMPLIANCE,
          guard(supportSigned),
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', action: 'SIGN', status: 'SIGNED' })),
        ),
        transition(
          DocumentMachineAction.AGENT_SIGN,
          DocumentMachineState.SIGN,
          guard(accepted),
          reduce((context: IDocument) =>
            addChange(context, { source: 'CLIENT', action: 'SIGN', status: 'AGENT_SIGNED' }),
          ),
        ),
        ...jokerTransitions,
      ),
      compliance: state(
        transition(
          DocumentMachineAction.SUPPORT_UNDO,
          DocumentMachineState.ACCEPTANCE,
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', done: true, status: 'DONE' })),
          reduce((context: IDocument) => addChange(context, { source: 'SUPPORT', status: 'TO_VERIFY' })),
          reduce((context: IDocument) => addChange(context, { source: 'COMPLIANCE', status: 'PENDING' })),
          reduce((context: IDocument) => updateStatus(context, 'TO_VERIFY')),
          action(dispatchUpdateDocument),
        ),
        transition(
          DocumentMachineAction.COMPLIANCE_APPROVE,
          DocumentMachineState.DONE,
          reduce((context: IDocument) => addChange(context, { source: 'CLIENT', done: true, status: 'DONE' })),
          reduce((context: IDocument) => addChange(context, { source: 'SUPPORT', done: true, status: 'DONE' })),
          reduce((context: IDocument) =>
            addChange(context, { source: 'COMPLIANCE', action: 'APPROVE', done: true, status: 'APPROVED' }),
          ),
          reduce((context: IDocument) => updateStatus(context, 'DONE')),
          action(dispatchUpdateDocument),
        ),
        transition(
          DocumentMachineAction.COMPLIANCE_REJECT,
          DocumentMachineState.PROVIDE,
          reduce((context: IDocument) =>
            addChange(context, { source: 'CLIENT', status: context.source === 'CLIENT' ? 'TO_PROVIDE' : 'PENDING' }),
          ),
          reduce((context: IDocument) =>
            addChange(context, { source: 'SUPPORT', status: context.source === 'SUPPORT' ? 'TO_PROVIDE' : 'PENDING' }),
          ),
          reduce((context: IDocument) =>
            addChange(context, { source: 'COMPLIANCE', action: 'REJECT', status: 'REJECTED' }),
          ),
          reduce((context: IDocument) => updateStatus(context, 'TO_PROVIDE')),
          action(dispatchUpdateDocument),
        ),
        ...jokerTransitions,
      ),
      done: state(
        transition(
          DocumentMachineAction.COMPLIANCE_UNDO,
          DocumentMachineState.COMPLIANCE,
          reduce((context: IDocument) =>
            addChange(context, { source: 'COMPLIANCE', action: 'UNDO', status: 'TO_APPROVE' }),
          ),
          reduce((context: IDocument) => updateStatus(context, 'TO_APPROVE')),
          action(dispatchUpdateDocument),
        ),
      ),
    },
    context,
  );
