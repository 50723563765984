/* eslint-disable react-hooks/exhaustive-deps */
import { getFluxQualificationRule, getQualificationRules } from '@assets/rules/qualification';
import { IInput, UIForm } from '@components/form/Form';
import Progress from '@components/ui/Progress';
import { Grid, makeStyles, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { IAgentCompany, IAgentLegalRepresentant } from '@models/agent.model';
import { IQualificationRule } from '@models/core.model';
import { updateAgent } from '@store/agent/agent.action';
import { AppState } from '@store/configuration';
import { Colors } from '@tools/constants';
import React, { CSSProperties, FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    // height: '100%',
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent !important',
    },
  },
  tab: {
    '&:hover': {
      color: Colors.treezor.blue,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: Colors.treezor.blue,
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: Colors.treezor.blue,
      outline: 'none',
    },
  },
}));

const styles: { [index: string]: CSSProperties } = {
  form: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' },
  col1: {
    margin: 10,
  },
  col2: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '45%',
    maxWidth: '45%',
    minWidth: '45%',
    margin: 10,
  },
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({ children, value, index, ...other }) => (
  <Grid role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
    {value === index && children}
  </Grid>
);

const Qualification = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);
  const updateStatus = useSelector((state: AppState) => state.agent.status.update);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const fluxQualificationRule = useMemo<IQualificationRule>(
    () => ({
      field: 'flux',
      inputs: [0, 1, 2]
        .map((year): IInput[] => getFluxQualificationRule(t, year).inputs)
        .reduce((acc, arr) => acc.concat(arr), []),
    }),
    [],
  );
  const qualificationRules = useMemo<IQualificationRule[]>(() => getQualificationRules(t), [t]);

  const updateAgentCompany = (field: string, data: any) => {
    if (!currentAgent?.company || !Object.keys(currentAgent?.company || {}).includes(field)) return;

    dispatch(
      updateAgent({
        company: {
          [field]: data,
        },
      }),
    );
  };

  const updateAgentLegalRepresentant = (legalRepresentant: IAgentLegalRepresentant) => {
    dispatch(
      updateAgent({
        legalRepresentant,
      }),
    );
  };

  const updateAgentFlux = (company: IAgentCompany) => {
    dispatch(
      updateAgent({
        company,
      }),
    );
  };

  const flattenObj = (obj: any, suffixe?: string, parent?: any, res: any = {}) => {
    for (let key in obj) {
      let propName = parent ? `${parent}_${key}` : key;
      propName = suffixe ? `${suffixe}_${propName}` : propName;
      res[propName] = null;
      if (Array.isArray(obj[key])) {
        res[propName] = obj[key];
      } else if (typeof obj[key] == 'object') {
        flattenObj(obj[key], suffixe, propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  const getProgression = (field: string) => {
    try {
      if (field === 'legalRepresentant') {
        const flatten = flattenObj(currentAgent?.legalRepresentant);
        return countProgression(flatten);
      }
      if (currentAgent?.company && currentAgent?.company[field]) {
        const flatten = flattenObj(currentAgent?.company[field]);
        return countProgression(flatten);
      }
      return 0;
    } catch (err) {
      console.error(err);
      return NaN;
    }
  };

  const getFluxProgression = () => {
    try {
      if (currentAgent?.company?.flux) {
        const flatten: any = currentAgent?.company?.flux.reduce(
          (acc, flux, index) => ({ ...acc, ...flattenObj(flux, `year${index}`) }),
          {},
        );
        return countProgression(flatten);
      }
      return 0;
    } catch (err) {
      console.error(err);
      return NaN;
    }
  };

  const countProgression = (flatObject: any): number => {
    const keys = Object.keys(flatObject);
    const keysCount = keys.length;

    if (keysCount > 0) {
      const keysNullCount = keys.filter(
        (attribute) => flatObject[attribute] === null || flatObject[attribute] === '' || flatObject[attribute] === [],
      ).length;

      return ((keysCount - keysNullCount) * 100) / keysCount;
    }
    return 0;
  };

  interface TabTitleProps {
    label: string;
    progression: number;
    className?: string;
  }
  const TabTitle: FunctionComponent<TabTitleProps> = ({ label, progression, className }) => (
    <Grid container direction="row" justify="center" alignItems="center">
      <Typography variant="subtitle2" className={className}>
        {label}
      </Typography>
      <Progress value={progression} style={{ marginLeft: 5 }} />
    </Grid>
  );

  return (
    <Grid>
      <Tabs
        value={activeTab}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}>
        {qualificationRules.map((rule) => (
          <Tab
            key={rule.field}
            label={
              <TabTitle
                label={t(`forms.${rule.field}.title`)}
                progression={getProgression(rule.field as string)}
                className={classes.tab}
              />
            }
          />
        ))}
        <Tab
          label={<TabTitle label={t('forms.flux.title')} progression={getFluxProgression()} className={classes.tab} />}
        />
      </Tabs>

      {qualificationRules.map((rule, index) => (
        <TabPanel key={rule.field} value={activeTab} index={index}>
          <UIForm
            isLoading={updateStatus.inProgress}
            style={styles.form}
            inputStyle={styles.col2}
            inputs={rule.inputs}
            defaultValues={
              rule.field === 'legalRepresentant'
                ? currentAgent?.legalRepresentant
                : (currentAgent?.company && currentAgent?.company[rule.field as string]) || {}
            }
            onSubmit={(values) =>
              rule.field === 'legalRepresentant'
                ? updateAgentLegalRepresentant(values)
                : updateAgentCompany(rule.field as string, values)
            }
          />
        </TabPanel>
      ))}

      <TabPanel value={activeTab} index={qualificationRules.length}>
        <UIForm
          isLoading={updateStatus.inProgress}
          style={styles.form}
          inputStyle={styles.col2}
          inputs={fluxQualificationRule.inputs}
          defaultValues={currentAgent?.company || []}
          onSubmit={(values) => updateAgentFlux(values)}
        />
      </TabPanel>
    </Grid>
  );
};

export default Qualification;
