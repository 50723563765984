export const ValidationUtils = {
  isURL(url: string): boolean {
    if (!url || url === '') return true;
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator

    return !!pattern.test(url);
  },
  isEmail(email: string): boolean {
    if (!email || email === '') return true;
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  },
  isPhoneNumber(phone: string): boolean {
    if (!phone || phone === '') return true;
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(String(phone));
  },

  isValidateSiret: (_siret: string) => {
    const siret = _siret.replace(/\s/g, '');
    if (siret.length !== 14 || isNaN(Number(siret))) return false;

    const siretValid =
      siret.split('').reduce((acc, char, index) => {
        let digit = Number(char);

        if (index % 2 === 0) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
        return (acc += digit);
      }, 0) %
        10 ===
      0;
    return siretValid;
  },
};

export const removeEmpty = (obj: any): any => {
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty(v) : v }), {});
};
