import { Button } from '@material-ui/core';
import { IAgentStep, IDocument } from '@models/agent.model';
import { DocumentMachineAction } from '@models/machine.model';
import { AppState } from '@store/configuration';
import { FileUtils } from '@tools/file.utils';
import React, { FunctionComponent, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface DocumentPreviewModalProps {
  className?: string;
  isOpen: boolean;
  toggle: () => void;
  document: IDocument;
  send: (type: any, params?: any) => void;
  step: IAgentStep;
}

const DocumentPreviewModal: FunctionComponent<DocumentPreviewModalProps> = ({
  document,
  toggle,
  isOpen,
  send,
  step,
}) => {
  const [numPages, setNumPages] = useState(null);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const accept = () => {
    send(DocumentMachineAction.SUPPORT_ACCEPT, step);
    toggle();
  };

  const refuse = () => {
    send(DocumentMachineAction.SUPPORT_REFUSE);
    toggle();
  };

  const approve = () => {
    send(DocumentMachineAction.COMPLIANCE_APPROVE);
    toggle();
  };

  const reject = () => {
    send(DocumentMachineAction.COMPLIANCE_REJECT);
    toggle();
  };

  if (!isOpen) return null;

  return (
    <Modal centered={true} isOpen toggle={toggle} style={{ maxWidth: 670 }} className="document-preview-modal">
      {isOpen && FileUtils.isPDF(document.mimeType || document.default || document.template) && (
        <Document file={{ url: document.presignedUrl }} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page renderInteractiveForms key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      )}

      {isOpen && FileUtils.isImage(document.mimeType || document.default || document.template) && (
        <img style={{ maxWidth: 670 }} src={document.presignedUrl} alt="Document" />
      )}

      {currentUser?.type === 'support-user' && numPages !== null && (
        <div className="actions-preview">
          {document.supportInfo?.status === 'TO_VERIFY' && (
            <Button variant="contained" color="primary" onClick={accept}>
              Valider
            </Button>
          )}
          {document.supportInfo?.status === 'TO_VERIFY' && (
            <Button variant="contained" color="secondary" onClick={refuse}>
              Refuser
            </Button>
          )}
          {document.complianceInfo?.status === 'TO_APPROVE' && (
            <Button variant="contained" color="primary" onClick={approve}>
              Valider
            </Button>
          )}
          {document.complianceInfo?.status === 'TO_APPROVE' && (
            <Button variant="contained" color="secondary" onClick={reject}>
              Refuser
            </Button>
          )}
        </div>
      )}
    </Modal>
  );
};

export default DocumentPreviewModal;
