import { IOnboardingSupportUser, IOrganization, IUser } from '@models/user.model';

export enum UserActionTypes {
  CREATE = 'USER_CREATE',
  REMOVE = 'USER_REMOVE',

  ORGANIZATION_GET_REQUEST = 'ORGANIZATION_GET_REQUEST',
  ORGANIZATION_GET_SUCESS = 'ORGANIZATION_GET_SUCESS',
  ORGANIZATION_GET_FAILURE = 'ORGANIZATION_GET_FAILURE',

  SUPPORT_USER_POST_REQUEST = 'SUPPORT_USER_POST_REQUEST',
  SUPPORT_USER_POST_SUCESS = 'SUPPORT_USER_POST_SUCESS',
  SUPPORT_USER_POST_FAILURE = 'SUPPORT_USER_POST_FAILURE',

  SUPPORT_USER_PUT_REQUEST = 'SUPPORT_USER_PUT_REQUEST',
  SUPPORT_USER_PUT_SUCESS = 'SUPPORT_USER_PUT_SUCESS',
  SUPPORT_USER_PUT_FAILURE = 'SUPPORT_USER_PUT_FAILURE',

  RESET = 'RESET_AGENT',
}

export interface UserCreateAction {
  type: typeof UserActionTypes.CREATE;
  currentUser: IUser;
}

export interface UserRemoveAction {
  type: typeof UserActionTypes.REMOVE;
}

export interface OrganizationGetRequestAction {
  type: typeof UserActionTypes.ORGANIZATION_GET_REQUEST;
}

export interface OrganizationGetSuccessAction {
  type: typeof UserActionTypes.ORGANIZATION_GET_SUCESS;
  organization: IOrganization;
}

export interface OrganizationGetFailureAction {
  type: typeof UserActionTypes.ORGANIZATION_GET_FAILURE;
  error: any;
}

export interface SupportUserAddRequestAction {
  type: typeof UserActionTypes.SUPPORT_USER_POST_REQUEST;
}

export interface SupportUserAddSuccessAction {
  type: typeof UserActionTypes.SUPPORT_USER_POST_SUCESS;
  supportUser: IOnboardingSupportUser;
}

export interface SupportUserAddFailureAction {
  type: typeof UserActionTypes.SUPPORT_USER_POST_FAILURE;
  error: any;
}

export interface SupportUserUpdateRequestAction {
  type: typeof UserActionTypes.SUPPORT_USER_PUT_REQUEST;
}

export interface SupportUserUpdateSuccessAction {
  type: typeof UserActionTypes.SUPPORT_USER_PUT_SUCESS;
  supportUser: IOnboardingSupportUser;
}

export interface SupportUserUpdateFailureAction {
  type: typeof UserActionTypes.SUPPORT_USER_PUT_FAILURE;
  error: any;
}

export interface ResetAction {
  type: typeof UserActionTypes.RESET;
}

export type UserAction =
  | UserCreateAction
  | UserRemoveAction
  | OrganizationGetRequestAction
  | OrganizationGetSuccessAction
  | OrganizationGetFailureAction
  | SupportUserAddRequestAction
  | SupportUserAddSuccessAction
  | SupportUserAddFailureAction
  | SupportUserUpdateRequestAction
  | SupportUserUpdateSuccessAction
  | SupportUserUpdateFailureAction
  | ResetAction;

export interface UserState {
  currentUser?: IUser;
  inProgress: boolean;
  organization?: IOrganization;
}
