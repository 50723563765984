import { createStyles, makeStyles, Popover, Theme, Typography } from '@material-ui/core';
import React, { FunctionComponent, MouseEvent, useState } from 'react';
import { IconType } from 'react-icons/lib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);

interface ButtonPopoverProps {
  Icon: IconType;
  description: string;
  disabled?: boolean;
  onClick: () => void;
}

export const ButtonPopover: FunctionComponent<ButtonPopoverProps> = ({ description, disabled, onClick, Icon }) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState<SVGElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<SVGElement>) => {
    setAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  return (
    <>
      {Icon({
        className: disabled ? 'disabled' : '',
        onClick,
        onMouseOver: handlePopoverOpen,
        onMouseOut: handlePopoverClose,
        style: { margin: 10, fontSize: 'large', cursor: 'pointer' },
      })}
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        transitionDuration={{
          appear: 2000,
          enter: 3000,
          exit: 2000,
        }}
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Typography>{description}</Typography>
      </Popover>
    </>
  );
};
