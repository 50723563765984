import { IQualificationRule } from '@models/core.model';
import { ValidationUtils } from '@tools/validation';
import { TFunction } from 'i18next';

export const getQualificationRules = (t: TFunction): IQualificationRule[] => [
  {
    field: 'profile',
    inputs: [
      { name: 'name', label: t('forms.profile.name') },
      { name: 'legalStatus', label: t('forms.profile.legalStatus') },
      {
        name: 'siret',
        label: t('forms.profile.siret'),
        rules: {
          validate: (value) =>
            (value && ValidationUtils.isValidateSiret(value)) || (t('forms.profile.rules.incorrectSiret') as string),
        },
      },
      { name: 'shareCapital', label: t('forms.profile.shareCapital') },
      { name: 'commercialName', label: t('forms.profile.commercialName') },
      { name: 'website', label: t('forms.profile.website') },
      { name: 'address.line1', label: t('forms.profile.address.line1') },
      { name: 'address.line2', label: t('forms.profile.address.line2') },
      { name: 'address.zipCode', label: t('forms.profile.address.zipCode') },
      { name: 'address.city', label: t('forms.profile.address.city') },
      { name: 'address.country', label: t('forms.profile.address.country') },
      { name: 'facebook', label: t('forms.profile.facebook') },
      { name: 'linkedin', label: t('forms.profile.linkedin') },
      { name: 'twitter', label: t('forms.profile.twitter') },
    ],
  },
  {
    field: 'legalRepresentant',
    inputs: [
      { name: 'firstname', label: t('forms.legalRepresentant.firstname') },
      { name: 'lastname', label: t('forms.legalRepresentant.lastname') },
      { name: 'role', label: t('forms.legalRepresentant.role') },
      { name: 'email', label: t('forms.legalRepresentant.email') },
      { name: 'phone', label: t('forms.legalRepresentant.phone') },
      { name: 'birthday', label: t('forms.legalRepresentant.birthday'), type: 'date' },
      { name: 'nationality', label: t('forms.legalRepresentant.nationality') },
      { name: 'placeOfBirth', label: t('forms.legalRepresentant.placeOfBirth') },
    ],
  },
  {
    field: 'project',
    inputs: [
      { name: 'activityArea', label: t('forms.project.activityArea') },
      {
        name: 'commercialTarget',
        label: t('forms.project.commercialTarget.label'),
        radio: {
          options: [
            { label: t('forms.project.commercialTarget.b2b'), value: 'b2b' },
            { label: t('forms.project.commercialTarget.b2c'), value: 'b2c' },
            { label: t('forms.project.commercialTarget.both'), value: 'both' },
          ],
        },
      },
      { name: 'name', label: t('forms.project.name') },
      { name: 'countriesTarget', label: t('forms.project.countriesTarget'), list: true },
      { name: 'dateLaunched', label: t('forms.project.dateLaunched'), type: 'date' },
      { name: 'agrement', label: t('forms.project.agrement') },
      { name: 'psp', label: t('forms.project.psp') },
      { name: 'description', label: t('forms.project.description') },
      {
        name: 'commericialContact.firstname',
        label: `${t('forms.project.commericialContact')} - ${t('forms.core.contact.firstname')}`,
      },
      {
        name: 'commericialContact.lastname',
        label: `${t('forms.project.commericialContact')} - ${t('forms.core.contact.lastname')}`,
      },
      {
        name: 'commericialContact.email',
        label: `${t('forms.project.commericialContact')} - ${t('forms.core.contact.email')}`,
      },
      {
        name: 'commericialContact.phone',
        label: `${t('forms.project.commericialContact')} - ${t('forms.core.contact.phone')}`,
      },
      {
        name: 'technicalContact.firstname',
        label: `${t('forms.project.technicalContact')} - ${t('forms.core.contact.firstname')}`,
      },
      {
        name: 'technicalContact.lastname',
        label: `${t('forms.project.technicalContact')} - ${t('forms.core.contact.lastname')}`,
      },
      {
        name: 'technicalContact.email',
        label: `${t('forms.project.technicalContact')} - ${t('forms.core.contact.email')}`,
      },
      {
        name: 'technicalContact.phone',
        label: `${t('forms.project.technicalContact')} - ${t('forms.core.contact.phone')}`,
      },
    ],
  },
  {
    field: 'vad',
    inputs: [
      {
        name: 'creditCard.mastercard',
        label: t('forms.vad.mastercard'),
        type: 'boolean',
      },
      {
        name: 'creditCard.visa',
        label: t('forms.vad.visa'),
        type: 'boolean',
      },
      {
        name: 'creditCard.amex',
        label: t('forms.vad.amex'),
        type: 'boolean',
      },
      {
        name: 'creditCard.sofort',
        label: t('forms.vad.sofort'),
        type: 'boolean',
      },
      {
        name: 'creditCard.other',
        label: t('forms.vad.otherCard'),
        list: true,
      },
      {
        name: 'disengageable3DSecure',
        label: t('forms.vad.disengageable3DSecure'),
        type: 'boolean',
      },
      {
        name: 'integrationType',
        label: t('forms.vad.integrationType'),
        radio: {
          options: [
            { label: t('forms.project.commercialTarget.b2b'), value: 'b2b' },
            { label: t('forms.project.commercialTarget.b2c'), value: 'b2c' },
            { label: t('forms.project.commercialTarget.both'), value: 'both' },
          ],
        },
      },
      { name: 'btocRepartition', label: t('forms.vad.btocRepartition'), type: 'slider' },
      { name: 'mid', label: t('forms.vad.mid') },
      { name: 'otherNeeds', label: t('forms.vad.otherNeeds') },
    ],
  },
  {
    field: 'sepa',
    inputs: [
      { name: 'sepaAquisitionInput', label: t('forms.sepa.sepaAquisitionInput'), type: 'boolean' },
      { name: 'sepaAquisitionOutput', label: t('forms.sepa.sepaAquisitionOutput'), type: 'boolean' },
      { name: 'sepaEmmisionInput', label: t('forms.sepa.sepaEmmisionInput'), type: 'boolean' },
      { name: 'sepaEmmisionOutput', label: t('forms.sepa.sepaEmmisionOutput.label'), type: 'boolean' },
      { name: 'hasFREndUserIBAN', label: t('forms.sepa.hasFREndUserIBAN'), type: 'boolean' },
      { name: 'hasVirtualIBAN', label: t('forms.sepa.hasVirtualIBAN'), type: 'boolean' },
      { name: 'hasInternationalIBAN', label: t('forms.sepa.hasInternationalIBAN'), type: 'boolean' },
      { name: 'countriesIBAN', label: t('forms.sepa.countriesIBAN'), list: true },
    ],
  },
  {
    field: 'internationalTransfert',
    inputs: [
      {
        name: 'countries',
        label: t('forms.internationalTransfert.hasSEPACountries'),
        objecstList: {
          items: [
            { name: 'country', label: t('forms.internationalTransfert.countries.country') },
            { name: 'currency', label: t('forms.internationalTransfert.countries.currency') },
          ],
        },
      },
    ],
  },
  {
    field: 'programCard',
    inputs: [
      {
        name: 'emissionType',
        label: t('forms.programCard.emissionType.label'),
        radio: {
          options: [
            { label: t('forms.programCard.emissionType.options.physical'), value: 'physical' },
            { label: t('forms.programCard.emissionType.options.virtual'), value: 'virtual' },
            { label: t('forms.programCard.emissionType.options.both'), value: 'both' },
          ],
        },
      },
      { name: 'program.description', label: t('forms.programCard.program.description') },
      { name: 'program.target', label: t('forms.programCard.program.target') },
      { name: 'program.objectifs', label: t('forms.programCard.program.objectifs') },
      {
        name: 'sendingType',
        label: t('forms.programCard.sendingType.label'),
        radio: {
          options: [
            { label: t('forms.programCard.sendingType.options.holder'), value: 'holder' },
            { label: t('forms.programCard.sendingType.options.singleAddress'), value: 'singleAddress' },
          ],
        },
      },
      { name: 'dab', label: t('forms.programCard.dab'), type: 'boolean' },
      { name: 'rechargeable', label: t('forms.programCard.rechargeable'), type: 'boolean' },
      { name: 'quantity', label: t('forms.programCard.quantity') },
      {
        name: 'type',
        label: t('forms.programCard.type.label'),
        radio: {
          options: [
            { label: t('forms.programCard.type.options.openLoop'), value: 'openLoop' },
            { label: t('forms.programCard.type.options.clo'), value: 'clo' },
            { label: t('forms.programCard.type.options.privateLabel'), value: 'privateLabel' },
          ],
        },
      },
      {
        name: 'programCategory',
        label: t('forms.programCard.programCategory.label'),
        radio: {
          options: [
            { label: t('forms.programCard.programCategory.options.prepaid'), value: 'prepaid' },
            { label: t('forms.programCard.programCategory.options.debit'), value: 'debit' },
            { label: t('forms.programCard.programCategory.options.gift'), value: 'gift' },
          ],
        },
      },
      { name: 'nfc', label: t('forms.programCard.nfc'), type: 'boolean' },
      {
        name: 'dynamicalCardOptionManagement',
        label: t('forms.programCard.dynamicalCardOptionManagement'),
        type: 'boolean',
      },
      {
        name: 'programValidity',
        label: t('forms.programCard.programValidity.label'),
        radio: {
          options: [
            { label: t('forms.programCard.programValidity.options.1year'), value: '1year' },
            { label: t('forms.programCard.programValidity.options.2year'), value: '2year' },
            { label: t('forms.programCard.programValidity.options.3year'), value: '3year' },
          ],
        },
      },
      { name: 'secure3D', label: t('forms.programCard.secure3D'), type: 'boolean' },
      { name: 'smartcard', label: t('forms.programCard.smartcard'), type: 'boolean' },
      { name: 'currencies', label: t('forms.programCard.currencies'), list: true },
      {
        name: 'mobileContactlessProviders',
        label: t('forms.programCard.mobileContactlessProviders.label'),
        select: {
          multiple: true,
          options: [
            { label: t('forms.programCard.mobileContactlessProviders.options.applePay'), value: 'applePay' },
            { label: t('forms.programCard.mobileContactlessProviders.options.androidPay'), value: 'androidPay' },
            { label: t('forms.programCard.mobileContactlessProviders.options.samsungPay'), value: 'samsungPay' },
          ],
        },
      },
      { name: 'otherNeeds', label: t('forms.programCard.otherNeeds') },
    ],
  },
  {
    field: 'bankCheck',
    inputs: [{ name: 'checkCashingOrigin', label: t('forms.bankCheck.checkCashingOrigin.label'), list: true }],
  },
];

export const getFluxQualificationRule = (t: TFunction, year: number): IQualificationRule => ({
  inputs: [
    {
      type: 'label',
      name: '',
      label: t(`forms.flux.years.yearN${year}`),
    },
    {
      name: `flux[${year}].payins.cardAmount`,
      label: t('forms.flux.amounts.cardAmount'),
      type: 'number',
    },
    {
      name: `flux[${year}].payins.transactionsCardByMonthByCustomer`,
      label: t('forms.flux.amounts.transactionsCardByMonthByCustomer'),
      type: 'number',
    },
    {
      name: `flux[${year}].payins.transfertsByMonthByCustomer`,
      label: t('forms.flux.amounts.transfertsByMonthByCustomer'),
      type: 'number',
    },
    {
      name: `flux[${year}].payins.transfertAmount`,
      label: t('forms.flux.amounts.transfertAmount'),
      type: 'number',
    },
    {
      name: `flux[${year}].payins.debitsByMonthByCustomer`,
      label: t('forms.flux.amounts.debitsByMonthByCustomer'),
      type: 'number',
    },
    {
      name: `flux[${year}].payins.debitAmount`,
      label: t('forms.flux.amounts.debitAmount'),
      type: 'number',
    },
    {
      name: `flux[${year}].payins.totalPayinByMonthByCustomer`,
      label: t('forms.flux.amounts.totalPayinByMonthByCustomer'),
      type: 'number',
    },

    {
      name: `flux[${year}].payouts.cardAmount`,
      label: t('forms.flux.amounts.cardAmount'),
      type: 'number',
    },
    {
      name: `flux[${year}].payouts.transactionsCardByMonthByCustomer`,
      label: t('forms.flux.amounts.transactionsCardByMonthByCustomer'),
      type: 'number',
    },
    {
      name: `flux[${year}].payouts.transfertsByMonthByCustomer`,
      label: t('forms.flux.amounts.transfertsByMonthByCustomer'),
      type: 'number',
    },
    {
      name: `flux[${year}].payouts.transfertAmount`,
      label: t('forms.flux.amounts.transfertAmount'),
      type: 'number',
    },
    {
      name: `flux[${year}].payouts.debitsByMonthByCustomer`,
      label: t('forms.flux.amounts.debitsByMonthByCustomer'),
      type: 'number',
    },
    {
      name: `flux[${year}].payouts.debitAmount`,
      label: t('forms.flux.amounts.debitAmount'),
      type: 'number',
    },
    {
      name: `flux[${year}].payouts.totalPayinByMonthByCustomer`,
      label: t('forms.flux.amounts.totalPayinByMonthByCustomer'),
      type: 'number',
    },

    {
      name: `flux[${year}].customersEstimation`,
      label: t('forms.flux.customersEstimation'),
      type: 'number',
    },
    {
      name: `flux[${year}].totalFluxByMonthByCustomer`,
      label: t('forms.flux.totalFluxByMonthByCustomer'),
      type: 'number',
    },
  ],
});
