import { IOnboardingSupportUserRequest, IOnboardingSupportUser, IOrganization } from '@models/user.model';
import { AppState, appStore } from '@store/configuration';
import { AuthError } from '@tools/AppError';
import { Config } from '../../config';

const OrganizationService = {
  findMyOrganization: async (organizationId: string): Promise<IOrganization> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const url = `${Config.api.coreOnboarding}/organization/${organizationId}`;

    const getOrganizationResponse = await fetch(url, { headers });

    if (!getOrganizationResponse.ok) {
      throw new AuthError(getOrganizationResponse.status, JSON.stringify(getOrganizationResponse.body));
    }

    const organization = (await getOrganizationResponse.json()) as IOrganization;

    return organization;
  },
};

const SupportUserService = {
  findMyOnboardingSupportUser: async (supportUserId: string): Promise<IOnboardingSupportUser> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const url = `${Config.api.coreOnboarding}/onboarding-support-user/support-user/${supportUserId}`;

    const getOnboardingSupportUserResponse = await fetch(url, { headers });

    if (!getOnboardingSupportUserResponse.ok) {
      throw new AuthError(
        getOnboardingSupportUserResponse.status,
        JSON.stringify(getOnboardingSupportUserResponse.body),
      );
    }

    const onboardingSupportUser = (await getOnboardingSupportUserResponse.json()) as IOnboardingSupportUser;

    return onboardingSupportUser;
  },
  getOnboardingSupportUser: async (supportUserId: string): Promise<IOnboardingSupportUser> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const url = `${Config.api.coreOnboarding}/onboarding-support-user/${supportUserId}`;

    const getOnboardingSupportUserResponse = await fetch(url, { headers });

    if (!getOnboardingSupportUserResponse.ok) {
      throw new AuthError(
        getOnboardingSupportUserResponse.status,
        JSON.stringify(getOnboardingSupportUserResponse.body),
      );
    }

    const onboardingSupportUser = (await getOnboardingSupportUserResponse.json()) as IOnboardingSupportUser;

    return onboardingSupportUser;
  },
  postOnboardingSupportUser: async (
    createSupportUser: IOnboardingSupportUserRequest,
  ): Promise<IOnboardingSupportUser> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const postOnboardingSupportUserResponse = await fetch(`${Config.api.coreOnboarding}/onboarding-support-user`, {
      method: 'POST',
      headers,
      body: JSON.stringify(createSupportUser),
    });

    if (!postOnboardingSupportUserResponse.ok) {
      throw new AuthError(
        postOnboardingSupportUserResponse.status,
        JSON.stringify(postOnboardingSupportUserResponse.body),
      );
    }

    const onboardingSupportUser = (await postOnboardingSupportUserResponse.json()) as IOnboardingSupportUser;

    return onboardingSupportUser;
  },
  putOnboardingSupportUser: async (
    updateSupportUser: IOnboardingSupportUserRequest,
  ): Promise<IOnboardingSupportUser> => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const { onboardingSupportUserId, ...rest } = updateSupportUser;

    const putOnboardingSupportUserResponse = await fetch(
      `${Config.api.coreOnboarding}/onboarding-support-user/${onboardingSupportUserId}`,
      {
        method: 'PUT',
        headers,
        body: JSON.stringify(rest),
      },
    );

    if (!putOnboardingSupportUserResponse.ok) {
      throw new AuthError(
        putOnboardingSupportUserResponse.status,
        JSON.stringify(putOnboardingSupportUserResponse.body),
      );
    }

    const onboardingSupportUser = (await putOnboardingSupportUserResponse.json()) as IOnboardingSupportUser;

    return onboardingSupportUser;
  },
};

export { OrganizationService, SupportUserService };
