import check from '@assets/images/check.svg';
import DragAndDrop from '@components/DragAndDrop';
import useToggle from '@hooks/useToggle';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { IOption, IStep } from '@models/agent.model';
import _ from 'lodash';
import React, { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaEye, FaGift, FaLock } from 'react-icons/fa';
import { Label } from 'reactstrap';
import { LanguageChoice } from './LanguageChoice';
import Spinner from './ui/Spinner';

const useStyles = makeStyles({
  customTextField: {
    '& input::placeholder': {
      fontSize: '1rem',
    },
  },
});

export interface StepperComponentProps {
  onSubmit: (value: any) => void;
  steps: Record<number, IStep>;
  defaultValues?: any;
  isLoading?: boolean;
  submitLabel?: string;
  initialHistory?: number[];
  onChange?: (history: number[], value: any) => void;
}

const StepperComponent = ({
  onSubmit,
  steps,
  defaultValues,
  isLoading,
  submitLabel,
  initialHistory,
  onChange,
}: StepperComponentProps) => {
  const { t } = useTranslation();

  const [history, setHistory] = useState(initialHistory || [1]);
  const [showPassword, togglePassword] = useToggle(false);

  const currentStep = useMemo(() => {
    return history[history.length - 1];
  }, [history]);

  const classes = useStyles();
  const nameRef = useRef<HTMLInputElement>();

  const { errors, getValues, watch, control, trigger, setValue, reset } = useForm({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (defaultValues) reset(_.cloneDeep(defaultValues));
  }, [defaultValues, reset]);

  const currentValue = watch();
  useEffect(() => {
    if (Object.keys(currentValue).length > 0) {
      onChange?.(history, currentValue);
    }
  }, [history, currentValue, onChange]);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [currentStep, setValue, steps, trigger]);

  const onKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && currentStep !== Object.keys(steps).length) {
      if (nameRef.current) {
        await nameRef.current.blur();
        await nameRef.current.focus();
      }

      const nextStep = await validate();
      if (nextStep) setHistory((history) => [...history, nextStep]);
    }
  };

  const validate = async (): Promise<number | undefined> => {
    const input = steps[currentStep].input;
    await trigger(input.name);

    const hasError = _.get(errors, input.name);
    if (!hasError) {
      if (steps[currentStep].input.type === 'radio') {
        const selectedOption =
          input.options?.find((option: IOption) => option.value === getValues(input.name)) || input.options?.[0];
        if (selectedOption?.nextStep) {
          return selectedOption.nextStep;
        } else {
          return currentStep + 1;
        }
      } else {
        return steps[currentStep].input.nextStep || currentStep + 1;
      }
    }

    return undefined;
  };

  const onNextStep = async () => {
    const nextStep = await validate();
    if (nextStep) setHistory((history) => [...history, nextStep]);
  };

  const onPrevStep = () => {
    setHistory((history) => history.splice(0, history.length - 1));
  };

  const validatePassword = (repeatPassword: string): boolean => {
    return repeatPassword === getValues('password');
  };

  return (
    <div className="stepper">
      <div className="stepper-language">
        <LanguageChoice />
      </div>
      <div className="stepper-form">
        {Object.keys(steps).map((key: string) => {
          const input = steps[+key].input;
          return (
            <div className={currentStep === +key ? '' : 'hidden'} key={key}>
              <div className="stepper-title">
                <Label className="question">
                  {t(steps[+key].question)} {input.rules?.required ? ' *' : ''}
                </Label>
                <Label className="input-label">{t(input.label || '')}</Label>
              </div>

              <div key={input.name} className="stepper-input">
                {['text', 'password', 'date', 'email', 'url', 'tel', 'number'].includes(input.type) && (
                  <Controller
                    key={input.name}
                    rules={{
                      ...input.rules,
                      ...(input.type === 'password' && { validate: validatePassword }),
                    }}
                    control={control}
                    name={input.name}
                    render={({ value, onChange }) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        inputRef={(e: HTMLInputElement) => {
                          if (currentStep === +key) nameRef.current = e;
                        }}
                        name={input.name}
                        {...(input.autoComplete && { autoComplete: input.autoComplete })}
                        fullWidth={true}
                        value={value}
                        defaultValue={value}
                        onChange={onChange}
                        size="medium"
                        className="response"
                        type={input.type === 'password' && showPassword ? 'text' : input.type}
                        placeholder={input.placeholder}
                        variant="standard"
                        inputProps={{
                          style: { color: 'white', fontSize: '1rem' },
                          accept: input.type === 'file' ? input.typeFile : '',
                        }}
                        InputLabelProps={{
                          style: _.get(errors, input.name) ? {} : { color: 'white' },
                          required: input.rules?.required as boolean,
                        }}
                        disabled={input.readOnly}
                        error={!!_.get(errors, input.name)}
                        helperText={
                          <span className="helperText">
                            {t(_.get(errors, input.name)?.message) || t(_.get(errors, input.name))}
                          </span>
                        }
                        onKeyPress={onKeyPress}
                        {...(input.type === 'date' && {
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <FaGift />
                              </InputAdornment>
                            ),
                          },
                        })}
                        {...(input.type === 'password' && {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end" onClick={togglePassword} className="showPassword">
                                {showPassword ? <FaLock /> : <FaEye />}
                              </InputAdornment>
                            ),
                          },
                        })}
                      />
                    )}
                  />
                )}

                {input.type === 'file' && (
                  <Controller
                    key={input.name}
                    rules={input.rules}
                    control={control}
                    name={input.name}
                    render={({ value, onChange }) => (
                      <>
                        {value?.name}
                        <DragAndDrop
                          handleDrop={(file) => {
                            onChange(file);
                          }}
                          accept=".pdf"
                        />
                      </>
                    )}
                  />
                )}

                {input.type === 'radio' && (
                  <>
                    <Controller
                      rules={input.rules}
                      control={control}
                      name={input.name}
                      defaultValue={input.defaultValue}
                      onKeyPress={onKeyPress}
                      render={({ value, onChange }) => (
                        <RadioGroup>
                          {input.options?.map((option: IOption, index: number) => (
                            <FormControlLabel
                              key={index}
                              checked={option.value === value}
                              control={<Radio onChange={(_, checked) => checked && onChange(option.value)} />}
                              label={t(option.label)}
                              ref={(e: HTMLInputElement) => {
                                if (currentStep === +key && index === 0) nameRef.current = e;
                              }}
                            />
                          ))}
                        </RadioGroup>
                      )}
                      invalid={_.get(errors, input.name)}
                    />
                    {_.get(errors, input.name) && <FormHelperText error>Please fill in this field</FormHelperText>}
                  </>
                )}

                {input.type === 'select' && (
                  <Controller
                    key={input.name}
                    name={input.name}
                    rules={input.rules}
                    control={control}
                    defaultValue={input.defaultValue}
                    render={({ value, onChange }) => (
                      <FormControl
                        variant="outlined"
                        style={{
                          width: '80%',
                          margin: 10,
                          color: 'white',
                        }}>
                        <InputLabel id={`${input.name}-select`}>{t(input.label || '')}</InputLabel>
                        <Select
                          labelId={`${input.name}-select`}
                          label={t(input.label || '')}
                          multiple={true}
                          value={getValues(input.name) || []}
                          name={input.name}
                          variant="outlined"
                          onChange={onChange}
                          renderValue={(value: any) =>
                            value
                              .map((val: string) =>
                                input.options ? t(input.options.find((opt) => opt.value === val)?.label || '') : t(val),
                              )
                              .join(', ')
                          }>
                          {input.options?.map((option) => (
                            <MenuItem
                              key={`${option.value}`}
                              value={option.value as string}
                              style={{
                                color: '#285eea',
                              }}>
                              <ListItemIcon>
                                <Checkbox color="primary" checked={value?.indexOf(option.value) > -1} />
                              </ListItemIcon>
                              <ListItemText primary={t(option.label)} />
                            </MenuItem>
                          ))}
                        </Select>
                        {_.get(errors, input.name) && (
                          <FormHelperText error>{t(_.get(errors, input.name)?.message)}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                )}
              </div>
            </div>
          );
        })}

        {currentStep === Object.keys(steps).length && (
          <div className="button-container">
            <Button className="previous" onClick={onPrevStep}>
              {t('preprospect.previous')}
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={() => onSubmit(getValues())}
              className="no-siren-link submit">
              {submitLabel}
              {isLoading && <Spinner size={20} color="inherit" style={{ marginLeft: 20 }} />}
            </Button>
          </div>
        )}

        {currentStep !== Object.keys(steps).length && (
          <div className="button-container">
            <Button disabled={currentStep === 1} className="previous" onClick={onPrevStep}>
              {t('preprospect.previous')}
            </Button>
            <Button variant="contained" color="default" onClick={onNextStep} className="no-siren-link ok">
              OK <img className="check-image" src={check} alt="" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default StepperComponent;
