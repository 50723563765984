/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileUpload } from 'react-icons/fa';
import Draggable from './Draggable';

interface DragAndDropProps {
  accept?: string;
  handleDrop: (file: File) => void;
}

const DragAndDrop: FunctionComponent<DragAndDropProps> = ({
  handleDrop,
  accept = '.pdf, .pptx, .xlsx, .xls, .doc, .docx, .png, .jpeg, .jpg',
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className="drag-container"
      style={{ marginTop: 30, marginBottom: 30 }}>
      <Grid container direction="column" justify="center" alignItems="center" style={{ marginBottom: 20 }}>
        <Typography variant="h6">{t('components.dragAndDrop.title')}</Typography>
        <Typography variant="subtitle2">{`${t('components.dragAndDrop.acceptedFormat', {
          acceptedFormat: accept,
        })}`}</Typography>
      </Grid>
      <Draggable
        container
        direction="column"
        justify="center"
        alignItems="center"
        accept={accept}
        onFileDrop={handleDrop}
        className="drag-canvas">
        <FaFileUpload size={70} style={{ marginBottom: 20, color: '#1d4f80' }} />
        <Typography style={{ color: '#1d4f80' }} variant="subtitle2">
          {t('components.dragAndDrop.drag')}
        </Typography>
      </Draggable>
    </Grid>
  );
};

export default DragAndDrop;
