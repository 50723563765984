import Step from '@components/Step';
import { ButtonPopover } from '@components/ui/ButtonPopover';
import Spinner from '@components/ui/Spinner';
import { IAgentStep } from '@models/agent.model';
import { fetchAgent, fetchAgentByCurrentUser, toggleStep } from '@store/agent/agent.action';
import { AWSService } from '@store/agent/agent.service';
import { AppState } from '@store/configuration';
import { changeSideTab } from '@store/core/core.action';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { extension } from 'mime-types';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload, FaEnvelope, FaTasks } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';
import { Config } from '../config';

interface DocumentsProps {}

const Documents: FunctionComponent<DocumentsProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);
  const fetchStatus = useSelector((state: AppState) => state.agent.status.fetch);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  useEffect(() => {
    if (currentUser?.type === 'support-user') return;

    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.has('agentId')) dispatch(fetchAgent(searchParams.get('agentId') as string));
    else if (currentAgent?.agentId) dispatch(fetchAgent(currentAgent?.agentId));
    else dispatch(fetchAgentByCurrentUser());
  }, []);

  useEffect(() => {
    if (!history.location.state) return;

    const stepNameToExpand = (history.location.state as any).step;
    const documentNameToScroll = (history.location.state as any).document;

    if (!stepNameToExpand || !documentNameToScroll) return;

    setTimeout(() => {
      dispatch(toggleStep(stepNameToExpand, true));
      dispatch(changeSideTab('/documents'));
    }, 1000);
  }, [history.location.state, dispatch]);

  const downloadZIP = async () => {
    const zipper = JSZip.default();
    const agentSteps = currentAgent?.steps?.filter(
      (step) => step.process && processDisplayable.includes(step.process) && !step.disabled,
    );

    if (!agentSteps) return;

    const agentFiles = await Promise.all(
      agentSteps.map(async (step) => ({
        step: step.label?.replace('/', '-'),
        files: await getStepPresignedFiles(step),
      })),
    );

    if (!agentFiles) return;

    await Promise.all(
      agentFiles.map(async (stepFiles) => {
        const stepFolder = zipper.folder(stepFiles.step as string);

        if (!stepFiles.files?.length) return;

        await Promise.all(
          stepFiles.files.map(async (file) => {
            const fileResponse = await fetch(file.url);

            if (!fileResponse.ok) return;

            const fileBlob = await fileResponse.blob();
            stepFolder?.file(`${file.name}.${extension(fileBlob.type)}`, fileBlob, { binary: true });
          }),
        );
      }),
    );

    const zip = await zipper.generateAsync({ type: 'blob' });

    const zipName = '[Support]_Dossier Agent_FR_[Nom Agent]_[JJMMAAAA]'
      .replace('[Support]', Config.support.name)
      .replace('[Nom Agent]', currentAgent?.company?.profile?.name as string)
      .replace('[JJMMAAAA]', moment().format('DDMMYYYY'));

    saveAs(zip, zipName);
  };

  const sendZIP = async () => {
    const email = {
      to: currentAgent?.legalRepresentant?.email,
      subject: `${Config.support.name} - Dossier Agent ${
        currentAgent?.company?.profile?.name as string
      } ${moment().format('L')}`,
      body: `Bonjour,

Veuillez trouver ci joint le dossier d’agent pour la société ${currentAgent?.company?.profile?.name as string}.

Nous restons à votre disposition pour toute information complémentaire.

Cordialement,
${Config.support.name}`,
    };

    window.location.href = `mailto:${email.to}?subject=${encodeURIComponent(email.subject)}&body=${encodeURIComponent(
      email.body,
    )}`;
  };

  const getStepPresignedFiles = async (step: IAgentStep) => {
    if (step.documents) {
      const documentsWithPath =
        currentUser?.type === 'support-user'
          ? step.documents.filter((document) => document.permission?.download)
          : step.documents.filter((document) => document.permission?.download && document.visible);

      if (documentsWithPath.length) {
        return await Promise.all(
          documentsWithPath.map(async (document) => {
            let url;

            if (!document.path && document.default) {
              url = await AWSService.getPresignedFile(
                'get',
                Config.bucket.templatesFolder as string,
                document.default as string,
              );
            } else if (!document.path && document.template) {
              url = await AWSService.getPresignedFile(
                'get',
                Config.bucket.templatesFolder,
                document.template as string,
              );
            } else {
              url = await AWSService.getPresignedFile('get', `${currentAgent?.agentId}`, document.documentId);
            }

            return {
              url,
              name: document.fullName,
            };
          }),
        );
      }

      return Promise.resolve([]);
    }
  };

  const changeProcess = () => {
    history.push('/change-process');
  };

  const processDisplayable = useMemo<string[]>(
    () =>
      currentAgent?.availableProcess?.slice(
        0,
        currentAgent?.availableProcess?.findIndex((process) => process === currentAgent.currentProcess) + 1,
      ) || [],
    [currentAgent],
  );

  return (
    <Container className="documents-page">
      {currentAgent && (
        <>
          <div className="documents-actions">
            <ButtonPopover
              Icon={FaDownload}
              onClick={downloadZIP}
              description={t('pages.documents.actions.zipAllProcess')}
            />

            {currentUser?.type === 'support-user' && (
              <>
                <ButtonPopover
                  Icon={FaEnvelope}
                  onClick={sendZIP}
                  description={t('pages.documents.actions.prepareRegulatorEmail')}
                />
                <ButtonPopover
                  Icon={FaTasks}
                  onClick={changeProcess}
                  description={t('pages.documents.actions.updateProcess')}
                />
              </>
            )}
          </div>

          {currentAgent?.steps &&
            currentAgent?.steps
              .filter(
                (step) =>
                  step.process &&
                  processDisplayable.includes(step.process) &&
                  !step.disabled &&
                  !!step.documents?.length,
              )
              .map((step, index) => <Step key={`step-${index}`} step={step} rank={index + 1} />)}
        </>
      )}

      {fetchStatus.inProgress && <Spinner center />}
    </Container>
  );
};

export default Documents;
