import { IOption, IStep } from '@models/agent.model';
import { ValidationUtils } from '@tools/validation';

const location: IOption[] = [
  { label: 'preprospect.location.france', value: 'france' },
  { label: 'preprospect.location.belgium', value: 'belgium' },
  { label: 'preprospect.location.germany', value: 'germany' },
  { label: 'preprospect.location.italy', value: 'italy' },
  { label: 'preprospect.location.spain', value: 'spain' },
  { label: 'preprospect.location.uk', value: 'uk' },
  { label: 'preprospect.location.portugal', value: 'portugal' },
  { label: 'preprospect.location.netherlands', value: 'netherlands' },
  { label: 'preprospect.location.luxembourg', value: 'luxembourg' },
  { label: 'preprospect.location.otherEuCountry', value: 'otherEuCountry' },
  { label: 'preprospect.location.switzerland', value: 'switzerland' },
  { label: 'preprospect.location.africa', value: 'africa' },
  { label: 'preprospect.location.northAmerica', value: 'northAmerica' },
  { label: 'preprospect.location.southAmerica', value: 'southAmerica' },
  { label: 'preprospect.location.asiaPacific', value: 'asiaPacific' },
  { label: 'preprospect.location.other', value: 'other' },
];

export const onBoardingSteps: Record<number, IStep> = {
  1: {
    question: 'preprospect.form.firstname.question',
    input: {
      type: 'text',
      name: 'legalRepresentant.firstname',
      label: 'preprospect.form.firstname.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      autoComplete: 'given-name',
    },
  },
  2: {
    question: 'preprospect.form.lastname.question',
    input: {
      type: 'text',
      name: 'legalRepresentant.lastname',
      label: 'preprospect.form.lastname.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      autoComplete: 'family-name',
    },
  },
  3: {
    question: 'preprospect.form.email.question',
    input: {
      type: 'email',
      name: 'legalRepresentant.email',
      rules: {
        required: { value: true, message: 'preprospect.errorMessage.required' },
        validate: ValidationUtils.isEmail || 'preprospect.errorMessage.email',
      },
      readOnly: true,
      autoComplete: 'email',
    },
  },
  4: {
    question: 'preprospect.form.legalRepresentantPhone.question',
    input: {
      type: 'tel',
      name: 'legalRepresentant.phone',
      rules: {
        required: { value: true, message: 'preprospect.errorMessage.required' },
        validate: { value: (val) => ValidationUtils.isPhoneNumber(val) || 'preprospect.errorMessage.tel' },
      },
      placeholder: '06 12 34 56 78',
      autoComplete: 'tel-country-code',
    },
  },
  5: {
    question: 'preprospect.form.legalRepresentantLinkedIn.question',
    input: {
      type: 'url',
      name: 'legalRepresentant.linkedIn',
      label: 'preprospect.form.legalRepresentantLinkedIn.label',
      rules: {
        required: { value: true, message: 'preprospect.errorMessage.required' },
        validate: { value: (val) => ValidationUtils.isURL(val) || 'preprospect.errorMessage.url' },
      },
    },
  },
  6: {
    question: 'preprospect.form.companyProfileName.question',
    input: {
      type: 'text',
      name: 'company.profile.name',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      autoComplete: 'organization',
    },
  },
  7: {
    question: 'preprospect.form.role.question',
    input: {
      type: 'text',
      name: 'legalRepresentant.role',
      label: 'preprospect.form.role.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      autoComplete: 'organization-title',
    },
  },
  8: {
    question: 'preprospect.form.companyIsRegistered.question',
    input: {
      type: 'radio',
      name: 'company.isRegistered',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: 'yes',
      options: [
        { label: 'forms.core.yes', value: 'yes' },
        { label: 'forms.core.no', value: 'false', nextStep: 15 },
      ],
    },
  },
  9: {
    question: 'preprospect.form.companyWebsite.question',
    input: {
      type: 'url',
      name: 'company.website',
      label: 'preprospect.form.companyWebsite.label',
      rules: { validate: { value: (val) => ValidationUtils.isURL(val) || 'preprospect.errorMessage.url' } },
    },
  },
  10: {
    question: 'preprospect.form.companyIsPartOfGroup.question',
    input: {
      type: 'radio',
      name: 'company.isPartOfGroup',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: 'yes',
      options: [
        { label: 'forms.core.yes', value: 'yes' },
        { label: 'forms.core.no', value: 'no', nextStep: 12 },
      ],
    },
  },
  11: {
    question: 'preprospect.form.companyGroup.question',
    input: {
      type: 'url',
      name: 'company.group',
      label: 'preprospect.form.companyGroup.label',
      rules: {
        required: { value: true, message: 'preprospect.errorMessage.required' },
        validate: { value: (val) => ValidationUtils.isURL(val) || 'preprospect.errorMessage.url' },
      },
    },
  },
  12: {
    question: 'preprospect.form.companyTeamSize.question',
    input: {
      type: 'radio',
      name: 'company.teamSize',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: '1',
      options: [
        { label: 'preprospect.form.companyTeamSize.options.alone', value: 'alone' },
        { label: 'preprospect.form.companyTeamSize.options.cofounders', value: 'cofounders' },
        { label: 'preprospect.form.companyTeamSize.options.10-20', value: '10-20' },
        { label: 'preprospect.form.companyTeamSize.options.20-50', value: '20-50' },
        { label: 'preprospect.form.companyTeamSize.options.50+', value: '50+' },
      ],
    },
  },
  13: {
    question: 'preprospect.form.companyCustomerBaseSize.question',
    input: {
      type: 'text',
      name: 'company.customerBaseSize',
      label: 'preprospect.form.companyCustomerBaseSize.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
    },
  },
  14: {
    question: 'preprospect.form.companyServicesProvider.question',
    input: {
      type: 'select',
      name: 'company.servicesProvider',
      label: 'preprospect.form.companyServicesProvider.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: [],
      options: location,
      nextStep: 16,
    },
  },
  15: {
    question: 'preprospect.form.companyPitchDeck.question',
    input: {
      type: 'file',
      typeFile: 'application/pdf',
      name: `company.pitchDeck`,
      label: 'preprospect.form.companyPitchDeck.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
    },
  },
  16: {
    question: 'preprospect.form.companyProjectType.question',
    input: {
      type: 'radio',
      name: 'company.projectTypeMenu',
      label: 'preprospect.form.companyProjectType.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: 'neobank',
      options: [
        { label: 'preprospect.form.companyProjectType.options.neobank.label', value: 'neobank', nextStep: 17 },
        { label: 'preprospect.form.companyProjectType.options.b2bPayments.label', value: 'b2bPayments', nextStep: 18 },
        {
          label: 'preprospect.form.companyProjectType.options.employeeBenefits.label',
          value: 'employeeBenefits',
          nextStep: 19,
        },
        { label: 'preprospect.form.companyProjectType.options.others.label', value: 'others', nextStep: 20 },
      ],
    },
  },
  17: {
    question: 'preprospect.form.companyProjectType.question',
    input: {
      type: 'radio',
      name: 'company.projectType',
      label: 'preprospect.form.companyProjectType.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: 'neobank.b2c',
      options: [
        { label: 'preprospect.form.companyProjectType.options.neobank.b2c', value: 'neobank.b2c', nextStep: 21 },
        { label: 'preprospect.form.companyProjectType.options.neobank.b2b', value: 'neobank.b2b', nextStep: 21 },
        { label: 'preprospect.form.companyProjectType.options.neobank.family', value: 'neobank.family', nextStep: 21 },
      ],
    },
  },
  18: {
    question: 'preprospect.form.companyProjectType.question',
    input: {
      type: 'radio',
      name: 'company.projectType',
      label: 'preprospect.form.companyProjectType.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: 'b2bPayments.bill',
      options: [
        {
          label: 'preprospect.form.companyProjectType.options.b2bPayments.bill',
          value: 'b2bPayments.bill',
          nextStep: 21,
        },
        {
          label: 'preprospect.form.companyProjectType.options.b2bPayments.expense',
          value: 'b2bPayments.expense',
          nextStep: 21,
        },
      ],
    },
  },
  19: {
    question: 'preprospect.form.companyProjectType.question',
    input: {
      type: 'radio',
      name: 'company.projectType',
      label: 'preprospect.form.companyProjectType.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: 'employeeBenefits.mealCards',
      options: [
        {
          label: 'preprospect.form.companyProjectType.options.employeeBenefits.mealCards',
          value: 'employeeBenefits.mealCards',
          nextStep: 21,
        },
        {
          label: 'preprospect.form.companyProjectType.options.employeeBenefits.giftCards',
          value: 'employeeBenefits.giftCards',
          nextStep: 21,
        },
        {
          label: 'preprospect.form.companyProjectType.options.employeeBenefits.mobilityCards',
          value: 'employeeBenefits.mobilityCards',
          nextStep: 21,
        },
        {
          label: 'preprospect.form.companyProjectType.options.employeeBenefits.salaryAdvance',
          value: 'employeeBenefits.salaryAdvance',
          nextStep: 21,
        },
        {
          label: 'preprospect.form.companyProjectType.options.employeeBenefits.other',
          value: 'employeeBenefits.other',
          nextStep: 21,
        },
      ],
    },
  },
  20: {
    question: 'preprospect.form.companyProjectType.question',
    input: {
      type: 'radio',
      name: 'company.projectType',
      label: 'preprospect.form.companyProjectType.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: 'others.proptech',
      options: [
        {
          label: 'preprospect.form.companyProjectType.options.others.proptech',
          value: 'others.proptech',
          nextStep: 21,
        },
        { label: 'preprospect.form.companyProjectType.options.others.savings', value: 'others.savings', nextStep: 21 },
        {
          label: 'preprospect.form.companyProjectType.options.others.insurance',
          value: 'others.insurance',
          nextStep: 21,
        },
        {
          label: 'preprospect.form.companyProjectType.options.others.marketplace',
          value: 'others.marketplace',
          nextStep: 21,
        },
        { label: 'preprospect.form.companyProjectType.options.others.p2p', value: 'others.p2p', nextStep: 21 },
        { label: 'preprospect.form.companyProjectType.options.others.crypto', value: 'others.crypto', nextStep: 21 },
      ],
    },
  },
  21: {
    question: 'preprospect.form.companyValueProposition.question',
    input: {
      type: 'text',
      name: `company.valueProposition`,
      label: 'preprospect.form.companyValueProposition.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
    },
  },
  22: {
    question: 'preprospect.form.companyClientLocation.question',
    input: {
      type: 'select',
      name: 'company.clientLocation',
      label: 'preprospect.form.companyClientLocation.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: [],
      options: location,
    },
  },
  23: {
    question: 'preprospect.form.companyProjectFunding.question',
    input: {
      type: 'radio',
      name: 'company.projectFunding',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: 'ownFunds',
      options: [
        { label: 'preprospect.form.companyProjectFunding.options.ownFunds', value: 'ownFunds' },
        { label: 'preprospect.form.companyProjectFunding.options.preSeed', value: 'preSeed' },
        { label: 'preprospect.form.companyProjectFunding.options.seed', value: 'seed' },
        { label: 'preprospect.form.companyProjectFunding.options.seriesA', value: 'seriesA' },
        { label: 'preprospect.form.companyProjectFunding.options.beyondSeriesA', value: 'beyondSeriesA' },
        { label: 'preprospect.form.companyProjectFunding.options.other', value: 'other' },
      ],
    },
  },
  24: {
    question: 'preprospect.form.companyCoworker.question',
    input: {
      type: 'select',
      name: 'company.coworker',
      label: 'preprospect.form.companyCoworker.label',
      rules: { required: { value: true, message: 'preprospect.errorMessage.required' } },
      defaultValue: [],
      options: [
        { label: 'preprospect.form.companyCoworker.options.cto', value: 'cto' },
        { label: 'preprospect.form.companyCoworker.options.cfo', value: 'cfo' },
        { label: 'preprospect.form.companyCoworker.options.internalDevelopers', value: 'internalDevelopers' },
        { label: 'preprospect.form.companyCoworker.options.externalDevelopers', value: 'externalDevelopers' },
        { label: 'preprospect.form.companyCoworker.options.internalProjectManager', value: 'internalProjectManager' },
        { label: 'preprospect.form.companyCoworker.options.externalProjectManager', value: 'externalProjectManager' },
        { label: 'preprospect.form.companyCoworker.options.other', value: 'other' },
      ],
    },
  },
  25: {
    question: 'preprospect.form.password.question',
    input: {
      type: 'password',
      name: 'password',
      label: 'preprospect.form.password.label',
      rules: {
        required: { value: true, message: 'preprospect.errorMessage.required' },
        minLength: { value: 8, message: 'preprospect.errorMessage.passwordLength' },
      },
    },
  },
  26: {
    question: 'preprospect.form.confirmPassword.question',
    input: {
      type: 'password',
      name: 'confirmPassword',
      label: 'preprospect.form.password.label',
      rules: {
        required: { value: true, message: 'preprospect.errorMessage.required' },
        minLength: { value: 8, message: 'preprospect.errorMessage.passwordLength' },
      },
    },
  },
};
