import { withPageConfig } from '@components/layout/withPageConfig';
import { useEffect } from 'react';
import { IPageConfigContext } from '@components/layout/PageConfigContext';

interface IEmptyLayoutProps {
  pageConfig: IPageConfigContext;
  children: any;
}

const EmptyLayout = (props: IEmptyLayoutProps) => {
  useEffect(() => {
    props.pageConfig.setNavBarVisibility(false);
    props.pageConfig.setSideBarVisibility(false);

    return () => {
      props.pageConfig.setNavBarVisibility(true);
      props.pageConfig.setSideBarVisibility(true);
    };
  }, [props.pageConfig]);

  return props.children;
};

const PageConfigEmptyLayout = withPageConfig(EmptyLayout);

export { PageConfigEmptyLayout as EmptyLayout };
