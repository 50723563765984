import { IAgent } from '@models/agent.model';
import {
  IAuthResponse,
  IBusinessInformationsResponse,
  ILoginRequest,
  IConfirmationProspect,
  ISIRETResponse,
  IChangePassword,
} from '@models/auth.model';
import { AppState, appStore } from '@store/configuration';
import { AuthError } from '@tools/AppError';
import { Config } from '../../config';

const AuthService = {
  get: {
    withPasswordGrant: async (request: ILoginRequest): Promise<IAuthResponse | never> => {
      try {
        const form = new FormData();
        form.append('grant_type', 'password');
        form.append('username', request.email);
        form.append('scope', 'read_only read_write admin legal keys');
        form.append('password', request.password);
        const authResponse = await fetch(`${Config.oauth.base}/token`, {
          method: 'POST',
          body: form,
        });
        if (!authResponse.ok) {
          return Promise.reject(authResponse);
        }

        const auth = (await authResponse.json()) as IAuthResponse;

        return auth;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    withClientCredentials: async (): Promise<IAuthResponse> => {
      const form = new FormData();
      form.append('grant_type', 'client_credentials');
      form.append('client_id', Config.oauth.dashboardClientId);
      form.append('scope', 'read_only read_write admin legal keys');
      form.append('client_secret', '<CLIENT SECRET MISSING>');

      const authResponse = await fetch(`${Config.oauth.base}/token`, {
        method: 'POST',
        body: form,
      });

      if (!authResponse.ok) {
        throw new AuthError(authResponse.status, JSON.stringify(authResponse.body));
      }

      const auth = (await authResponse.json()) as IAuthResponse;

      return auth;
    },
    withAuthorizationCode: async (
      authorizationCode: string,
      withRedirectURI: boolean,
      isSupport: boolean,
    ): Promise<IAuthResponse> => {
      try {
        const form = new FormData();
        form.append('grant_type', 'authorization_code');
        form.append('client_id', isSupport ? Config.oauth.dashboardClientId : Config.oauth.clientId);
        form.append('code', decodeURIComponent(authorizationCode));
        if (withRedirectURI) {
          form.append('redirect_uri', Config.oauth.redirectURI);
        }

        const authResponse = await fetch(`${Config.oauth.base}/token`, {
          method: 'POST',
          body: form,
        });

        if (!authResponse.ok) {
          return Promise.reject(authResponse);
        }

        const auth = (await authResponse.json()) as IAuthResponse;

        return auth;
      } catch (err) {
        return Promise.reject(err);
      }
    },
  },
};

const OnboardService = {
  onboard: async (email: string, organizationId: string, supportUserId: string) => {
    try {
      const state: AppState = appStore.getState();

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${state.auth.accessToken}`,
      };

      const input: RequestInfo = `${Config.api.coreOnboarding}/onboard/users`;
      const init: RequestInit = {
        method: 'POST',
        headers,
        body: JSON.stringify({ email, password: 'TrzOnboarding2020', organizationId, hostId: supportUserId }),
      };
      const onboardResponse = await fetch(input, init);

      if (!onboardResponse.ok) {
        return Promise.reject(onboardResponse);
        // UFK : a rediscuter
        // new AuthError(onboardResponse.status, JSON.stringify(onboardResponse.body))
        // return false;
      }
      return true;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  confirm: async (prospect: IConfirmationProspect) => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const confirmResponse = await fetch(`${Config.api.coreOnboarding}/onboard/users/${prospect.userId}/confirmation`, {
      method: 'POST',
      headers,
      body: JSON.stringify(prospect),
    });

    if (!confirmResponse.ok) {
      new AuthError(confirmResponse.status, JSON.stringify(confirmResponse.body));
      return false;
    }

    return true;
  },
  changePassword: async ({ userId, password }: IChangePassword) => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const changePasswordResponse = await fetch(`${Config.api.coreOnboarding}/onboard/users/${userId}/change-password`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ password }),
    });

    if (!changePasswordResponse.ok) {
      new AuthError(changePasswordResponse.status, JSON.stringify(changePasswordResponse.body));
      return false;
    }

    return true;
  },
  finalize: async (newAgent: IAgent) => {
    const state: AppState = appStore.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.auth.accessToken}`,
    };

    const finalizeResponse = await fetch(
      `${Config.api.coreOnboarding}/onboard/users/${state.user.currentUser?.id}/finalyse`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(newAgent),
      },
    );

    if (!finalizeResponse.ok) {
      throw new AuthError(finalizeResponse.status, JSON.stringify(finalizeResponse.body));
    }

    const finalize = await finalizeResponse.json();

    return finalize;
  },
  searchCompanyBySiret: async (siret: number): Promise<IBusinessInformationsResponse> => {
    try {
      const state: AppState = appStore.getState();

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${state.auth.accessToken}`,
      };

      const url: RequestInfo = `${Config.api.base}/v1/businessinformations?country=FR&registrationNumber=${siret}`;
      const init: RequestInit = { headers };
      const cpndata = await fetch(url, init);
      if (!cpndata.ok) {
        return Promise.reject(cpndata);
      }
      const auth = (await cpndata.json()) as ISIRETResponse;

      if (!auth.businessinformations.length) return Promise.reject('BusinessInformations not fount');

      return auth.businessinformations[0];
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export { AuthService, OnboardService };
