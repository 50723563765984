import { PageConfigContext } from '@components/layout/PageConfigContext';
import React from 'react';

export const withPageConfig = (Component) => {
  const WithPageConfig = (props) => (
    <PageConfigContext.Consumer>
      {(pageConfig) => <Component pageConfig={pageConfig} {...props} />}
    </PageConfigContext.Consumer>
  );
  return WithPageConfig;
};
