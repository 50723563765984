import { AppBar, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import Documents from '@pages/Documents';
import Overview from '@pages/Overview';
import Profile from '@pages/Profile';
import Qualification from '@pages/Qualification';
import { fetchAgent, fetchAgentByCurrentUser } from '@store/agent/agent.action';
import { AppState } from '@store/configuration';
import { Colors } from '@tools/constants';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FaCertificate, FaFolder, FaHome, FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Spinner from '@components/ui/Spinner';
import { Config } from '../config';
import PreProspectOverview from './PreProspectOverview';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    '&>.MuiTabs-root': {
      backgroundColor: '#f2f6f9',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparant !important',
    },
  },
  tab: {
    display: 'flex',
    '&:hover': {
      color: Colors.treezor.blue,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: Colors.treezor.blue,
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: Colors.treezor.blue,
      outline: 'none',
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}>
    {value === index && children}
  </div>
);

const Agent = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);
  const fetchStatus = useSelector((state: AppState) => state.agent.status.fetch);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.has('agentId')) dispatch(fetchAgent(searchParams.get('agentId') as string));
    else if (currentAgent?.agentId) dispatch(fetchAgent(currentAgent?.agentId));
    else dispatch(fetchAgentByCurrentUser());
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  if (fetchStatus.inProgress) return <Spinner center />;

  return currentAgent?.status === 'PRE_PROSPECT' ? (
    <PreProspectOverview></PreProspectOverview>
  ) : (
    <AppBar position="static" className={classes.root}>
      <Tabs
        value={activeTab}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        centered>
        <Tab icon={<FaHome />} label={t('pages.agent.tab.home')} className={classes.tab} />
        <Tab icon={<FaFolder />} label={t('pages.agent.tab.documents')} className={classes.tab} />
        <Tab icon={<FaUser />} label={t('pages.agent.tab.profil')} className={classes.tab} />
        {Config.app.useQualification && <Tab icon={<FaCertificate />} label={t('pages.agent.tab.qualification')} className={classes.tab} />}
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        <Overview setActiveTab={setActiveTab} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Documents />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <Profile />
      </TabPanel>
      {Config.app.useQualification && (
        <TabPanel value={activeTab} index={3}>
          <Qualification />
        </TabPanel>
      )}
    </AppBar>
  );
};

export default Agent;
