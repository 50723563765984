// import translationES from '@assets/locales/es.json';
import translationEN from '@assets/locales/en.json';
import translationES from '@assets/locales/es.json';
import translationFR from '@assets/locales/fr.json';
import translationIT from '@assets/locales/it.json';
import { Storage } from '@tools/localStorage';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const userLang = window.localStorage.getItem(Storage.LANGUAGE) || navigator.language[0] + navigator.language[1];

// the translations
const resources = {
  fr: {
    translation: translationFR,
  },
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // Pass i18n instance to react-i18next.
  .init({
    resources,
    lng: userLang,
    fallbackLng: 'en', // Use fr if detected lng is not available.
    debug: false,

    interpolation: {
      escapeValue: true, // React already safes from xss SO not needed for react as it escapes by default.
    },
  });

export default i18n;
