import { IAgent } from '@models/agent.model';
import { IAuthResponse, IBusinessInformationsResponse, ILoginRequest, IConfirmationProspect } from '@models/auth.model';
import { IProspectKeys } from '@models/keys.model';
import { setAgent, uploadCompanyFile } from '@store/agent/agent.action';
import { AgentActionTypes } from '@store/agent/agent.types';
import { AuthService, OnboardService } from '@store/auth/auth.service';
import { AuthAction, AuthActionTypes } from '@store/auth/auth.types';
import { AppState, appStore, persistor } from '@store/configuration';
import { CoreActionTypes } from '@store/core/core.types';
import { KeysService } from '@store/keys/keys.service';
import { KeysActionTypes } from '@store/keys/keys.types';
import { createCurrentUser } from '@store/user/user.action';
import { UserActionTypes } from '@store/user/user.types';
import { AsymmetricTreezorEncryption } from '@tools/AsymmetricTreezorEncryption';
import { SymmetricTreezorEncryption } from '@tools/SymmetricTreezorEncryption';

const login = (request: ILoginRequest) => {
  const loginRequest = (): AuthAction => ({ type: AuthActionTypes.LOGIN_REQUEST });
  const loginSuccess = (response: IAuthResponse): AuthAction => ({ type: AuthActionTypes.LOGIN_SUCESS, response });
  const loginFailed = (error: any): AuthAction => ({ type: AuthActionTypes.LOGIN_FAILURE, error });

  return async (dispatch: any) => {
    dispatch(loginRequest());
    try {
      const auth = await AuthService.get.withPasswordGrant(request);
      dispatch(loginSuccess(auth));
      dispatch(createCurrentUser(auth.access_token));
      dispatch(setAccessToken(auth.access_token));
    } catch (loginError) {
      dispatch(loginFailed(loginError));
      console.error(loginError);
    }
  };
};

const loginWithAuthorizationCode = (authorizationCode: string, withRedirectUri: boolean, isSupport: boolean) => {
  const loginRequest = (): AuthAction => ({ type: AuthActionTypes.LOGIN_REQUEST });
  const loginSuccess = (response: IAuthResponse): AuthAction => ({ type: AuthActionTypes.LOGIN_SUCESS, response });

  return async (dispatch: any) => {
    dispatch(loginRequest());

    const auth = await AuthService.get.withAuthorizationCode(authorizationCode, withRedirectUri, isSupport);
    dispatch(loginSuccess(auth));
    dispatch(createCurrentUser(auth.access_token));
    dispatch(setAccessToken(auth.access_token));
  };
};

const loginWithClientCredentials = () => {
  const loginRequest = (): AuthAction => ({ type: AuthActionTypes.LOGIN_REQUEST });
  const loginSuccess = (response: IAuthResponse): AuthAction => ({ type: AuthActionTypes.LOGIN_SUCESS, response });

  return async (dispatch: any) => {
    dispatch(loginRequest());

    const auth = await AuthService.get.withClientCredentials();
    dispatch(loginSuccess(auth));
    dispatch(createCurrentUser(auth.access_token));
    dispatch(setAccessToken(auth.access_token));
  };
};

const onboardProspect = (email: string, organizationId: string) => {
  const onboardRequest = (): AuthAction => ({ type: AuthActionTypes.ONBOARD_REQUEST });
  const onboardSuccess = (): AuthAction => ({ type: AuthActionTypes.ONBOARD_SUCESS });
  const onboardFailure = (error: any): AuthAction => ({ type: AuthActionTypes.ONBOARD_FAILURE, error });

  return async (dispatch: any) => {
    dispatch(onboardRequest());
    try {
      const supportUserId = (appStore.getState() as AppState).user.currentUser?.id;

      if (supportUserId) {
        await OnboardService.onboard(email, organizationId, supportUserId);
        dispatch(onboardSuccess());
      } else {
        dispatch(onboardFailure(new Error('Support user ID not found')));
      }
    } catch (err) {
      dispatch(onboardFailure(err));
      console.error(err);
    }
  };
};

const confirmProspect = (prospect: IConfirmationProspect) => {
  const confirmRequest = (): AuthAction => ({ type: AuthActionTypes.CONFIRM_REQUEST });
  const confirmSuccess = (): AuthAction => ({ type: AuthActionTypes.CONFIRM_SUCESS });
  const confirmFailure = (error?: any): AuthAction => ({ type: AuthActionTypes.CONFIRM_FAILURE, error });

  return async (dispatch: any) => {
    dispatch(confirmRequest());
    try {
      const state: AppState = appStore.getState();
      if (!state.auth.accessToken) {
        const authorization = await AuthService.get.withAuthorizationCode(
          prospect.authorizationCode as string,
          false,
          false,
        );
        dispatch(setAccessToken(authorization.access_token));
        dispatch(createCurrentUser(authorization.access_token));

        if (!authorization) {
          return;
        }
      }
      const globalSupport = await KeysService.globalSupport.get();
      if (!globalSupport) {
        return;
      }

      const asymmetricTreezorEncryption = new AsymmetricTreezorEncryption();
      const symmetricTreezorEncryption = new SymmetricTreezorEncryption();

      await asymmetricTreezorEncryption.init();
      await symmetricTreezorEncryption.init();

      const keyPair = await asymmetricTreezorEncryption.generateKeyPair();

      const prospectKeys: IProspectKeys = {
        encryptedPrivateKey: {
          withUserSecret: await symmetricTreezorEncryption.encryptKey(
            keyPair.private as string,
            prospect.password as string,
          ),
          withGlobalSupportPublicKey: await asymmetricTreezorEncryption.encryptKey(
            keyPair.private as string,
            globalSupport.globalSupportPublicKey,
          ),
        },
        publicKey: keyPair.public as string,
      };
      prospect.keys = prospectKeys;
      const confirm = await OnboardService.confirm(prospect);
      if (!confirm) {
        dispatch(confirmFailure());
        return;
      }
      dispatch(confirmSuccess());
    } catch (err) {
      dispatch(confirmFailure(err));
    }
  };
};

const finalizeProspect = (newAgent: IAgent, password: string, file?: File) => {
  const finalizeRequest = (): AuthAction => ({ type: AuthActionTypes.FINALYSE_REQUEST });
  const finalizeSuccess = (): AuthAction => ({ type: AuthActionTypes.FINALYSE_SUCESS });
  const finalizeFailure = (error: any): AuthAction => ({ type: AuthActionTypes.FINALYSE_FAILURE, error });

  return async (dispatch: any) => {
    try {
      dispatch(finalizeRequest());

      const agent: IAgent = await OnboardService.finalize(newAgent);

      dispatch(setAgent(agent));
      if (!agent) {
        dispatch(finalizeFailure(new Error('Agent not found')));
      }
      if (file) {
        await dispatch(uploadCompanyFile(file, agent));
      }

      await OnboardService.changePassword({ userId: agent.userId!, password });
      dispatch(finalizeSuccess());
    } catch (error) {
      dispatch(finalizeFailure(error));
    }
  };
};

const searchCompanyBySiret = (siret: number) => {
  const sireneRequest = (): AuthAction => ({ type: AuthActionTypes.SIRENE_REQUEST });
  const sireneSuccess = (sirene: IBusinessInformationsResponse): AuthAction => ({
    type: AuthActionTypes.SIRENE_SUCCESS,
    sirene,
  });
  const sireneFailure = (error: any): AuthAction => ({
    type: AuthActionTypes.SIRENE_FAILURE,
    error,
  });

  return async (dispatch: any) => {
    dispatch(sireneRequest());
    try {
      const sirene = await OnboardService.searchCompanyBySiret(siret);
      dispatch(sireneSuccess(sirene));
    } catch (loginError) {
      dispatch(sireneFailure(loginError));
      console.error(loginError);
    }
  };
};

const logout = () => {
  persistor.purge();

  return (dispatch: any) => {
    dispatch({ type: CoreActionTypes.RESET });
    dispatch({ type: UserActionTypes.RESET });
    dispatch({ type: KeysActionTypes.RESET });
    dispatch({ type: AgentActionTypes.RESET });
    dispatch({ type: AuthActionTypes.LOGOUT });
  };
};

const setAccessToken = (accessToken: string): AuthAction => ({ type: AuthActionTypes.SET_ACCESS_TOKEN, accessToken });
const clearAccessToken = (): AuthAction => ({ type: AuthActionTypes.CLEAR_ACCESS_TOKEN });

export {
  login,
  logout,
  loginWithClientCredentials,
  loginWithAuthorizationCode,
  setAccessToken,
  clearAccessToken,
  onboardProspect,
  confirmProspect,
  finalizeProspect,
  searchCompanyBySiret,
};
