import Spinner from '@components/ui/Spinner';
import {
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { AgentStatus, IAgent } from '@models/agent.model';
import { IResponse } from '@models/core.model';
import { fetchAllAgents, removeAgent } from '@store/agent/agent.action';
import { AppState } from '@store/configuration';
import { fetchOrganization } from '@store/user/user.action';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBan, FaChevronLeft, FaChevronRight, FaMicroscope, FaTrash, FaUserAlt } from 'react-icons/fa';
import { RiUserVoiceFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';

import { ButtonPopover } from '@components/ui/ButtonPopover';
import { Colors, ToastOptions } from '@tools/constants';
import moment from 'moment';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 50,
    height: '100%',
  },
  title: {
    marginBottom: 15,
    fontWeight: 600,
    fontVariantCaps: 'small-caps',
  },
  table: {
    padding: 24,
    borderRadius: 12,
    boxShadow: '0 0 13px 5px rgba(0, 0, 0, 0.05)',
  },
  tableHeader: {
    backgroundColor: '#f3faff',
  },
  tab: {
    display: 'flex',
    '&:hover': {
      color: Colors.treezor.blue,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: Colors.treezor.blue,
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: Colors.treezor.blue,
      outline: 'none',
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}>
    {value === index && children}
  </div>
);

interface IAgentsProps {
  agents?: IAgent[];
}

const Agents = ({ agents }: IAgentsProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateStatus = useSelector((state: AppState) => state.agent.status.update);
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);

  const goToAgent = (agent: IAgent) => {
    history.push(`/agent?agentId=${agent.agentId}`);
  };

  const onRemoveAgent = (agent: IAgent) => {
    Swal.fire({
      title: t('core.message.confirmation.title'),
      html: `${t('core.message.confirmation.remove')} : <b> ${agent.company?.profile?.name} </b> ?`,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: t('core.actions.delete'),
      cancelButtonText: t('core.actions.cancel'),
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = (await dispatch(removeAgent(agent))) as IResponse;
        if (response.success) {
          toast.success(`Agent ${t('core.message.success.remove')}`, ToastOptions);
        } else {
          toast.error(`${t('core.message.fail')}`, ToastOptions);
        }
      }
    });
  };

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>{t('support.agentName')}</TableCell>
            <TableCell align="center">{t('support.managers')}</TableCell>
            <TableCell align="center">{t('support.lastAction')}</TableCell>
            <TableCell align="center">{t('support.createdAt')}</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {agents?.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <FaBan />
                <br />
                {t('support.noData')}
              </TableCell>
            </TableRow>
          )}

          {agents?.map((agent) =>
            updateStatus.inProgress && agent.agentId === currentAgent?.agentId ? (
              <TableRow key={agent.agentId}>
                <TableCell scope="row" colSpan={4} align="center">
                  <Spinner size={16} />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={agent.agentId} style={{ cursor: 'pointer' }}>
                <TableCell scope="row" onClick={() => goToAgent(agent)}>
                  {agent.company?.profile?.name}
                </TableCell>
                <TableCell align="center" onClick={() => goToAgent(agent)}>
                  {agent.overview?.supportUsers?.map((supportUser) => (
                    <p>{`${supportUser.firstname} ${supportUser.lastname}`}</p>
                  ))}
                </TableCell>
                <TableCell align="center" onClick={() => goToAgent(agent)}>
                  {agent.notifications &&
                    agent.notifications.length > 0 &&
                    `${agent.notifications[0].document}: ${t('pages.overview.notifications.sentence').replace(
                      '$action',
                      t(`pages.overview.notifications.action.${agent.notifications[0].action}`),
                    )}`}
                </TableCell>
                <TableCell align="center" onClick={() => goToAgent(agent)}>
                  {moment(agent.createdAt).format('lll')}
                </TableCell>
                <TableCell align="center">
                  <ButtonPopover
                    Icon={FaTrash}
                    onClick={() => onRemoveAgent(agent)}
                    description={t('support.action.delete')}
                  />
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AgentsTab = ({ index, activeTab, status }: { activeTab: number; index: number; status: AgentStatus }) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const agents = useSelector((state: AppState) =>
    state.agent.allAgents[status]?.sort((agentA, agentB) => agentB.createdAt! - agentA.createdAt!),
  );
  const isLoading = useSelector((state: AppState) => state.agent.status.fetch.inProgress);

  useEffect(() => {
    dispatch(fetchAllAgents(status, currentPage));
  }, [dispatch, status, currentPage]);

  return (
    <TabPanel value={activeTab} index={index}>
      <Grid style={{ textAlign: 'end' }}>
        <IconButton disabled={currentPage === 1}>
          <FaChevronLeft onClick={() => setCurrentPage(currentPage - 1)} />
        </IconButton>
        {currentPage}
        <IconButton disabled={agents?.length < 20}>
          <FaChevronRight onClick={() => setCurrentPage(currentPage + 1)} />
        </IconButton>
      </Grid>

      {isLoading && <LinearProgress />}
      <Agents agents={agents} />
    </TabPanel>
  );
};

const Support = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(fetchOrganization());
  }, [dispatch]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Grid style={{ padding: 20 }}>
      <Typography variant="h5" className={classes.title}>
        {t('support.title')}
      </Typography>
      <Tabs
        value={activeTab}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        centered>
        <Tab icon={<FaUserAlt />} label={t('support.tabs.agents')} className={classes.tab} />
        <Tab icon={<RiUserVoiceFill />} label={t('support.tabs.preLeads')} className={classes.tab} />
        <Tab icon={<FaMicroscope />} label={t('support.tabs.leads')} className={classes.tab} />
        <Tab icon={<FaBan />} label={t('support.tabs.refused')} className={classes.tab} />
      </Tabs>

      <AgentsTab status="AGENT" index={0} activeTab={activeTab} />
      <AgentsTab status="PRE_PROSPECT" index={1} activeTab={activeTab} />
      <AgentsTab status="PROSPECT" index={2} activeTab={activeTab} />
      <AgentsTab status="REFUSED" index={3} activeTab={activeTab} />
    </Grid>
  );
};

export default Support;
