import { agentReducer } from '@store/agent/agent.reducer';
import { authReducer } from '@store/auth/auth.reducer';
import { coreReducer } from '@store/core/core.reducer';
import { keysReducer } from '@store/keys/keys.reducer';
import { userReducer } from '@store/user/user.reducer';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { PersistConfig, Persistor, persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import thunkMiddleware from 'redux-thunk';
import jwt_decode from 'jwt-decode';
import { CoreActionTypes } from './core/core.types';
import { UserActionTypes } from './user/user.types';
import { KeysActionTypes } from './keys/keys.types';
import { AgentActionTypes } from './agent/agent.types';
import { AuthAction, AuthActionTypes } from './auth/auth.types';
import { IJWTDecoded } from '@models/user.model';

const appReducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  keys: keysReducer,
  core: coreReducer,
  agent: agentReducer,
});

export type AppState = ReturnType<typeof appReducers>;

const persistConfig: PersistConfig<any> = {
  key: 'authType',
  storage: storageSession,
};

const customApiMiddleware = () => (next: ({ type }: { type: string }) => void) => (action: AuthAction) => {
  if (action.type !== AuthActionTypes.SET_ACCESS_TOKEN || !action.accessToken) return next(action);

  const accessTokenDecoded = jwt_decode<IJWTDecoded>(action.accessToken);
  const timestamp = Math.round(Date.now() / 1000);
  if (accessTokenDecoded && accessTokenDecoded.exp && timestamp > accessTokenDecoded.exp) {
    persistor.purge();
    next({ type: CoreActionTypes.RESET });
    next({ type: UserActionTypes.RESET });
    next({ type: KeysActionTypes.RESET });
    next({ type: AgentActionTypes.RESET });
    next({ type: AuthActionTypes.LOGOUT });
  } else {
    next(action);
  }
};

const appPersistedReducers = persistReducer(persistConfig, appReducers);
const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const appStore = createStore(
  appPersistedReducers,
  composeEnhancers(applyMiddleware(thunkMiddleware, customApiMiddleware)),
);

const persistor: Persistor = persistStore(appStore);

export { appStore, persistor };
