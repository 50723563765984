import React from 'react';
import { Button, NavItem as NavItemStrap } from 'reactstrap';

export interface NavItemProps {
    icon: any;
    label?: string;
    href: string;
    selected?: boolean;
    navigateTo: (href: string) => void;
}

const NavItem = (props: NavItemProps) => {

    const { icon, label, href, selected, navigateTo } = props;

    return (
        <NavItemStrap className={selected ? 'app-nav-item selected' : 'app-nav-item'}>
            <Button onClick={() => navigateTo(href)}>
                <span className="app-nav-item-icon">{icon}</span>
                {label &&
                    <span className="app-nav-item-label">{label}</span>
                }
            </Button>
        </NavItemStrap>
    );
};

export default NavItem;