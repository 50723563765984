import { createReducer } from 'typesafe-actions';

import { KeysAction, KeysActionTypes, KeysState } from '@store/keys/keys.types';

const initialState: KeysState = {};

const keysReducer = createReducer<KeysState, KeysAction>(initialState)
  .handleType(KeysActionTypes.SET_GLOBAL_SUPPORT, (state, action) => ({
    ...state,
    globalSupport: action.globalSupport,
  }))
  .handleType(KeysActionTypes.RESET, () => initialState);

export { keysReducer };
