import {
  IOnboardingSupportUserRequest,
  IJWTDecoded,
  IOnboardingSupportUser,
  IOrganization,
  IUser,
} from '@models/user.model';
import { OrganizationService, SupportUserService } from '@store/auth/organization.service';
import { AppState, appStore } from '@store/configuration';
import { UserAction, UserActionTypes } from '@store/user/user.types';
import JWTDecode from 'jwt-decode';

const createCurrentUser = (accessToken: any) => {
  const accessTokenDecoded: IJWTDecoded = JWTDecode(accessToken);

  const currentUser: IUser = {
    id: accessTokenDecoded.sub,
    type: accessTokenDecoded.userType,
  };

  return { type: UserActionTypes.CREATE, currentUser };
};

const removeCurrentUser = (): UserAction => ({ type: UserActionTypes.REMOVE });

const fetchOrganization = () => {
  const fetchOrganizationRequest = (): UserAction => ({ type: UserActionTypes.ORGANIZATION_GET_REQUEST });
  const fetchOrganizationSuccess = (organization: IOrganization): UserAction => ({
    type: UserActionTypes.ORGANIZATION_GET_SUCESS,
    organization,
  });

  return async (dispatch: any) => {
    dispatch(fetchOrganizationRequest());
    try {
      const supportUserId = (appStore.getState() as AppState).user.currentUser?.id;

      if (!supportUserId) return;
      const onboardingSupportUser = await SupportUserService.findMyOnboardingSupportUser(supportUserId);

      if (!onboardingSupportUser) return;
      const organization = await OrganizationService.findMyOrganization(onboardingSupportUser.organizationId);

      organization.supportUsers = await Promise.all(
        organization.supportUsersId.map(async (supportUserId) => {
          return await SupportUserService.getOnboardingSupportUser(supportUserId);
        }),
      );

      dispatch(fetchOrganizationSuccess(organization));
    } catch (loginError) {
      console.error(loginError);
    }
  };
};

const addSupportUser = (supportUser: IOnboardingSupportUserRequest) => {
  const addSupportUserRequest = (): UserAction => ({ type: UserActionTypes.SUPPORT_USER_POST_REQUEST });
  const addSupportUserSuccess = (supportUser: IOnboardingSupportUser): UserAction => ({
    type: UserActionTypes.SUPPORT_USER_POST_SUCESS,
    supportUser,
  });

  return async (dispatch: any) => {
    dispatch(addSupportUserRequest());

    try {
      const onboardingSupportUser = await SupportUserService.postOnboardingSupportUser(supportUser);
      dispatch(addSupportUserSuccess(onboardingSupportUser));
    } catch (loginError) {
      console.error(loginError);
    }
  };
};

const updateSupportUser = (supportUser: IOnboardingSupportUserRequest) => {
  const updateSupportUserRequest = (): UserAction => ({ type: UserActionTypes.SUPPORT_USER_PUT_REQUEST });
  const updateSupportUserSuccess = (supportUser: IOnboardingSupportUser): UserAction => ({
    type: UserActionTypes.SUPPORT_USER_PUT_SUCESS,
    supportUser,
  });

  return async (dispatch: any) => {
    dispatch(updateSupportUserRequest());

    try {
      const onboardingSupportUser = await SupportUserService.putOnboardingSupportUser(supportUser);
      dispatch(updateSupportUserSuccess(onboardingSupportUser));
    } catch (loginError) {
      console.error(loginError);
    }
  };
};

export { createCurrentUser, removeCurrentUser, fetchOrganization, addSupportUser, updateSupportUser };
