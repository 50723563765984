import { IRegistration } from '@models/create.model';
import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    fontFamily: 'Lato',
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    textAlign: 'center',
    borderWidth: 4,
    borderColor: 'black',
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 50,
  },
  signature: {
    borderWidth: 1,
    borderColor: 'black',
    width: 150,
    height: 50,
    marginTop: 10,
  },
});

Font.register({
  family: 'Open Sans',
  src: `/fonts/Open_Sans/OpenSans-Regular.ttf`,
});
Font.register({
  family: 'Lato',
  src: `/fonts/Lato/Lato-Regular.ttf`,
});
Font.register({
  family: 'Lato Italic',
  src: `/fonts/Lato/Lato-Italic.ttf`,
});
Font.register({
  family: 'Lato Bold',
  src: `/fonts/Lato/Lato-Bold.ttf`,
});

interface RegistrationProps {
  registration: IRegistration;
}

export const RegistrationOutput: FunctionComponent<RegistrationProps> = ({ registration }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text>FORMULAIRE D'ENREGISTREMENT</Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text style={{ marginBottom: 10 }}>Je soussigné(e)</Text>
        <Text>Nom: {registration.lastname}</Text>
        <Text style={{ marginBottom: 10 }}>Prénom: {registration.firstname}</Text>
        <View style={{ marginBottom: 10, flex: 1, flexDirection: 'row' }}>
          <Text>Né(e) le: {moment(registration.birthday).format('L')}</Text>
          <Text style={{ marginLeft: 20 }}>A: {registration.placeOfBirth}</Text>
        </View>
      </View>
      <View style={{ marginBottom: 10 }}>
        <Text></Text>
      </View>
      <View style={{ marginBottom: 20 }}>
        <Text>Fait à {registration.made?.city}</Text>
        <Text>Le {moment(registration.made?.date).format('L')}</Text>
      </View>
      <View style={{ marginBottom: 20, alignSelf: 'flex-end' }}>
        <Text>Signature</Text>
        <View style={styles.signature} />
      </View>
      <View style={{ marginBottom: 10 }}></View>
    </Page>
  </Document>
);
