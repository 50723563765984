import { IKeyPair } from '@models/keys.model';

export enum KeysActionTypes {
  SET_GLOBAL_SUPPORT = 'SET_GLOBAL_SUPPORT',

  RESET = 'RESET_AGENT',
}

export interface SetGlobalSupportAction {
  type: typeof KeysActionTypes.SET_GLOBAL_SUPPORT;
  globalSupport: IKeyPair;
}

export interface ResetAction {
  type: typeof KeysActionTypes.RESET;
}

export type KeysAction = SetGlobalSupportAction | ResetAction;

export interface KeysState {
  globalSupport?: IKeyPair;
}
