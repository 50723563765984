import {
  AgentStatus,
  IAddCommentRequest,
  IAgent,
  IAgentStep,
  IDocFile,
  IDocument,
  IDocumentChange,
} from '@models/agent.model';
import { AgentService, FileService } from '@store/agent/agent.service';
import { AgentAction, AgentActionTypes } from '@store/agent/agent.types';
import { AppState, appStore } from '@store/configuration';
import { removeEmpty } from '@tools/validation';
import * as _ from 'lodash';

const resetStatus = (): AgentAction => ({ type: AgentActionTypes.RESET_STATUS });

const fetchAgent = (agentId: string) => {
  const fetchAgentRequest = (): AgentAction => ({
    type: AgentActionTypes.FETCH_AGENT_REQUEST,
  });
  const fetchAgentSuccess = (agent: IAgent): AgentAction => ({
    type: AgentActionTypes.FETCH_AGENT_SUCCESS,
    agent,
  });
  const fetchAgentFail = (error: any): AgentAction => ({
    type: AgentActionTypes.FETCH_AGENT_FAILURE,
    error,
  });

  return async (dispatch: any) => {
    dispatch(fetchAgentRequest());
    try {
      const agent = await AgentService.get(agentId);
      dispatch(fetchAgentSuccess(agent));
    } catch (loginError) {
      dispatch(fetchAgentFail(loginError));
      console.error(loginError);
    }
  };
};

const createAgent = (agentRequest: IAgent) => {
  const createAgentRequest = (): AgentAction => ({
    type: AgentActionTypes.CREATE_AGENT_REQUEST,
  });
  const createAgentSuccess = (agent: IAgent): AgentAction => ({
    type: AgentActionTypes.CREATE_AGENT_SUCCESS,
    agent,
  });
  const createAgentFail = (error: any): AgentAction => ({
    type: AgentActionTypes.CREATE_AGENT_FAILURE,
    error,
  });

  return async (dispatch: any) => {
    dispatch(createAgentRequest());
    try {
      const agent = await AgentService.post(removeEmpty(agentRequest));
      dispatch(createAgentSuccess(agent));
    } catch (loginError) {
      dispatch(createAgentFail(loginError));
      console.error(loginError);
    }
  };
};

const fetchAgentByCurrentUser = () => {
  const fetchAgentRequest = (): AgentAction => ({
    type: AgentActionTypes.FETCH_AGENT_REQUEST,
  });
  const fetchAgentSuccess = (agent: IAgent): AgentAction => ({
    type: AgentActionTypes.FETCH_AGENT_SUCCESS,
    agent,
  });

  return async (dispatch: any) => {
    dispatch(fetchAgentRequest());
    try {
      const agent = await AgentService.findByCurrentUser();
      dispatch(fetchAgentSuccess(agent));
    } catch (loginError) {
      console.error(loginError);
    }
  };
};

const updateAgent = (agentRequest: IAgent) => {
  const updateAgentRequest = (): AgentAction => ({
    type: AgentActionTypes.UPDATE_AGENT_REQUEST,
  });
  const updateAgentSuccess = (agent: IAgent): AgentAction => ({
    type: AgentActionTypes.UPDATE_AGENT_SUCCESS,
    agent,
  });

  return async (dispatch: any) => {
    dispatch(updateAgentRequest());
    try {
      const state: AppState = appStore.getState();
      if (!agentRequest.agentId) {
        agentRequest.agentId = state.agent.currentAgent?.agentId;
      }

      const agent = await AgentService.put(_.cloneDeep(agentRequest) as IAgent);
      dispatch(updateAgentSuccess(_.merge(agentRequest, agent) as IAgent));
    } catch (loginError) {
      console.error(loginError);
    }
  };
};

const publishComment = (commentRequest: IAddCommentRequest) => {
  const updateAgentRequest = (): AgentAction => ({
    type: AgentActionTypes.UPDATE_AGENT_REQUEST,
  });
  const updateAgentSuccess = (agent: IAgent): AgentAction => ({
    type: AgentActionTypes.UPDATE_AGENT_SUCCESS,
    agent,
  });

  return async (dispatch: any) => {
    dispatch(updateAgentRequest());

    try {
      const currentAgent = (appStore.getState() as AppState).agent.currentAgent as IAgent;

      const agent = await AgentService.putComment(commentRequest);
      dispatch(updateAgentSuccess(_.merge(agent, currentAgent)));
    } catch (loginError) {
      console.error(loginError);
    }
  };
};

const fetchAllAgents = (status: AgentStatus, page?: number) => {
  const fetchAllAgentsRequest = (): AgentAction => ({
    type: AgentActionTypes.FETCH_ALL_AGENTS_REQUEST,
  });
  const fetchAllAgentsSuccess = (agents: IAgent[]): AgentAction => ({
    type: AgentActionTypes.FETCH_ALL_AGENTS_SUCCESS,
    status,
    agents,
  });

  return async (dispatch: any) => {
    dispatch(fetchAllAgentsRequest());
    try {
      const agents = await AgentService.all(status, page);
      dispatch(fetchAllAgentsSuccess(agents));
    } catch (loginError) {
      console.error(loginError);
    }
  };
};

const uploadDocumentFile = (file: File, document: IDocument) => {
  const updateAgentRequest = (): AgentAction => ({
    type: AgentActionTypes.UPDATE_AGENT_REQUEST,
  });

  return async (dispatch: any) => {
    try {
      dispatch(updateAgentRequest());

      const currentAgent = (appStore.getState() as AppState).agent.currentAgent as IAgent;
      await FileService.putFile(file, `${currentAgent?.agentId}`, document.documentId);

      document.path = [currentAgent?.agentId, document.documentId].join('/');
      document.mimeType = file.type;

      dispatch(updateDocument(document));
    } catch (awsError) {
      console.error(awsError);
    }
  };
};

const uploadDocumentsFiles = (data: IDocFile[]) => {
  const updateAgentRequest = (): AgentAction => ({
    type: AgentActionTypes.UPDATE_AGENT_REQUEST,
  });

  return async (dispatch: any) => {
    try {
      dispatch(updateAgentRequest());

      const currentAgent = (appStore.getState() as AppState).agent.currentAgent as IAgent;
      data = await data.map(({ document, file }) => {
        FileService.putFile(file, `${currentAgent?.agentId}`, document.documentId);
        return {
          file,
          document: {
            ...document,
            path: [currentAgent?.agentId, document.documentId].join('/'),
            mimeType: file.type,
          },
        };
      });
      return dispatch(updateDocuments(data));
    } catch (awsError) {
      console.error(awsError);
    }
  };
};

const removeDocumentFile = (document: IDocument) => {
  return async (dispatch: any) => {
    try {
      const agentId = (appStore.getState() as AppState).agent.currentAgent?.agentId;

      await FileService.deleteFile(agentId as string, document.documentId);

      document.path = undefined;
      document.mimeType = undefined;

      dispatch(updateDocument(document));
    } catch (awsError) {
      console.error(awsError);
    }
  };
};

const updateDocumentLink = (uri: string, document: IDocument) => {
  document.path = uri;
  return updateDocument(document);
};

const updateDocument = (document: IDocument) => {
  const currentAgent = (appStore.getState() as AppState).agent.currentAgent as IAgent;

  const agentUpdated: IAgent = {
    steps: (currentAgent?.steps as IAgentStep[]).map<IAgentStep>((step) => {
      return {
        ...step,
        documents: step.documents?.map<IDocument>((documentIterator) => {
          if (documentIterator.fullName === document.fullName) {
            return document;
          }
          return documentIterator;
        }),
      };
    }),
  };

  return updateAgent(agentUpdated);
};

const updateDocuments = (data: { document: any; file: File }[]) => {
  const currentAgent = (appStore.getState() as AppState).agent.currentAgent as IAgent;

  const agentUpdated: IAgent = {
    steps: (currentAgent?.steps as IAgentStep[]).map<IAgentStep>((step) => {
      return {
        ...step,
        documents: step.documents?.map<IDocument>((documentIterator) => {
          const document = data.find(
            ({ document }) => documentIterator.fullName === document.fullName && document.step === step.label,
          )?.document;
          delete document?.step;
          return document
            ? {
                ...document,
                changes: [
                  getChange({ source: 'CLIENT', done: true, status: 'DONE' }),
                  getChange({ source: 'SUPPORT', action: 'UPLOAD', done: true, status: 'UPLOADED' }),
                  getChange({ source: 'COMPLIANCE', status: 'TO_APPROVE' }),
                  ...document.changes,
                ],
                status: 'TO_APPROVE',
              }
            : documentIterator;
        }),
      };
    }),
  };

  return updateAgent(agentUpdated);
};

const setAgent = (agent: IAgent): AgentAction => ({
  type: AgentActionTypes.SET_AGENT,
  agent,
});
const removeCurrentAgent = (): AgentAction => ({
  type: AgentActionTypes.REMOVE_CURRENT_AGENT,
});

const toggleStep = (stepLabel: string, expand = false): AgentAction => {
  const currentAgent = (appStore.getState() as AppState).agent.currentAgent as IAgent;

  return {
    type: AgentActionTypes.SET_AGENT,
    agent: {
      ...currentAgent,
      steps: currentAgent.steps?.map((step) => {
        if (step.label !== stepLabel) return step;
        return { ...step, expand: expand ? expand : !step.expand };
      }),
    },
  };
};

const toggleDocumentComments = (stepLabel: string, documentName: string, expand = false): AgentAction => {
  const currentAgent = (appStore.getState() as AppState).agent.currentAgent as IAgent;

  return {
    type: AgentActionTypes.SET_AGENT,
    agent: {
      ...currentAgent,
      steps: currentAgent.steps?.map((step) => {
        if (step.label !== stepLabel) return step;
        return {
          ...step,
          documents: step.documents?.map((document) => {
            if (document.name !== documentName) return document;
            return { ...document, commentsIsExpand: expand ? expand : !document.commentsIsExpand };
          }),
        };
      }),
    },
  };
};

const getChange = (change: IDocumentChange) => ({
  source: change.source,
  action: change.action,
  date: Date.now(),
  done: Boolean(change.done),
  status: change.status,
});

const removeAgent = (agent: IAgent) => {
  const removeAgentsRequest = (): AgentAction => ({
    type: AgentActionTypes.REMOVE_AGENT_REQUEST,
  });
  const removeAgentsSuccess = (agentId: string): AgentAction => ({
    type: AgentActionTypes.REMOVE_AGENT_SUCCESS,
    agentId,
  });

  return async (dispatch: any) => {
    dispatch(removeAgentsRequest());
    try {
      const response = await AgentService.delete(agent);
      if (response && agent.agentId) dispatch(removeAgentsSuccess(agent.agentId));
      return response;
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  };
};

const uploadCompanyFile = (file: File, agent: IAgent) => {
  const updateAgentRequest = (): AgentAction => ({
    type: AgentActionTypes.UPDATE_AGENT_REQUEST,
  });

  return async (dispatch: any) => {
    try {
      dispatch(updateAgentRequest());
      await FileService.putFile(file, agent.agentId as string);
      agent = {
        ...agent,
        company: {
          ...agent.company,
        },
      };
      dispatch(updateAgent(agent));
    } catch (awsError) {
      console.error(awsError);
    }
  };
};

const updateProspectStatus = (status: AgentStatus) => {
  const currentAgent = (appStore.getState() as AppState).agent.currentAgent as IAgent;
  const updateAgentRequest = (): AgentAction => ({
    type: AgentActionTypes.UPDATE_AGENT_REQUEST,
  });
  const updateAgentSuccess = (agent: IAgent): AgentAction => ({
    type: AgentActionTypes.UPDATE_AGENT_SUCCESS,
    agent,
  });
  return async (dispatch: any) => {
    dispatch(updateAgentRequest());
    try {
      const response = currentAgent?.agentId
        ? await AgentService.updateProspectStatus(currentAgent.agentId, status)
        : null;
      if (response) dispatch(updateAgentSuccess({ ...currentAgent, status: status }));
      return response;
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  };
};

export {
  createAgent,
  fetchAgent,
  updateAgent,
  updateDocument,
  setAgent,
  fetchAllAgents,
  fetchAgentByCurrentUser,
  toggleDocumentComments,
  publishComment,
  uploadDocumentFile,
  updateDocumentLink,
  removeDocumentFile,
  removeCurrentAgent,
  toggleStep,
  resetStatus,
  uploadDocumentsFiles,
  removeAgent,
  uploadCompanyFile,
  updateProspectStatus,
};
