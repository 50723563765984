import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { IResumeExperience } from '@models/create.model';
import Title from './Title';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0,
    },
  } as any,
  entryContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  date: {
    fontSize: 11,
    fontFamily: 'Lato Italic',
  },
  detailContainer: {
    flexDirection: 'row',
  },
  detailLeftColumn: {
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
  },
  detailRightColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  bulletPoint: {
    fontSize: 10,
  },
  details: {
    fontSize: 10,
  },
  headerContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  rightColumn: {
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-end',
    justifySelf: 'flex-end',
  } as any,
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
});

interface ExperienceEntryProps {
  company?: string;
  details?: string;
  position?: string;
  duration?: string;
}

const ExperienceEntry = (props: ExperienceEntryProps) => {
  const { company, details, position, duration } = props;

  const title = `${company} | ${position}`;
  return (
    <View style={styles.entryContainer}>
      <View style={styles.headerContainer}>
        <View style={styles.leftColumn}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.date}>{duration}</Text>
        </View>
      </View>
      <Text style={styles.details}>{details}</Text>
    </View>
  );
};

interface ExperienceProps {
  experiences: IResumeExperience[];
}

const Experience = (props: ExperienceProps) => (
  <View style={styles.container}>
    <Title>Experience</Title>
    {props.experiences.map(({ company, duration, details, position }, index) => (
      <ExperienceEntry
        company={company}
        duration={duration}
        details={details}
        key={`experience-${index}`}
        position={position}
      />
    ))}
  </View>
);

export default Experience;
