import { IRules } from '@models/core.model';

export enum CoreActionTypes {
  SET_ERROR = 'SET_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
  SET_SIDE_TAB = 'SET_SIDE_TAB',

  FETCH_CURRENT_RULES_REQUEST = 'FETCH_CURRENT_RULES_REQUEST',
  FETCH_CURRENT_RULES_SUCCESS = 'FETCH_CURRENT_RULES_SUCCESS',
  FETCH_CURRENT_RULES_FAILURE = 'FETCH_CURRENT_RULES_FAILURE',

  RESET = 'RESET_AGENT',
}

export interface SetErrorAction {
  type: typeof CoreActionTypes.SET_ERROR;
  error: string;
}

export interface SetSideTab {
  type: typeof CoreActionTypes.SET_SIDE_TAB;
  tab: string;
}

export interface ClearErrorAction {
  type: typeof CoreActionTypes.CLEAR_ERROR;
}

export interface FetchCurrentRulesRequestAction {
  type: typeof CoreActionTypes.FETCH_CURRENT_RULES_REQUEST;
}

export interface FetchCurrentRulesSuccessAction {
  type: typeof CoreActionTypes.FETCH_CURRENT_RULES_SUCCESS;
  response: IRules;
}

export interface FetchCurrentRulesFailureAction {
  type: typeof CoreActionTypes.FETCH_CURRENT_RULES_FAILURE;
  error: any;
}

export interface ResetAction {
  type: typeof CoreActionTypes.RESET;
}

export type CoreAction =
  | SetErrorAction
  | ClearErrorAction
  | SetSideTab
  | FetchCurrentRulesRequestAction
  | FetchCurrentRulesSuccessAction
  | FetchCurrentRulesFailureAction
  | ResetAction;

export interface CoreState {
  error?: string;
  activeTab?: string;
  rules?: IRules;
}
