import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { IResumePersonal } from '@models/create.model';
import { Colors } from '../../../tools/constants';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
    fontFamily: 'Lato Bold',
    fontSize: 11,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 9,
  },
  name: {
    fontSize: 24,
    color: Colors.treezor.blue,
  },
});

interface HeaderProps {
  header: IResumePersonal;
}

export default (props: HeaderProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.name}>{props.header.name}</Text>
        <Text style={{ fontSize: 16 }}>{props.header.job}</Text>
      </View>
      <View style={[styles.row, { marginTop: 6 }]}>
        <Link src="" style={{}}>
          {props.header.email}
        </Link>
        <Text style={{}}>{props.header.phone}</Text>
      </View>
      <View style={styles.linkColumn}></View>
    </View>
  );
};
