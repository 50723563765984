import AppLayout from '@components/layout/AppLayout';
import RoutedContent from '@components/RoutedContent';
import { appStore, persistor } from '@store/configuration';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Config } from './config';
import './i18n';
import './tools/document.state.machine';

const basePath = process.env.BASE_PATH || '/';

const AppClient = () => {
  moment.locale('fr');

  const { t } = useTranslation();

  useEffect(() => {
    window.document.title = `${Config.support.name} OnBoarding`;
  }, []);

  return (
    <Provider store={appStore}>
      <PersistGate loading={<div>{t('core.loading')}</div>} persistor={persistor as any}>
        <Router basename={basePath}>
          <AppLayout>
            <RoutedContent />
            <ToastContainer/>
          </AppLayout>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default hot(module)(AppClient);
