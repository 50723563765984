import { EmptyLayout } from '@components/layout/EmptyLayout';
import React from 'react';
import { Container, Row } from 'reactstrap';

const ConfirmationFailed = () => (
  <EmptyLayout>
    <Container fluid>
      <Row></Row>
    </Container>
  </EmptyLayout>
);

export default ConfirmationFailed;
