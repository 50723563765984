import { IConviction } from '@models/create.model';

export const CONVICTION_MOCK: IConviction = {
  address: {
    city: 'Paris',
    country: 'France',
    line1: '26 rue Yvonne Le Tac',
    zipCode: '75018',
  },
  birthday: Date.now(),
  father: {
    firstname: 'Papa',
    lastname: 'Ben Slimane',
  },
  mother: {
    firstname: 'Maman',
    lastname: 'Ben Slimane',
  },
  made: {
    city: 'Paris',
    date: '06/12/2020',
  },
  firstname: 'Amir',
  lastname: 'Ben Slimane',
  placeOfBirth: 'Antibes',
};
