import { MachineState } from 'robot3';

export enum DocumentMachineState {
  IDLE = 'idle',
  PROVIDE = 'provide',
  ACCEPTANCE = 'acceptance',
  COMPLIANCE = 'compliance',
  SIGN = 'sign',
  DONE = 'done',
}

export enum DocumentMachineAction {
  AGENT_READ = 'agent/read',
  AGENT_UPLOAD = 'agent/upload',
  AGENT_LINK = 'agent/link',
  AGENT_SIGN = 'agent/sign',
  AGENT_REMOVE = 'agent/remove',

  SUPPORT_UPLOAD = 'support/upload',
  SUPPORT_LINK = 'support/link',
  SUPPORT_SIGN = 'support/sign',
  SUPPORT_ACCEPT = 'support/accept',
  SUPPORT_REFUSE = 'support/refuse',
  SUPPORT_UNDO = 'support/undo',

  COMPLIANCE_APPROVE = 'compliance/approve',
  COMPLIANCE_REJECT = 'compliance/reject',
  COMPLIANCE_REMOVE = 'compliance/remove',
  COMPLIANCE_UNDO = 'compliance/undo',
}

export interface DocumentMachine {
  idle: MachineState;
  provide: MachineState;
  acceptance: MachineState;
  compliance: MachineState;
  sign: MachineState;
  done: MachineState;
}
