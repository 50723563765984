import { IDocument } from '@models/agent.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { UIForm } from './form/Form';

interface UploadFileModalProps {
  className?: string;
  isOpen: boolean;
  toggle: () => void;
  document: IDocument;
  handleLink: (url: string) => void;
}

const LinkFileModal = (props: UploadFileModalProps) => {
  const { isOpen, document, toggle, handleLink } = props;
  const { t } = useTranslation();

  const link = (values: any) => {
    handleLink(values.link);
  };

  return (
    <Modal style={{ width: '50%' }} centered={true} isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <UIForm
          defaultValues={{ link: document.path }}
          inputs={[{ name: 'link', label: t('forms.link.uri.label') }]}
          validLabel={t('forms.link.submit.label')}
          onSubmit={link}
        />
      </ModalBody>
    </Modal>
  );
};

export default LinkFileModal;
