import DragAndDrop from '@components/DragAndDrop';
import { UIForm } from '@components/form/Form';
import Spinner from '@components/ui/Spinner';
import useToggle from '@hooks/useToggle';
import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { IAgent } from '@models/agent.model';
import { createAgent } from '@store/agent/agent.action';
import { onboardProspect } from '@store/auth/auth.action';
import { AppState } from '@store/configuration';
import { parse } from 'papaparse';
import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { Config } from '../config';

const Admin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showAlert, toggleAlert] = useToggle(false);
  const [useMultiple, toggleMultiple] = useToggle(false);

  const onboarding = useSelector((state: AppState) => state.auth.status.onboarding);
  const error = useSelector((state: AppState) => state.auth.error);
  const organization = useSelector((state: AppState) => state.user.organization);

  const doOnboardUser = ({ email }: { email: string }) => {
    toggleAlert();
    dispatch(onboardProspect(email, organization?.organizationId as string));
  };

  return (
    <Grid container direction="column" justify="center" alignItems="center" style={{ padding: 10, height: '100%' }}>
      {Config.app.enableMultipleAgents && (
        <FormControlLabel
          style={{ flex: 1, alignSelf: 'flex-end' }}
          value="start"
          control={<Switch checked={useMultiple} onChange={toggleMultiple} />}
          label={t('admin.onboard.onboardMultipleAgents')}
          labelPlacement="start"
        />
      )}

      {!useMultiple && (
        <Grid container direction="column" justify="center" alignItems="center" style={{ flex: 10, padding: 10 }}>
          <UIForm
            label={t('admin.onboard.form.label')}
            validLabel={t('admin.onboard.form.send')}
            isLoading={onboarding.inProgress}
            inputs={[{ name: 'email', label: t('admin.onboard.form.email') }]}
            onSubmit={(values) => doOnboardUser(values)}
          />

          {showAlert && error && (
            <Alert onClose={toggleAlert} severity="error">
              {t('admin.onboard.error')}
            </Alert>
          )}
          {showAlert && onboarding.done && (
            <Alert onClose={toggleAlert} severity="success">
              {t('admin.onboard.success')}
            </Alert>
          )}
        </Grid>
      )}

      {Config.app.enableMultipleAgents && useMultiple && <OnboardMulipleAgent />}
    </Grid>
  );
};

interface IAgentCSVRow {
  CODE?: string;
  SIRET: string;
  EMAIL: string;
  PHONE?: string;
  NAME: string;
  OWNER_FIRSTNAME: string;
  OWNER_LASTNAME: string;
  ADDRESS: string;
  CITY: string;
  ZIPCODE: string;
  COUNTRY: string;
}

const OnboardMulipleAgent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createStatus = useSelector((state: AppState) => state.agent.status.create);
  const organizationId = useSelector((state: AppState) => state.user.organization?.organizationId);

  const [agentsPreview, setAgentsPreview] = useState<IAgent[]>();

  const doOnboardMultipleAgents = async (event: MouseEvent | KeyboardEvent) => {
    event.preventDefault();

    if (agentsPreview)
      agentsPreview.map(async (agentPreview) => dispatch(createAgent({ ...agentPreview, organizationId })));
  };

  const parseCsvFile = (file: File) => {
    parse(file, {
      header: true,
      delimiter: ',',
      transformHeader: (header) =>
        header
          .split(',')
          .map((head) => head.trim())
          .join(','),
      skipEmptyLines: true,
      complete: (agents) => {
        if (Array.isArray(agents.data))
          setAgentsPreview(
            agents.data.map(
              (agent: IAgentCSVRow) =>
                ({
                  organizationId,
                  code: agent.CODE?.trim(),
                  company: {
                    profile: {
                      siret: agent.SIRET?.trim(),
                      address: {
                        line1: agent.ADDRESS?.trim(),
                        city: agent.CITY?.trim(),
                        zipCode: agent.ZIPCODE?.trim(),
                        country: agent.COUNTRY?.trim(),
                      },
                      name: agent.NAME?.trim(),
                    },
                  },
                  legalRepresentant: {
                    firstname: agent.OWNER_FIRSTNAME?.trim(),
                    lastname: agent.OWNER_LASTNAME?.trim(),
                    email: agent.EMAIL?.trim(),
                    phone: agent.PHONE?.trim(),
                  },
                } as IAgent),
            ),
          );
        else console.error(agents.errors);
      },
    });
  };

  return (
    <Grid container direction="column" justify="flex-start" alignItems="center" style={{ flex: 10, padding: 10 }}>
      {!agentsPreview && <DragAndDrop handleDrop={parseCsvFile} accept=".csv" />}

      {agentsPreview && agentsPreview.length && (
        <Table>
          <thead>
            <tr>
              <th>{t('admin.onboard.overview.code')}</th>
              <th>{t('admin.onboard.overview.owner')}</th>
              <th>{t('admin.onboard.overview.name')}</th>
              <th>{t('admin.onboard.overview.email')}</th>
              <th>{t('admin.onboard.overview.address')}</th>
              <th>{t('admin.onboard.overview.zipCode')}</th>
              <th>{t('admin.onboard.overview.city')}</th>
              <th>{t('admin.onboard.overview.country')}</th>
            </tr>
          </thead>
          <tbody>
            {agentsPreview.map((agentPreview, index) => (
              <tr key={index}>
                <th scope="row">{agentPreview.code}</th>
                <td>{agentPreview.company?.profile?.name}</td>
                <td>
                  {[agentPreview.legalRepresentant?.firstname, agentPreview.legalRepresentant?.lastname].join(' ')}
                </td>
                <td>{agentPreview.legalRepresentant?.email}</td>
                <td>{agentPreview.company?.profile?.address?.line1}</td>
                <td>{agentPreview.company?.profile?.address?.zipCode}</td>
                <td>{agentPreview.company?.profile?.address?.city}</td>
                <td>{agentPreview.company?.profile?.address?.country}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {agentsPreview && (
        <Button
          color="primary"
          className="onboard-multiple-btn"
          style={{ alignSelf: 'flex-end' }}
          onClick={doOnboardMultipleAgents}>
          {t('admin.onboard.onboardTheseAgents')}
          {createStatus.inProgress && <Spinner size={20} color="inherit" style={{ marginLeft: 20 }} />}
        </Button>
      )}
    </Grid>
  );
};

export default Admin;
