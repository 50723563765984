import { createReducer } from 'typesafe-actions';

import { AgentStatus, IAgent } from '@models/agent.model';
import { AgentAction, AgentActionTypes, AgentState } from '@store/agent/agent.types';

const initialState: AgentState = {
  allAgents: {} as Record<AgentStatus, IAgent[]>,
  status: {
    create: { inProgress: false, done: false },
    fetch: { inProgress: false, done: false },
    update: { inProgress: false, done: false },
    remove: { inProgress: false, done: false },
  },
};

const agentReducer = createReducer<AgentState, AgentAction>(initialState)
  .handleType(AgentActionTypes.FETCH_AGENT_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, fetch: { inProgress: true, done: false } },
  }))
  .handleType(AgentActionTypes.FETCH_AGENT_SUCCESS, (state, action) => ({
    ...state,
    currentAgent: action.agent,
    status: { ...state.status, fetch: { inProgress: false, done: true } },
  }))
  .handleType(AgentActionTypes.FETCH_AGENT_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, fetch: { inProgress: false, done: false } },
    error: action.error,
  }))
  .handleType(AgentActionTypes.CREATE_AGENT_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, create: { inProgress: true, done: false } },
  }))
  .handleType(AgentActionTypes.CREATE_AGENT_SUCCESS, (state, action) => ({
    ...state,
    status: { ...state.status, create: { inProgress: false, done: true } },
  }))
  .handleType(AgentActionTypes.CREATE_AGENT_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, create: { inProgress: false, done: false } },
    error: action.error,
  }))
  .handleType(AgentActionTypes.UPDATE_AGENT_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, update: { inProgress: true, done: false } },
  }))
  .handleType(AgentActionTypes.UPDATE_AGENT_SUCCESS, (state, action) => ({
    ...state,
    currentAgent: action.agent,
    status: { ...state.status, update: { inProgress: false, done: true } },
  }))
  .handleType(AgentActionTypes.UPDATE_AGENT_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, update: { inProgress: false, done: false } },
    error: action.error,
  }))
  .handleType(AgentActionTypes.FETCH_ALL_AGENTS_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, fetch: { inProgress: true, done: false } },
  }))
  .handleType(AgentActionTypes.FETCH_ALL_AGENTS_SUCCESS, (state, action) => ({
    ...state,
    allAgents: { ...state.allAgents, [action.status]: action.agents },
    status: { ...state.status, fetch: { inProgress: false, done: true } },
  }))
  .handleType(AgentActionTypes.FETCH_ALL_AGENTS_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, fetch: { inProgress: false, done: false } },
    error: action.error,
  }))
  .handleType(AgentActionTypes.REMOVE_AGENT_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, remove: { inProgress: true, done: false } },
  }))
  .handleType(AgentActionTypes.REMOVE_AGENT_SUCCESS, (state, action) => ({
    ...state,
    status: { ...state.status, remove: { inProgress: false, done: true } },
  }))
  .handleType(AgentActionTypes.REMOVE_AGENT_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, remove: { inProgress: false, done: false } },
    error: action.error,
  }))
  .handleType(AgentActionTypes.SET_AGENT, (state, action) => ({
    ...state,
    currentAgent: action.agent,
  }))
  .handleType(AgentActionTypes.REMOVE_CURRENT_AGENT, (state) => ({
    ...state,
    currentAgent: undefined,
  }))
  .handleType(AgentActionTypes.RESET, (state) => ({
    ...state,
    currentAgent: undefined,
  }))
  .handleType(AgentActionTypes.RESET_STATUS, (state) => initialState);

export { agentReducer };
