import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';
import Title from './Title';

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Lato Bold',
    fontSize: 11,
    marginBottom: 10,
  },
  goal: {
    fontFamily: 'Lato',
    fontSize: 10,
    marginBottom: 10,
  },
});


interface GoalProps {
  goal: string;
}

const Goal: FunctionComponent<GoalProps> = ({ goal }) => (
  <View>
    <Title>Objectif</Title>
    <View>
      <Text style={styles.goal}>{goal}</Text>
    </View>
  </View>
);

export default Goal;
