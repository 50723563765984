const FileFormats = {
  pdf: ['pdf'],
  image: ['jpg', 'jpeg', 'png'],
};

export const FileUtils = {
  toBase64: async (file: File): Promise<Buffer | string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as Buffer);
      };
      reader.onerror = (error) => reject(error);
    }),
  isPDF: (name?: string): boolean => {
    if (!name) return false;
    return Boolean(FileFormats.pdf.find((format) => name.includes(format)));
  },
  isImage: (name?: string): boolean => {
    if (!name) return false;
    return Boolean(FileFormats.image.find((format) => name.includes(format)));
  },
  isPreviewable: (name?: string): boolean => {
    if (!name) return false;
    return Boolean([...FileFormats.pdf, ...FileFormats.image].find((format) => name.includes(format)));
  },

  base64toBlob : (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  },
};
