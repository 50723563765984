import { IKeyPair } from '@models/keys.model';
import { SodiumPlus, X25519PublicKey, X25519SecretKey } from 'sodium-plus';

export class AsymmetricTreezorEncryption {
  static instance: AsymmetricTreezorEncryption;
  sodium: any;

  constructor() {
    if (AsymmetricTreezorEncryption.instance) {
      return AsymmetricTreezorEncryption.instance;
    }

    AsymmetricTreezorEncryption.instance = this;
  }

  async init() {
    if (!this.sodium) {
      this.sodium = await SodiumPlus.auto();
    }
  }
  /**
   * @return {Promise<{public: string, private: string}>} The hex key pair
   */
  async generateKeyPair(): Promise<IKeyPair> {
    try {
      const keyPair = await this.sodium.crypto_box_keypair();

      const publicKeyBin = await this.sodium.crypto_box_publickey(keyPair);
      const privateKeyBin = await this.sodium.crypto_box_secretkey(keyPair);

      const publicKeyHex = await this.sodium.sodium_bin2hex(publicKeyBin.buffer);
      const privateKeyHex = await this.sodium.sodium_bin2hex(privateKeyBin.buffer);

      return {
        public: publicKeyHex,
        private: privateKeyHex,
      };
    } catch (error) {
      console.error(error);
      throw new Error('[Asymmetric] Generate Key Pair');
    }
  }

  /**
   * @param {string} key The hex key to encrypt
   * @param {string} publicKey The hex public key to encrypt key
   *
   * @return {Promise<string>} The hex key encryted with public key
   */
  async encryptKey(key: string, publicKey: string): Promise<string> {
    try {
      const publicKeyBin = await this.sodium.sodium_hex2bin(publicKey);

      const encryptedKeyBin = await this.sodium.crypto_box_seal(key, new X25519PublicKey(publicKeyBin));

      const encryptedKeyHex = await this.sodium.sodium_bin2hex(encryptedKeyBin);
      return encryptedKeyHex;
    } catch (error) {
      console.error(error);
      throw new Error('[Asymmetric] Encrypt Key');
    }
  }

  /**
   * @param {string} encryptedKey The hex key to decryt
   * @param {string} publicKey The hex public key to decrypt key
   * @param {string} privateKey The hex public key to decrypt key
   *
   * @return {Promise<string>} The hex key decryted with private key
   */
  async decryptKey(encryptedKey: string, publicKey: string, privateKey: string): Promise<string> {
    try {
      const encrypptedKeyBin = await this.sodium.sodium_hex2bin(encryptedKey);
      const publicKeyBin = await this.sodium.sodium_hex2bin(publicKey);
      const privateKeyBin = await this.sodium.sodium_hex2bin(privateKey);

      const decryptedKeyBin = await this.sodium.crypto_box_seal_open(
        encrypptedKeyBin,
        new X25519PublicKey(publicKeyBin),
        new X25519SecretKey(privateKeyBin),
      );

      const decryptedKeyHex: string = await decryptedKeyBin.toString();

      return decryptedKeyHex;
    } catch (error) {
      console.error(error);
      throw new Error('[Asymmetric] Decrypt Key');
    }
  }

  /**
   * @param {string} encryptedData The data to decryt
   * @param {string} publicKey The hex public key to decrypt data
   * @param {string} privateKey The hex public key to decrypt data
   *
   * @return {Promise<string>} The hex key decryted with private key
   */
  async decryptData(encryptedData: string, publicKey: string, privateKey: string): Promise<string> {
    try {
      const publicKeyBin = await this.sodium.sodium_hex2bin(publicKey);
      const privateKeyBin = await this.sodium.sodium_hex2bin(privateKey);

      const decryptedDataBin = await this.sodium.crypto_box_seal_open(
        Buffer.from(encryptedData, 'base64'),
        new X25519PublicKey(publicKeyBin),
        new X25519SecretKey(privateKeyBin),
      );

      const decryptedDataHex: string = await decryptedDataBin.toString();

      return decryptedDataHex;
    } catch (error) {
      console.error(error);
      throw new Error('[Asymmetric] Decrypt Data');
    }
  }
}

// declare const window: {
//   asymmetricEncryption: AsymmetricTreezorEncryption;
//   symmetricEncryption: SymmetricTreezorEncryption;
// };
// window.asymmetricEncryption = new AsymmetricTreezorEncryption();
// window.asymmetricEncryption.init();
// window.symmetricEncryption = new SymmetricTreezorEncryption();
// window.symmetricEncryption.init();
