import { IRules } from '@models/core.model';
import { RulesService } from '@store/core/core.service';
import { CoreAction, CoreActionTypes } from '@store/core/core.types';

const setError = (error: string): CoreAction => ({ type: CoreActionTypes.SET_ERROR, error });
const clearError = (): CoreAction => ({ type: CoreActionTypes.CLEAR_ERROR });

const fetchCurrentRules = () => {
  const fetchCurrentRulesRequest = (): CoreAction => ({ type: CoreActionTypes.FETCH_CURRENT_RULES_REQUEST });
  const fetchCurrentRulesSuccess = (response: IRules): CoreAction => ({
    type: CoreActionTypes.FETCH_CURRENT_RULES_SUCCESS,
    response,
  });

  return async (dispatch: any) => {
    dispatch(fetchCurrentRulesRequest());
    try {
      const rules = await RulesService.getCurrentRules();
      dispatch(fetchCurrentRulesSuccess(rules));
    } catch (loginError) {
      console.error(loginError);
    }
  };
};

const changeSideTab = (tab: string): CoreAction => ({ type: CoreActionTypes.SET_SIDE_TAB, tab });

export { setError, clearError, changeSideTab, fetchCurrentRules };
