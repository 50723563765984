import { IGetGlobalSupportKeyResponse, IKeyPair, ISupportUserKeysRequest } from '@models/keys.model';
import { appStore } from '@store/configuration';
import { AsymmetricTreezorEncryption } from '@tools/AsymmetricTreezorEncryption';
import { SymmetricTreezorEncryption } from '@tools/SymmetricTreezorEncryption';
import { Config } from '../../config';

const KeysService = {
  globalSupport: {
    get: async () => {
      const state = appStore.getState();

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${state.auth.accessToken}`,
      };

      const globalSupportResponse = await fetch(`${Config.api.keyManagement}/keys/global-support`, { headers });
      const globalSupport = (await globalSupportResponse.json()) as IGetGlobalSupportKeyResponse;

      return globalSupport;
    },
  },
};

const CreateKeysService = {
  supportUser: async (passphrase: string, globalSupportPrivateKey: string): Promise<ISupportUserKeysRequest> => {
    const asymmetricTreezorEncryption = new AsymmetricTreezorEncryption();
    const symmetricTreezorEncryption = new SymmetricTreezorEncryption();

    await asymmetricTreezorEncryption.init();
    await symmetricTreezorEncryption.init();

    const supportUserKeyPair: IKeyPair = await asymmetricTreezorEncryption.generateKeyPair();

    const encrrptedGlobalSupportPrivateKey = await asymmetricTreezorEncryption.encryptKey(
      globalSupportPrivateKey as string,
      supportUserKeyPair.public as string,
    );

    const encrrptedPrivateKey = await symmetricTreezorEncryption.encryptKey(
      supportUserKeyPair.private as string,
      passphrase,
    );

    return {
      publicKey: supportUserKeyPair.public,
      encryptedGlobalSupportPrivateKey: {
        withUserPublicKey: encrrptedGlobalSupportPrivateKey,
      },
      encryptedPrivateKey: {
        withUserSecret: encrrptedPrivateKey,
      },
    } as ISupportUserKeysRequest;
  },
};

export { KeysService, CreateKeysService };
