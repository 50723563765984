import { createReducer } from 'typesafe-actions';

import { IOnboardingSupportUser, IOrganization } from '@models/user.model';
import { UserAction, UserActionTypes, UserState } from '@store/user/user.types';

const initialState: UserState = {
  currentUser: {},
  inProgress: false,
  organization: undefined,
};

const userReducer = createReducer<UserState, UserAction>(initialState)
  .handleType(UserActionTypes.CREATE, (state, action) => ({
    ...state,
    currentUser: action.currentUser,
  }))
  .handleType(UserActionTypes.REMOVE, (state) => ({
    ...state,
    currentUser: undefined,
  }))
  .handleType(UserActionTypes.RESET, (state, action) => ({
    ...state,
    ...initialState,
  }))
  .handleType(UserActionTypes.ORGANIZATION_GET_REQUEST, (state) => ({
    ...state,
    inProgress: true,
  }))
  .handleType(UserActionTypes.ORGANIZATION_GET_SUCESS, (state, action) => ({
    ...state,
    organization: action.organization,
    inProgress: false,
  }))
  .handleType(UserActionTypes.ORGANIZATION_GET_FAILURE, (state, action) => ({
    ...state,
    inProgress: false,
    error: action.error,
  }))
  .handleType(UserActionTypes.SUPPORT_USER_POST_REQUEST, (state) => ({
    ...state,
    inProgress: true,
  }))
  .handleType(UserActionTypes.SUPPORT_USER_POST_SUCESS, (state, action) => ({
    ...state,
    organization: {
      ...(state.organization || ({} as IOrganization)),
      supportUsers: [...(state.organization?.supportUsers || []), action.supportUser],
    },
    inProgress: false,
  }))
  .handleType(UserActionTypes.SUPPORT_USER_POST_FAILURE, (state, action) => ({
    ...state,
    inProgress: false,
    error: action.error,
  }))
  .handleType(UserActionTypes.SUPPORT_USER_PUT_REQUEST, (state) => ({
    ...state,
    inProgress: true,
  }))
  .handleType(UserActionTypes.SUPPORT_USER_PUT_SUCESS, (state, action) => ({
    ...state,
    ...state,
    organization: {
      ...(state.organization || ({} as IOrganization)),
      supportUsers: state.organization?.supportUsers?.map((supportUser) => {
        if (supportUser.supportUserId !== action.supportUser.supportUserId) return supportUser;
        return action.supportUser;
      }) as IOnboardingSupportUser[],
    },
    inProgress: false,
  }))
  .handleType(UserActionTypes.SUPPORT_USER_PUT_FAILURE, (state, action) => ({
    ...state,
    inProgress: false,
    error: action.error,
  }));

export { userReducer };
