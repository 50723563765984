import CompleteFileModal from '@components/CompleteFileModal';
import CreaetFileModal from '@components/CreateFileModal';
import DocumentPreviewModal from '@components/DocumentPreviewModal';
import LinkFileModal from '@components/LinkFileModal';
import UploadFileModal from '@components/UploadFileModal';
import useToggle from '@hooks/useToggle';
import { IAgentStep, IDocument } from '@models/agent.model';
import { default as React, forwardRef, useImperativeHandle } from 'react';

export interface DocumentModalsHandles {
  togglePreview: () => void;
  toggleUpload: () => void;
  toggleGenerate: () => void;
  toggleCreate: () => void;
  toggleLink: () => void;
}

interface DocumentModalsProps {
  step: IAgentStep;
  document: IDocument;
  onUploadFile: (file: File) => void;
  onLink: (url: string) => void;
  send: (type: any, params?: any) => void;
}

const DocumentModals = forwardRef<DocumentModalsHandles, DocumentModalsProps>(
  ({ step, document, onLink, onUploadFile, send }, ref) => {
    const [isOpenUploadFileModal, toggleUploadFileModal] = useToggle(false);
    const [isOpenCompleteFileModal, toggleCompleteFileModal] = useToggle(false);
    const [isOpenCreateFileModal, toggleCreateFileModal] = useToggle(false);
    const [isOpenDocumentPreviewModal, toggleDocumentPreviewModal] = useToggle(false);
    const [isOpenLinkModal, toggleLinkModal] = useToggle(false);

    useImperativeHandle(ref, () => ({
      togglePreview: toggleDocumentPreviewModal,
      toggleUpload: toggleUploadFileModal,
      toggleGenerate: toggleCompleteFileModal,
      toggleCreate: toggleCreateFileModal,
      toggleLink: toggleLinkModal,
    }));

    const handleUpload = (file: File) => {
      onUploadFile(file);
      toggleUploadFileModal();
    };

    const handleCreate = (blob: Blob) => {
      onUploadFile(blob as File);
      toggleCreateFileModal();
    };

    const handleComplete = (blob: Blob) => {
      onUploadFile(blob as File);
      toggleCompleteFileModal();
    };

    const handleLink = (url: string) => {
      onLink(url);
      toggleLinkModal();
    };

    return (
      <>
        {document.permission?.upload && (
          <UploadFileModal isOpen={isOpenUploadFileModal} toggle={toggleUploadFileModal} handleUpload={handleUpload} />
        )}

        {document.permission?.preview && (
          <DocumentPreviewModal
            isOpen={isOpenDocumentPreviewModal}
            toggle={toggleDocumentPreviewModal}
            document={document}
            send={send}
            step={step}
          />
        )}

        {document.permission?.edit && document.type === 'GENERATE' && (
          <CompleteFileModal
            isOpen={isOpenCompleteFileModal}
            toggle={toggleCompleteFileModal}
            document={document}
            handleGenerate={handleComplete}
          />
        )}

        {document.permission?.edit && document.type === 'CREATE' && (
          <CreaetFileModal
            isOpen={isOpenCreateFileModal}
            toggle={toggleCreateFileModal}
            document={document}
            handleCreate={handleCreate}
          />
        )}

        {document.permission?.link && (
          <LinkFileModal
            isOpen={isOpenLinkModal}
            toggle={toggleLinkModal}
            document={document}
            handleLink={handleLink}
          />
        )}
      </>
    );
  },
);

export default DocumentModals;
