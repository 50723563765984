import { useCallback, useRef, useState } from 'react';
import { interpret, Machine, Service } from 'robot3';

const useMachine = (machine: Machine, initialContext = {}) => {
  // On crée une nouvelle instance de la machine
  const ref = useRef<Service<any>>();

  if (!ref.current) {
    ref.current = interpret(
      machine,
      () => {
        setState(service?.machine.current);
        setContext(service?.context);
      },
      initialContext,
    );
  }
  const service = ref.current;

  // On stocke le context & l'état de la machine dans l'état react
  const [state, setState] = useState(service?.machine.current);
  const [context, setContext] = useState(service?.context);

  // Permet de demander une transition
  const send = useCallback(
    function (type, params = {}) {
      service?.send({ type, ...params });
    },
    [service],
  );

  const actions = useCallback(() => {
    const transitions: Map<string, any> = service?.machine.state.value.transitions;
    return Array.from(transitions.keys());
  }, [service]);

  // Vérifie si une transition est possible depuis l'état courant
  const can = useCallback(
    (transitionName) => {
      const transitions = service?.machine.state.value.transitions;
      if (!transitions.has(transitionName)) {
        return false;
      }
      const transitionsForName = transitions.get(transitionName);
      for (const t of transitionsForName) {
        if ((t.guards && t.guards(service?.context)) || !t.guards) {
          return true;
        }
      }
      return false;
    },
    [service],
  );

  return { state, context, send, can };
};

export default useMachine;
