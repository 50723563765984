import React from 'react';

import moment from 'moment';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import { AppState } from '@store/configuration';
import {
  FaEnvelope,
  FaFacebook,
  FaFlag,
  FaGifts,
  FaGlobeEurope,
  FaHome,
  FaLinkedin,
  FaPhone,
  FaTwitter,
  FaUser,
  FaUserTag,
} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { t } = useTranslation();

  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);

  const AboutCompany = () => (
    <Card style={{ minHeight: 200, borderRadius: 10 }}>
      <CardContent>
        <Grid container direction="row" alignItems="center">
          <Grid item style={{ alignSelf: 'flex-start' }}>
            <FaHome />
          </Grid>
          <Grid item>
            <div>{currentAgent?.company?.profile?.address?.line1}</div>
            <div>{currentAgent?.company?.profile?.address?.line2}</div>
            <div>
              {currentAgent?.company?.profile?.address?.zipCode} {currentAgent?.company?.profile?.address?.city}
            </div>
            <div>{currentAgent?.company?.profile?.address?.country}</div>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" style={{ marginTop: 20 }}>
          <Grid item>SIREN/SIRET: </Grid>
          <Grid item>{currentAgent?.company?.profile?.siret}</Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const AboutLegalRepresentant = () => (
    <Card style={{ minHeight: 200, borderRadius: 10 }}>
      <CardContent>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <FaUser />
          </Grid>
          <Grid item>
            {currentAgent?.legalRepresentant?.firstname} {currentAgent?.legalRepresentant?.lastname}
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <FaUserTag />
          </Grid>
          <Grid item>{currentAgent?.legalRepresentant?.role}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <FaGifts />
          </Grid>
          <Grid item>
            {currentAgent?.legalRepresentant?.birthday
              ? moment(currentAgent?.legalRepresentant?.birthday).format('L')
              : ''}
            {currentAgent?.legalRepresentant?.placeOfBirth || ''}
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <FaFlag />
          </Grid>
          <Grid item>{currentAgent?.legalRepresentant?.nationality}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <FaEnvelope />
          </Grid>
          <Grid item>{currentAgent?.legalRepresentant?.email}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <FaPhone />
          </Grid>
          <Grid item>{currentAgent?.legalRepresentant?.phone}</Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const NetworkSocials = () => (
    <Card style={{ minHeight: 200, borderRadius: 10 }}>
      <CardContent>
        <Grid container direction="row">
          <Grid>
            <FaGlobeEurope />
          </Grid>
          {currentAgent?.company?.profile?.website && (
            <Grid>
              <a href={currentAgent?.company?.profile?.website as string} target="_blank" rel="noopener noreferrer">
                {currentAgent?.company?.profile?.website}
              </a>
            </Grid>
          )}
        </Grid>
        <Grid container direction="row">
          <Grid>
            <FaFacebook />
          </Grid>
          {currentAgent?.company?.profile?.facebook && (
            <Grid>
              <a href={currentAgent?.company?.profile?.facebook as string} target="_blank" rel="noopener noreferrer">
                {currentAgent?.company?.profile?.facebook}
              </a>
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <FaTwitter />
          </Grid>
          {currentAgent?.company?.profile?.twitter && (
            <Grid>
              <a href={currentAgent?.company?.profile?.twitter as string} target="_blank" rel="noopener noreferrer">
                {currentAgent?.company?.profile?.twitter}
              </a>
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <FaLinkedin />
          </Grid>
          {currentAgent?.company?.profile?.linkedin && (
            <Grid>
              <a href={currentAgent?.company?.profile?.linkedin as string} target="_blank" rel="noopener noreferrer">
                {currentAgent?.company?.profile?.linkedin}
              </a>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Grid container direction="row" className="profile-page">
      <Grid item style={{ padding: 16, width: '40%' }}>
        <Typography variant="h6" style={{ textTransform: 'uppercase', fontWeight: 800 }} gutterBottom>
          {t('pages.profile.legalRepresentative.title')}
        </Typography>
        <AboutLegalRepresentant />
      </Grid>
      <Grid item style={{ padding: 16, width: '30%' }}>
        <Typography variant="h6" style={{ textTransform: 'uppercase', fontWeight: 800 }} gutterBottom>
          {currentAgent?.company?.profile?.name}
        </Typography>
        <AboutCompany />
      </Grid>
      <Grid item style={{ padding: 16, width: '30%' }}>
        <Typography variant="h6" style={{ textTransform: 'uppercase', fontWeight: 800 }} gutterBottom>
          {t('pages.profile.links.title')}
        </Typography>
        <NetworkSocials />
      </Grid>
    </Grid>
  );
};

export default Profile;
