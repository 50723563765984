import { removeCurrentAgent } from '@store/agent/agent.action';
import { AppState } from '@store/configuration';
import { changeSideTab } from '@store/core/core.action';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaCertificate,
  FaFolder,
  FaHome,
  FaUsers,
  FaUsersCog,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Nav } from 'reactstrap';
import { Config } from '../../config';
import NavItem, { NavItemProps } from './NavItem';

interface ILayoutSidebarProps extends React.HTMLProps<HTMLUListElement> {
  isExpand: boolean;
  toggleExpand: any;
}

const LayoutSidebar: FunctionComponent<ILayoutSidebarProps> = ({ className, toggleExpand, isExpand }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [navItems, setNavItems] = useState<NavItemProps[]>([]);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const activeTab = useSelector((state: AppState) => state.core.activeTab);
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);

  useEffect(() => {
    if (!currentUser) return;
    if (currentUser.type === 'user') {
      if (!currentAgent) return;
      if (currentAgent?.status === 'PRE_PROSPECT') return;
      const items = [
        {
          href: '/prospect',
          icon: <FaHome />,
          label: t('core.sidebar.prospect'),
          selected: history.location.pathname === '/prospect',
        },
        {
          href: '/documents',
          icon: <FaFolder />,
          label: t('core.sidebar.documents'),
          selected: history.location.pathname === '/documents',
        },
        // { href: '/meetings', icon: <FaCalendar />, label: t('core.sidebar.meetings'), selected: history.location.pathname === '/meetings' },
        // { href: '/formations', icon: <FaGraduationCap />, label: t('core.sidebar.formations'), selected: history.location.pathname === '/formations' },
      ] as NavItemProps[];

      if (Config.app.useQualification)
        items.push({
          href: '/qualification',
          icon: <FaCertificate />,
          label: t('core.sidebar.qualification'),
          selected: history.location.pathname === '/qualification',
        } as NavItemProps);

      setNavItems(items);
    } else {
      setNavItems([
        {
          href: '/support',
          icon: <FaUsers />,
          label: t('core.sidebar.support'),
          selected: history.location.pathname === '/support',
        },
        {
          href: '/admin',
          icon: <FaUsersCog />,
          label: t('core.sidebar.admin'),
          selected: history.location.pathname === '/admin',
        },
      ] as NavItemProps[]);
    }
  }, [currentUser, t, history, currentAgent]);

  useEffect(() => {
    if (activeTab && navItems.length)
      setNavItems(
        navItems.map((navItem) => ({
          ...navItem,
          selected: activeTab === navItem.href,
        })),
      );
  }, [activeTab]);

  const navigateTo = (href: string) => {
    if (href === '/support') {
      dispatch(removeCurrentAgent());
    }

    dispatch(changeSideTab(href));
    history.push(href);
  };

  return navItems.length ? (
    <Nav className={className} vertical>
      {navItems.map((navItem, index) =>
        isExpand ? (
          <NavItem
            navigateTo={navigateTo}
            selected={navItem.selected}
            key={index}
            href={navItem.href}
            icon={navItem.icon}
            label={navItem.label}
          />
        ) : (
          <NavItem
            navigateTo={navigateTo}
            selected={navItem.selected}
            key={index}
            href={navItem.href}
            icon={navItem.icon}
          />
        ),
      )}
      <div className="btn-expand">
        {isExpand ? (
          <Button>
            <FaAngleDoubleLeft onClick={() => toggleExpand()} />
          </Button>
        ) : (
          <Button>
            <FaAngleDoubleRight onClick={() => toggleExpand()} />
          </Button>
        )}
      </div>
    </Nav>
  ) : (
    <></>
  );
};

export default LayoutSidebar;
