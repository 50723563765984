import { AgentStatus, IAgent } from '@models/agent.model';

export enum AgentActionTypes {
  CREATE_AGENT_REQUEST = 'CREATE_AGENT_REQUEST',
  CREATE_AGENT_SUCCESS = 'CREATE_AGENT_SUCCESS',
  CREATE_AGENT_FAILURE = 'CREATE_AGENT_FAILURE',

  FETCH_AGENT_REQUEST = 'FETCH_AGENT_REQUEST',
  FETCH_AGENT_SUCCESS = 'FETCH_AGENT_SUCCESS',
  FETCH_AGENT_FAILURE = 'FETCH_AGENT_FAILURE',

  UPDATE_AGENT_REQUEST = 'UPDATE_AGENT_REQUEST',
  UPDATE_AGENT_SUCCESS = 'UPDATE_AGENT_SUCCESS',
  UPDATE_AGENT_FAILURE = 'UPDATE_AGENT_FAILURE',

  FETCH_ALL_AGENTS_REQUEST = 'FETCH_ALL_AGENTS_REQUEST',
  FETCH_ALL_AGENTS_SUCCESS = 'FETCH_ALL_AGENTS_SUCCESS',
  FETCH_ALL_AGENTS_FAILURE = 'FETCH_ALL_AGENTS_FAILURE',

  SET_AGENT = 'SET_AGENT',
  REMOVE_CURRENT_AGENT = 'REMOVE_CURRENT_AGENT',

  RESET = 'RESET_AGENT',
  RESET_STATUS = 'RESET_STATUS',

  REMOVE_AGENT_REQUEST = 'REMOVE_AGENT_REQUEST',
  REMOVE_AGENT_SUCCESS = 'REMOVE_AGENT_SUCCESS',
  REMOVE_AGENT_FAILURE = 'REMOVE_AGENT_FAILURE',
}

/* CREATE TYPES */
export interface CreateAgentRequestAction {
  type: typeof AgentActionTypes.CREATE_AGENT_REQUEST;
}
export interface CreateAgentSuccessAction {
  type: typeof AgentActionTypes.CREATE_AGENT_SUCCESS;
  agent: IAgent;
}
export interface CreateAgentFailureAction {
  type: typeof AgentActionTypes.CREATE_AGENT_FAILURE;
  error: any;
}

/* FETCH TYPES */
export interface FetchAgentRequestAction {
  type: typeof AgentActionTypes.FETCH_AGENT_REQUEST;
}
export interface FetchAgentSuccessAction {
  type: typeof AgentActionTypes.FETCH_AGENT_SUCCESS;
  agent: IAgent;
}
export interface FetchAgentFailureAction {
  type: typeof AgentActionTypes.FETCH_AGENT_FAILURE;
  error: any;
}

/* UPDATE TYPES */
export interface UpdateAgentRequestAction {
  type: typeof AgentActionTypes.UPDATE_AGENT_REQUEST;
}
export interface UpdateAgentSuccessAction {
  type: typeof AgentActionTypes.UPDATE_AGENT_SUCCESS;
  agent: IAgent;
}
export interface UpdateAgentFailureAction {
  type: typeof AgentActionTypes.UPDATE_AGENT_FAILURE;
  error: any;
}

/* FETCH ALL TYPES */
export interface FetchAllAgentsRequestAction {
  type: typeof AgentActionTypes.FETCH_ALL_AGENTS_REQUEST;
}
export interface FetchAllAgentsSuccessAction {
  type: typeof AgentActionTypes.FETCH_ALL_AGENTS_SUCCESS;
  status: AgentStatus;
  agents: IAgent[];
}
export interface FetchAllAgentsFailureAction {
  type: typeof AgentActionTypes.FETCH_ALL_AGENTS_FAILURE;
  error: any;
}

export interface SetAgentAction {
  type: typeof AgentActionTypes.SET_AGENT;
  agent: IAgent;
}

export interface RemoveCurrentAgentAction {
  type: typeof AgentActionTypes.REMOVE_CURRENT_AGENT;
}

export interface ResetLoadingAction {
  type: typeof AgentActionTypes.RESET_STATUS;
}

export interface ResetAction {
  type: typeof AgentActionTypes.RESET;
}

/* REMOVE TYPES */
export interface RemoveAgentRequestAction {
  type: typeof AgentActionTypes.REMOVE_AGENT_REQUEST;
}
export interface RemoveAgentSuccessAction {
  type: typeof AgentActionTypes.REMOVE_AGENT_SUCCESS;
  agentId: string;
}
export interface RemoveAgentFailureAction {
  type: typeof AgentActionTypes.REMOVE_AGENT_FAILURE;
  error: any;
}

export type AgentAction =
  | SetAgentAction
  | RemoveCurrentAgentAction
  | CreateAgentRequestAction
  | CreateAgentSuccessAction
  | CreateAgentFailureAction
  | FetchAgentRequestAction
  | FetchAgentSuccessAction
  | FetchAgentFailureAction
  | UpdateAgentRequestAction
  | UpdateAgentSuccessAction
  | UpdateAgentFailureAction
  | FetchAllAgentsRequestAction
  | FetchAllAgentsSuccessAction
  | FetchAllAgentsFailureAction
  | ResetAction
  | ResetLoadingAction
  | RemoveAgentRequestAction
  | RemoveAgentSuccessAction
  | RemoveAgentFailureAction;

export interface AgentState {
  currentAgent?: IAgent;
  allAgents: Record<AgentStatus, IAgent[]>;
  status: {
    fetch: {
      inProgress: boolean;
      done: boolean;
    };
    create: {
      inProgress: boolean;
      done: boolean;
    };
    update: {
      inProgress: boolean;
      done: boolean;
    };
    remove: {
      inProgress: boolean;
      done: boolean;
    };
  };
  error?: any;
}
