import treezorLogo from '@assets/images/logo-treezor.svg';

export const Config = {
  support: {
    name: 'Treezor',
    logo: treezorLogo,
  },

  app: {
    enableMultipleAgents: false,
    enrichOverview: true,
    useQualification: false,
  },

  api: {
    base: 'https://trzonboarding.api.treezor.co',
    coreOnboarding: 'https://trzonboarding.api.treezor.co/core-onboarding',
    keyManagement: 'https://trzonboarding.api.treezor.co/key-management',
  },

  bucket: {
    base: 'https://sbx-trzonboarding-agent.s3.eu-west-3.amazonaws.com',
    name: 'sbx-trzonboarding-agent',
    region: 'eu-west-3',
    identityPoolId: 'eu-west-3:ea86fdc4-1f28-4b5a-be40-f891b6e9bf77',
    templatesFolder: 'common',
  },

  oauth: {
    base: 'https://trzonboarding.api.treezor.co/oauth',
    clientId: '887357f2-ea07-47d7-b000-7a6100f762db',
    dashboardClientId: '03ca57d5-ee2b-41b9-aa02-8396a93a98c5',
    redirectURI: 'https://onboarding-treezor.api.treezor.co/login',
  },
};
