import { onBoardingSteps } from '@assets/data/onBoardingSteps';
import { IInput, UIForm } from '@components/form/Form';
import Spinner from '@components/ui/Spinner';
import {
  Avatar,
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  LinearProgressProps,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ICompanyInfo, IAgentOverview, IContact, IStep, IPersonalInfo } from '@models/agent.model';
import { IOnboardingSupportUser } from '@models/user.model';
import { fetchAgent, fetchAgentByCurrentUser, toggleStep, updateAgent } from '@store/agent/agent.action';
import { AWSService } from '@store/agent/agent.service';
import { AppState } from '@store/configuration';
import { Colors } from '@tools/constants';
import { ProspectUtils } from '@tools/prospect.utils';
import * as _ from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaEnvelope, FaPhone, FaTimes, FaUser } from 'react-icons/fa';
import { Document, Page } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import { Config } from '../config';
interface OverviewProps {
  setActiveTab?: (tab: number) => void;
}

const styles: { [index: string]: CSSProperties } = {
  form: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%' },
  col2: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '45%',
    maxWidth: '45%',
    minWidth: '45%',
    margin: 10,
  },
};

const Overview: FunctionComponent<OverviewProps> = ({ setActiveTab }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const agentId = useSelector((state: AppState) => state.agent.currentAgent?.agentId);
  const overview = useSelector((state: AppState) => state.agent.currentAgent?.overview);
  const steps = useSelector((state: AppState) => state.agent.currentAgent?.steps);
  const notifications = useSelector((state: AppState) => state.agent.currentAgent?.notifications);
  const legalRepresentant = useSelector((state: AppState) => state.agent.currentAgent?.legalRepresentant);
  const fetchStatus = useSelector((state: AppState) => state.agent.status.fetch);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);
  const organization = useSelector((state: AppState) => state.user.organization);

  const [edit, setEdit] = useState<boolean>(false);
  const [supportUsers, setSupportUsers] = useState<IOnboardingSupportUser[]>(
    overview?.supportUsers as IOnboardingSupportUser[],
  );
  const [onboardingInfo, setOnboardingInfo] = useState<IPartialProspectInfo>();
  const [numPages, setNumPages] = useState<number | null>(null);

  const toggleEdit = () => setEdit(!edit);

  useEffect(() => {
    if (currentUser?.type === 'support-user') return;

    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.has('agentId')) dispatch(fetchAgent(searchParams.get('agentId') as string));
    else if (agentId) dispatch(fetchAgent(agentId as string));
    else dispatch(fetchAgentByCurrentUser());
  }, []);

  useEffect(() => {
    if (currentAgent?.status === 'PRE_PROSPECT') return history.push('/pre-prospect');
  }, [currentAgent, history]);

  const overviewInputs = useMemo<IInput[]>(() => {
    if (!Config.app.enrichOverview)
      return [
        {
          name: 'main.regulatoryStatus',
          label: t('pages.overview.main.regulatoryStatus.label'),
          radio: {
            options: [
              { label: t('pages.overview.main.regulatoryStatus.status.IN_PROGRESS'), value: 'IN_PROGRESS' },
              { label: t('pages.overview.main.regulatoryStatus.status.SUBMITTED'), value: 'SUBMITTED' },
              { label: t('pages.overview.main.regulatoryStatus.status.VALIDATED'), value: 'VALIDATED' },
              { label: t('pages.overview.main.regulatoryStatus.status.REFUSED'), value: 'REFUSED' },
              { label: t('pages.overview.main.regulatoryStatus.status.RESILIED'), value: 'RESILIED' },
            ],
          },
        },
        { name: 'main.agentNumber', label: t('pages.overview.main.agentNumber.label') },
        { name: 'kpis.submissionAcprDate', label: t('pages.overview.kpis.submissionAcprDate.label'), type: 'date' },
        {
          name: 'kpis.regulatorRegistrationDate',
          label: t('pages.overview.kpis.regulatorRegistrationDate.label'),
          type: 'date',
        },
      ] as IInput[];

    return [
      {
        name: 'main.regulatoryStatus',
        label: t('pages.overview.main.regulatoryStatus.label'),
        radio: {
          options: [
            { label: t('pages.overview.main.regulatoryStatus.status.IN_PROGRESS'), value: 'IN_PROGRESS' },
            { label: t('pages.overview.main.regulatoryStatus.status.SUBMITTED'), value: 'SUBMITTED' },
            { label: t('pages.overview.main.regulatoryStatus.status.VALIDATED'), value: 'VALIDATED' },
            { label: t('pages.overview.main.regulatoryStatus.status.REFUSED'), value: 'REFUSED' },
            { label: t('pages.overview.main.regulatoryStatus.status.RESILIED'), value: 'RESILIED' },
          ],
        },
      },
      { name: 'main.agentNumber', label: t('pages.overview.main.agentNumber.label') },
      {
        name: 'main.technicalStatus',
        label: t('pages.overview.main.technicalStatus.label'),
        radio: {
          options: [
            { label: t('pages.overview.main.technicalStatus.status.SANDBOX'), value: 'SANDBOX' },
            { label: t('pages.overview.main.technicalStatus.status.ALPHA'), value: 'ALPHA' },
            { label: t('pages.overview.main.technicalStatus.status.BETA'), value: 'BETA' },
            { label: t('pages.overview.main.technicalStatus.status.PRODUCTION'), value: 'PRODUCTION' },
            { label: t('pages.overview.main.technicalStatus.status.STOPPED'), value: 'STOPPED' },
          ],
        },
      },
      { name: 'main.clientId', label: t('pages.overview.main.clientId.label') },
      { name: 'main.rppNumber', label: t('pages.overview.main.rppNumber.label'), list: true },
      { name: 'main.cardPrintNumber', label: t('pages.overview.main.cardPrintNumber.label'), list: true },
      { name: 'main.binNumber', label: t('pages.overview.main.binNumber.label'), list: true },
      { name: 'main.midNumber', label: t('pages.overview.main.midNumber.label') },
      { name: 'main.icsNumber', label: t('pages.overview.main.icsNumber.label') },
      {
        name: 'kpis.regulatorRegistrationDate',
        label: t('pages.overview.kpis.regulatorRegistrationDate.label'),
        type: 'date',
      },
      { name: 'kpis.startOnboardingDate', label: t('pages.overview.kpis.startOnboardingDate.label'), type: 'date' },
      { name: 'kpis.signLoiDate', label: t('pages.overview.kpis.signLoiDate.label'), type: 'date' },
      { name: 'kpis.accessSandboxDate', label: t('pages.overview.kpis.accessSandboxDate.label'), type: 'date' },
      { name: 'kpis.startAlphaDate', label: t('pages.overview.kpis.startAlphaDate.label'), type: 'date' },
      { name: 'kpis.startBetaDate', label: t('pages.overview.kpis.startBetaDate.label'), type: 'date' },
      { name: 'kpis.launchProdDate', label: t('pages.overview.kpis.launchProdDate.label'), type: 'date' },
      { name: 'kpis.submissionAcprDate', label: t('pages.overview.kpis.submissionAcprDate.label'), type: 'date' },
    ];
  }, [t]);

  const onboardingSteps: Record<number, IStep> = onBoardingSteps;

  interface IPartialProspectInfo {
    company: Partial<ICompanyInfo>;
    personalInfo: Partial<IPersonalInfo>;
    pitchDeck?: string;
  }

  useEffect(() => {
    if (!currentAgent || currentAgent.status === 'PRE_PROSPECT') return;

    (async () => {
      const infoValue = await Object.keys(onboardingSteps).reduce<Promise<IPartialProspectInfo>>(
        async (_acc, key) => {
          const { name, type } = onboardingSteps[+key].input;
          const label = t(onboardingSteps[+key].question);
          const value = _.get(currentAgent, name);
          let acc = await _acc;

          if (!acc.company || !acc.personalInfo) throw new Error('invalid reduce params');

          if (name.includes('pitchDeck')) {
            acc.pitchDeck =
              onboardingInfo?.pitchDeck ??
              (currentAgent.company?.pitchDeck && currentAgent.agentId
                ? await AWSService.getPresignedFile('get', currentAgent.agentId)
                : '');
          } else if (
            (name.includes('company') && !name.includes('company.profile')) ||
            name.includes('additionalInfo')
          ) {
            acc.company[name as keyof ICompanyInfo] = ProspectUtils.getFormattedInfo(
              label,
              ProspectUtils.getValue(type, value, +key, onboardingSteps, t),
            );
          } else {
            acc.personalInfo[name as keyof IPersonalInfo] = ProspectUtils.getFormattedInfo(label, value);
          }
          return acc;
        },
        Promise.resolve<IPartialProspectInfo>({
          company: {},
          personalInfo: {},
        }),
      );
      setOnboardingInfo(infoValue);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgent, t]);

  const updateOverview = (overviewValues: IAgentOverview) => {
    dispatch(
      updateAgent({
        agentId: agentId,
        overview: {
          ...overviewValues,
          supportUsers,
        },
      }),
    );
    toggleEdit();
  };

  const goToDocument = (step: string, document: string) => {
    if (currentUser?.type === 'user') history.push('/documents', { step, document });
    else {
      if (setActiveTab) {
        setActiveTab(1);
        setTimeout(() => {
          dispatch(toggleStep(step, true));
        }, 1000);
      }
    }
  };

  const OverviewContact = ({ contact }: { contact: IContact }) => (
    <div className="card-values">
      <div>
        <FaUser />
        <div>{[contact?.firstname, contact?.lastname].join(' ') || '-'}</div>
      </div>

      <div>
        <FaEnvelope />
        <div>{contact?.email || contact?.username || '-'}</div>
      </div>

      <div>
        <FaPhone />
        <div>{contact.phone || '-'}</div>
      </div>
    </div>
  );

  const CustomLinearProgress: FunctionComponent<
    LinearProgressProps & { value: number; label: string; start: number }
  > = (props) => {
    return (
      <Box display="flex" style={{ marginBottom: 10, marginTop: 10, alignItems: 'center' }}>
        <Box style={{ width: '30%', marginLeft: 20 }}>
          <Typography variant="body1" style={{ fontWeight: 400 }}>
            {props.label}
          </Typography>
        </Box>
        <Box style={{ width: '35%' }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box style={{ width: '5%', marginLeft: 10 }}>
          <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
        </Box>
        <Box style={{ textAlign: 'center', width: '30%' }}>
          {props.start !== Number.MAX_VALUE ? (
            <Typography variant="body2" align="center">
              {t('pages.overview.kpis.startedOn')} {moment(props.start).format('L')} (
              {moment(props.start).locale(i18n.language).fromNow()})
            </Typography>
          ) : (
            <Typography variant="body2" align="center">
              -
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  const Overviewinformations = () => (
    <div className="overview-container informations">
      <div className="title">{t('pages.overview.main.label')}</div>
      <div className="card">
        <div className="column-left">
          <div className="card-info">
            <div className="card-label">{t('pages.overview.main.regulatoryStatus.label')}</div>
            <div>{t(`pages.overview.main.regulatoryStatus.status.${overview?.main?.regulatoryStatus}`)}</div>
          </div>
          <div className="card-info">
            <div className="card-label">{t('pages.overview.main.agentNumber.label')}</div>
            <div>{overview?.main?.agentNumber || '-'}</div>
          </div>
          {Config.app.enrichOverview && (
            <>
              <div className="card-info">
                <div className="card-label">{t('pages.overview.main.technicalStatus.label')}</div>
                <div>{overview?.main?.technicalStatus || '-'}</div>
              </div>
              <div className="card-info">
                <div className="card-label">{t('pages.overview.main.midNumber.label')}</div>
                <div>{overview?.main?.midNumber || '-'}</div>
              </div>
              <div className="card-info">
                <div className="card-label">{t('pages.overview.main.icsNumber.label')}</div>
                <div>{overview?.main?.icsNumber || '-'}</div>
              </div>
              <div className="card-info">
                <div className="card-label">{t('pages.overview.main.clientId.label')}</div>
                <div>{overview?.main?.clientId || '-'}</div>
              </div>
              <div className="card-info">
                <div className="card-label">{t('pages.overview.main.binNumber.label')}</div>
                <div>{overview?.main?.binNumber?.join(',') || '-'}</div>
              </div>
              <div className="card-info">
                <div className="card-label">{t('pages.overview.main.rppNumber.label')}</div>
                <div>{overview?.main?.rppNumber?.join(',') || '-'}</div>
              </div>
              <div className="card-info">
                <div className="card-label">{t('pages.overview.main.cardPrintNumber.label')}</div>
                <div>{overview?.main?.cardPrintNumber?.join(',') || '-'}</div>
              </div>
            </>
          )}
        </div>
        <div className="column-right">
          <div className="card-info">
            <div className="card-label">{t('pages.overview.kpis.submissionAcprDate.label')}</div>
            <div className="card-values">
              {overview?.kpis?.submissionAcprDate
                ? moment(overview.kpis?.submissionAcprDate as number).format('L')
                : '--/--/--'}
            </div>
          </div>
          <div className="card-info">
            <div className="card-label">{t('pages.overview.kpis.regulatorRegistrationDate.label')}</div>
            <div className="card-values">
              {overview?.kpis?.regulatorRegistrationDate
                ? moment(overview.kpis?.regulatorRegistrationDate as number).format('L')
                : '--/--/--'}
            </div>
          </div>
          {Config.app.enrichOverview && (
            <>
              <div className="card-info">
                <div className="card-label">{t('pages.overview.kpis.startOnboardingDate.label')}</div>
                <div className="card-values">
                  {overview?.kpis?.startOnboardingDate
                    ? moment(overview.kpis?.startOnboardingDate as number).format('L')
                    : '--/--/--'}
                </div>
              </div>

              <div className="card-info">
                <div className="card-label">{t('pages.overview.kpis.signLoiDate.label')}</div>
                <div className="card-values">
                  {overview?.kpis?.signLoiDate ? moment(overview.kpis?.signLoiDate as number).format('L') : '--/--/--'}
                </div>
              </div>

              <div className="card-info">
                <div className="card-label">{t('pages.overview.kpis.startAlphaDate.label')}</div>
                <div className="card-values">
                  {overview?.kpis?.startAlphaDate
                    ? moment(overview.kpis?.startAlphaDate as number).format('L')
                    : '--/--/--'}
                </div>
              </div>

              <div className="card-info">
                <div className="card-label">{t('pages.overview.kpis.startBetaDate.label')}</div>
                <div className="card-values">
                  {overview?.kpis?.startBetaDate
                    ? moment(overview.kpis?.startBetaDate as number).format('L')
                    : '--/--/--'}
                </div>
              </div>

              <div className="card-info">
                <div className="card-label">{t('pages.overview.kpis.launchProdDate.label')}</div>
                <div className="card-values">
                  {overview?.kpis?.launchProdDate
                    ? moment(overview.kpis?.launchProdDate as number).format('L')
                    : '--/--/--'}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );

  const OverviewContacts = () => (
    <div className="contacts">
      <div className="overview-container">
        <div className="title">{t('pages.overview.contacts.supportUsers')}</div>
        <div className="card">
          {overview?.supportUsers?.map((supportUser) => (
            <div key={supportUser.onboardingSupportUserId} className="card-contact">
              <div className="card-label">{t(`pages.overview.contacts.role.${supportUser.role}`)}</div>
              <OverviewContact contact={{ ...supportUser }} />
            </div>
          ))}
        </div>
      </div>

      <div className="overview-container">
        <div className="title">{t('pages.overview.contacts.agent')}</div>
        <div className="card">
          <div className="card-contact">
            <div className="card-label">{legalRepresentant?.role}</div>
            <OverviewContact contact={{ ...legalRepresentant, username: legalRepresentant?.email }} />
          </div>
        </div>
      </div>
    </div>
  );

  const OverviewStats = () => (
    <div className="overview-container">
      <div className="title">{t('pages.overview.kpis.label')}</div>
      <div className="card" style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
        {steps &&
          steps
            .filter((step) => step.process === currentAgent?.currentProcess && !step.disabled)
            .map((step) => (
              <CustomLinearProgress
                key={step.label}
                label={step.label as string}
                start={step.startFrom as number}
                value={
                  step.documents?.length
                    ? Math.round((((step.count?.done as number) * 100) / step.documents?.length) as number)
                    : 0
                }
              />
            ))}
      </div>
    </div>
  );

  const OverviewNotifications = () => (
    <div className="overview-container">
      <div className="title">{t('pages.overview.notifications.label')}</div>
      <div className="card">
        {notifications?.map((notif, index) => (
          <Card
            key={`${notif.document}-${index}`}
            className="notification"
            onClick={() => goToDocument(notif.step, notif.document)}>
            <div className="notification-header">
              <div>
                {notif.source === 'CLIENT' && <Avatar style={{ backgroundColor: Colors.source.agent }}>CL</Avatar>}
                {notif.source === 'SUPPORT' && <Avatar style={{ backgroundColor: Colors.source.support }}>N1</Avatar>}
                {notif.source === 'COMPLIANCE' && (
                  <Avatar style={{ backgroundColor: Colors.source.compliance }}>N2</Avatar>
                )}
                <span>{notif.step}</span>
              </div>
              <div>
                {moment().diff(moment(notif.date), 'hours') > 23
                  ? moment(notif.date).locale(i18n.language).format('LLL')
                  : moment(notif.date).locale(i18n.language).fromNow()}
              </div>
            </div>
            <div className="notification-content">
              <span className="document-title">{notif.document} : </span>
              {t('pages.overview.notifications.sentence').replace(
                '$action',
                t(`pages.overview.notifications.action.${notif.action}`),
              )}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const OverviewProspectInformation = () => (
    <div className="prospect-info ">
      <div className="overview-container">
        <div className="title">{t('pages.overview.prospectInfo.label')}</div>
        <div className="card-prospect">
          <div className="flex-item">
            <p className="label">{t('preprospect.form.email.question')} : </p>
            <p className="value">{currentAgent?.legalRepresentant?.email}</p>
          </div>
          {onboardingInfo?.personalInfo &&
            Object.keys(onboardingInfo?.personalInfo).map((key) => {
              return (
                <div className="flex-item" key={key}>
                  <span className="label">{onboardingInfo.personalInfo[key as keyof IPersonalInfo]?.label} : </span>
                  <span className="value">{onboardingInfo.personalInfo[key as keyof IPersonalInfo]?.value}</span>
                </div>
              );
            })}
        </div>
        <div className="card-prospect">
          <div className="flex-item">
            <p className="label">{t('preprospect.pitchDeck')}</p>
            <div className="valuePitch">
              {onboardingInfo?.pitchDeck && (
                <Document file={{ url: onboardingInfo?.pitchDeck }} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page height={300} renderInteractiveForms key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="overview-container company">
        <div className="title-company"></div>
        <div className="card-prospect">
          {onboardingInfo?.company &&
            Object.keys(onboardingInfo?.company).map((key: string) => {
              const companyInfo = onboardingInfo.company[key as keyof ICompanyInfo];
              return (
                companyInfo &&
                companyInfo.value &&
                companyInfo.value !== '' && (
                  <div className="flex-item" key={key}>
                    <span className="label">{companyInfo.label} : </span>
                    <span className="value">{companyInfo.value}</span>
                  </div>
                )
              );
            })}
        </div>
      </div>
    </div>
  );

  if (!overview) return <Spinner center />;

  if (!edit)
    return (
      <div className="overview">
        <div className="overview-left">
          {currentAgent?.status === 'AGENT' ? (
            <>
              <Overviewinformations />
              <OverviewContacts />
              <OverviewStats />
              <OverviewProspectInformation />
            </>
          ) : (
            <>
              <OverviewProspectInformation />
              <Overviewinformations />
              {currentUser?.type === 'support-user' && <OverviewContacts />}
              <OverviewStats />
            </>
          )}
        </div>
        <div className="overview-right">
          <OverviewNotifications />
        </div>

        {currentUser?.type === 'support-user' && (
          <div className="actions">
            <Button onClick={toggleEdit} color="primary">
              <FaEdit />
            </Button>
          </div>
        )}
      </div>
    );

  return (
    <Grid container justify="center">
      <FormControl variant="outlined" style={styles.col2}>
        <InputLabel id="support-users-select">{t('pages.overview.main.supportUsers.label')}</InputLabel>
        <Select
          defaultValue={
            _.uniqBy(overview.supportUsers, 'onboardingSupportUserId')?.map((supportUser) =>
              JSON.stringify(supportUser),
            ) || []
          }
          label={t('pages.overview.main.supportUsers.label')}
          labelId="support-users-select"
          multiple={true}
          variant="outlined"
          onChange={(event) =>
            setSupportUsers(
              _.uniqBy(
                (event.target.value as string[]).map((supportUser) => JSON.parse(supportUser)),
                'onboardingSupportUserId',
              ),
            )
          }>
          {organization?.supportUsers.map((supportUser) => (
            <MenuItem key={supportUser.onboardingSupportUserId} value={JSON.stringify(supportUser)}>
              {[supportUser.firstname, supportUser.lastname].join(' ')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <UIForm
        isLoading={fetchStatus.inProgress}
        inputs={overviewInputs}
        style={styles.form}
        variant="outlined"
        inputStyle={styles.col2}
        defaultValues={overview || {}}
        onSubmit={(values) => updateOverview(values)}
      />

      <div className="actions">
        <Button onClick={toggleEdit}>
          <FaTimes />
        </Button>
      </div>
    </Grid>
  );
};

export default Overview;
