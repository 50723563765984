import { IResume } from '@models/create.model';

export const RESUME_MOCK: IResume = {
  personal: {
    name: 'Amir BEN SLIMANE',
    job: 'Tech Lead Developer',
    email: 'amir.ben-slimane@treezor.com',
    // picture: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==',
    address: {
      city: 'Paris',
      country: 'France',
      line1: '26 rue Yvonne le Tac',
      zipCode: '75018',
    },
    nationality: 'Française',
    goal: 'Devenir le meilleur des dresseurs !',
    phone: '0688498708',
    twitter: 'https://twitter.com/home',
    linkedin: 'https://www.linkedin.com/feed/',
  },
  educations: [
    {
      degree: 'Ingénieur SI',
      year: '2016',
      school: 'Polytech Nice Sophia',
    },
    {
      degree: 'Bac S',
      year: '2009',
      school: 'Lycée Calmette',
    },
  ],
  experiences: [
    {
      company: 'Treezor',
      position: 'Fullstack Developer',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a commodo tellus. Vestibulum gravida libero in lectus facilisis ullamcorper. Phasellus a dapibus orci, quis semper odio. Nunc tristique auctor lacus, mattis volutpat tellus congue at. Cras sed lorem congue, ultricies metus eget, vestibulum libero. Integer imperdiet, dui sed efficitur placerat, lorem leo ultricies tortor, non lacinia lorem metus vel odio. Donec auctor maximus purus, at lacinia nibh volutpat ut. Donec pretium sem ex. Nunc tristique tincidunt dolor, non finibus odio. Proin id justo mi. Vestibulum non auctor nunc. Suspendisse pulvinar suscipit ultrices.',
      duration: '2020 - now',
    },
    {
      company: 'Air France',
      position: 'Tech Lead',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a commodo tellus. Vestibulum gravida libero in lectus facilisis ullamcorper. Phasellus a dapibus orci, quis semper odio. Nunc tristique auctor lacus, mattis volutpat tellus congue at. Cras sed lorem congue, ultricies metus eget, vestibulum libero. Integer imperdiet, dui sed efficitur placerat, lorem leo ultricies tortor, non lacinia lorem metus vel odio. Donec auctor maximus purus, at lacinia nibh volutpat ut. Donec pretium sem ex. Nunc tristique tincidunt dolor, non finibus odio. Proin id justo mi. Vestibulum non auctor nunc. Suspendisse pulvinar suscipit ultrices.',
      duration: '2016 - 2020',
    },
    {
      company: 'Amadeus',
      position: 'Apprentice Developer',
      details:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a commodo tellus. Vestibulum gravida libero in lectus facilisis ullamcorper. Phasellus a dapibus orci, quis semper odio. Nunc tristique auctor lacus, mattis volutpat tellus congue at. Cras sed lorem congue, ultricies metus eget, vestibulum libero. Integer imperdiet, dui sed efficitur placerat, lorem leo ultricies tortor, non lacinia lorem metus vel odio. Donec auctor maximus purus, at lacinia nibh volutpat ut. Donec pretium sem ex. Nunc tristique tincidunt dolor, non finibus odio. Proin id justo mi. Vestibulum non auctor nunc. Suspendisse pulvinar suscipit ultrices.',
      duration: '2015 - 2016',
    },
  ],
  skills: [
    {
      title: 'React',
      value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    },
    {
      title: 'Angular',
      value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    },
    {
      title: 'Javascript/Tipescript',
      value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    },
  ],
};
