import { onBoardingSteps } from '@assets/data/onBoardingSteps';
import { AgentStatus, IAgent, IStep } from '@models/agent.model';
import { AppState } from '@store/configuration';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { AWSService } from '@store/agent/agent.service';
import { Document, Page } from 'react-pdf';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { ToastOptions } from '@tools/constants';
import { updateProspectStatus } from '@store/agent/agent.action';
import Spinner from '@components/ui/Spinner';
import { useHistory } from 'react-router';
import { ProspectUtils } from '@tools/prospect.utils';

const PreProspectOverview: FunctionComponent = () => {
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const steps: Record<number, IStep> = onBoardingSteps;
  const [numPages, setNumPages] = useState<number | null>(null);
  const [loaderStatusValidate, setLoaderStatusValidate] = useState(false);
  const [loaderStatusRefuse, setLoaderStatusRefuse] = useState(false);

  const [response, setResponse] = useState<any>();

  useEffect(() => {
    if (currentAgent) {
      (async () => {
        const value = await Object.keys(steps).reduce(async (_acc: any, key: string) => {
          const { name, type } = steps[+key].input;
          const question = t(steps[+key].question);
          const value = _.get(currentAgent, name);
          let acc = await _acc;

          if (name.includes('pitchDeck')) {
            acc.pitchDeck = currentAgent.company?.pitchDeck
              ? await AWSService.getPresignedFile('get', `${currentAgent?.agentId}`)
              : '';
          } else if (
            (name.includes('company') && !name.includes('company.profile')) ||
            name.includes('additionalInfo')
          ) {
            acc.company[name] = ProspectUtils.getFormattedInfo(
              question,
              ProspectUtils.getValue(type, value, +key, onBoardingSteps, t),
            );
          } else {
            acc.personnalInfo[name] = ProspectUtils.getFormattedInfo(question, value);
          }
          return acc;
        }, Promise.resolve({ company: {}, personnalInfo: {} }));
        setResponse(value);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgent, t]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onChangeProspectStatus = (status: AgentStatus): void => {
    Swal.fire({
      title: t('preprospect.support.confirmation.title'),
      html: `${
        status === 'REFUSED'
          ? t('preprospect.support.confirmation.refuse')
          : t('preprospect.support.confirmation.validate')
      }`,
      showCancelButton: true,
      confirmButtonColor: status === 'REFUSED' ? '#d33' : '#15c1e8',
      cancelButtonColor: '#3085d6',
      confirmButtonText: status === 'REFUSED' ? t('preprospect.support.refuse') : t('preprospect.support.validate'),
      cancelButtonText: t('core.actions.cancel'),
      reverseButtons: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        status === 'REFUSED' ? setLoaderStatusRefuse(true) : setLoaderStatusValidate(true);
        const response = (await dispatch(updateProspectStatus(status))) as IAgent;
        status === 'REFUSED' ? setLoaderStatusRefuse(false) : setLoaderStatusValidate(false);
        if (response.agentId) {
          toast.success(
            `${
              status === 'REFUSED'
                ? t('preprospect.support.message.success.refused')
                : t('preprospect.support.message.success.validated')
            }`,
            ToastOptions,
          );
          if (status === 'REFUSED') history.push('/support');
        } else {
          toast.error(`${t('core.message.fail')}`, ToastOptions);
        }
      }
    });
  };

  const overview = () => (
    <div className="pre-prospect">
      <div className="welcome">
        {currentUser?.type === 'user' ? (
          <>
            <div className="message">
              {t('preprospect.welcome')} {response?.personnalInfo?.name?.value}, {t('preprospect.requestInProgress')}
            </div>
            <div className="information">{t('preprospect.profileInformation')}</div>
          </>
        ) : (
          <>
            <div className="message">{t('preprospect.support.requestInProgress')}</div>
            <div className="change_status">
              <Button className="validate" onClick={() => onChangeProspectStatus('PROSPECT')}>
                {t('preprospect.support.validate')}
                {loaderStatusValidate && <Spinner size={20} color="inherit" style={{ marginLeft: 20 }} />}
              </Button>
              <Button className="refuse" onClick={() => onChangeProspectStatus('REFUSED')}>
                {t('preprospect.support.refuse')}
                {loaderStatusRefuse && <Spinner size={20} color="inherit" style={{ marginLeft: 20 }} />}
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="information">
        <div className="title">
          {currentUser?.type === 'user' ? (
            <>
              <p>{t('preprospect.yourInformation')}</p>
              <p>{t('preprospect.pleaseFindInformationHaveBeenCompleted')}</p>
            </>
          ) : (
            <>
              <p>{t('preprospect.support.information')}</p>
              <p>{t('preprospect.support.pleaseFindInformationHaveBeenCompleted')}</p>
            </>
          )}
        </div>
        <div className="content">
          <div className="agent flex-container">
            {currentAgent?.legalRepresentant?.email && (
              <div className="flex-item">
                <p className="label">{t('preprospect.form.email.question')}</p>
                <p className="value">{currentAgent?.legalRepresentant?.email}</p>
              </div>
            )}
            {response?.personnalInfo &&
              Object.keys(response?.personnalInfo).map((key: string) => {
                return (
                  <div className="flex-item" key={key}>
                    <p className="label">{response.personnalInfo[key].label}</p>
                    <p className="value">{response.personnalInfo[key].value}</p>
                  </div>
                );
              })}
          </div>
          <div className="pitch-deck flex-container">
            <div className="flex-item">
              <p className="label">{t('preprospect.pitchDeck')}</p>
              <div className="valuePitch">
                {response?.pitchDeck && (
                  <Document file={{ url: response?.pitchDeck }} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page height={300} renderInteractiveForms key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                  </Document>
                )}
              </div>
            </div>
          </div>
          <div className="company flex-container">
            {response?.company &&
              Object.keys(response?.company).map((key: string) => {
                return (
                  response.company[key].value &&
                  response.company[key].value !== '' && (
                    <div className="flex-item" key={key}>
                      <p className="label">{response.company[key]?.label}</p>
                      <p className="value">{response.company[key]?.value}</p>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );

  return overview();
};

export default PreProspectOverview;
