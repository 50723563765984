import { createReducer } from 'typesafe-actions';

import { AuthAction, AuthActionTypes, AuthState } from '@store/auth/auth.types';

const initialState: AuthState = {
  status: {
    confirmation: { inProgress: false, done: false },
    login: { inProgress: false, done: false },
    finalize: { inProgress: false, done: false },
    onboarding: { inProgress: false, done: false },
    sirene: { inProgress: false, done: false },
  },
};

const authReducer = createReducer<AuthState, AuthAction>(initialState)
  .handleType(AuthActionTypes.LOGOUT, () => initialState)
  .handleType(AuthActionTypes.SET_ACCESS_TOKEN, (state, action) => ({
    ...state,
    accessToken: action.accessToken,
  }))
  .handleType(AuthActionTypes.CLEAR_ACCESS_TOKEN, (state) => ({
    ...state,
    accessToken: undefined,
  }))
  .handleType(AuthActionTypes.LOGIN_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, login: { inProgress: true, done: false } },
    error: undefined,
  }))
  .handleType(AuthActionTypes.LOGIN_SUCESS, (state, action) => ({
    ...state,
    status: { ...state.status, login: { inProgress: false, done: true } },
    accessToken: action.response.access_token,
  }))
  .handleType(AuthActionTypes.LOGIN_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, login: { inProgress: false, done: false } },
    error: action.error,
  }))
  .handleType(AuthActionTypes.ONBOARD_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, onboarding: { inProgress: true, done: false } },
    error: undefined,
  }))
  .handleType(AuthActionTypes.ONBOARD_SUCESS, (state) => ({
    ...state,
    status: { ...state.status, onboarding: { inProgress: false, done: true } },
  }))
  .handleType(AuthActionTypes.ONBOARD_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, onboarding: { inProgress: false, done: false } },
    error: action.error,
  }))
  .handleType(AuthActionTypes.CONFIRM_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, confirmation: { inProgress: true, done: false } },
    error: undefined,
  }))
  .handleType(AuthActionTypes.CONFIRM_SUCESS, (state) => ({
    ...state,
    status: { ...state.status, confirmation: { inProgress: false, done: true } },
  }))
  .handleType(AuthActionTypes.CONFIRM_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, confirmation: { inProgress: false, done: false } },
    error: action.error,
  }))
  .handleType(AuthActionTypes.FINALYSE_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, finalize: { inProgress: true, done: false } },
    error: undefined,
  }))
  .handleType(AuthActionTypes.FINALYSE_SUCESS, (state) => ({
    ...state,
    status: { ...state.status, finalize: { inProgress: false, done: true } },
  }))
  .handleType(AuthActionTypes.FINALYSE_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, finalize: { inProgress: false, done: false } },
    error: action.error,
  }))
  .handleType(AuthActionTypes.SIRENE_REQUEST, (state) => ({
    ...state,
    status: { ...state.status, sirene: { inProgress: true, done: false } },
    error: undefined,
  }))
  .handleType(AuthActionTypes.SIRENE_SUCCESS, (state, action) => ({
    ...state,
    status: { ...state.status, sirene: { inProgress: false, done: true } },
    sirene: action.sirene,
  }))
  .handleType(AuthActionTypes.SIRENE_FAILURE, (state, action) => ({
    ...state,
    status: { ...state.status, sirene: { inProgress: false, done: false } },
    error: action.error,
  }));

export { authReducer };
