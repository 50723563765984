import Document from '@components/Document';
import { Avatar, makeStyles } from '@material-ui/core';
import { IAgentStep, IDocument } from '@models/agent.model';
import { toggleStep } from '@store/agent/agent.action';
import { AWSService } from '@store/agent/agent.service';
import { AppState } from '@store/configuration';
import { Colors } from '@tools/constants';
import * as JSZip from 'jszip';
import moment from 'moment';
import { extension } from 'mime-types';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload, FaRegMinusSquare, FaRegPlusSquare } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Progress } from 'reactstrap';
import { Config } from '../config';
import { ButtonPopover } from './ui/ButtonPopover';

export interface StepProps {
  step: IAgentStep;
  rank: number;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: 18,
    backgroundColor: Colors.treezor.blue,
  },
}));

const Step: FunctionComponent<StepProps> = ({ step, rank }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  const [documents, setDocuments] = useState<IDocument[]>([]);
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const toggleExpand = () => dispatch(toggleStep(step.label as string));
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentUser?.type === 'support-user') setDocuments(step.documents as IDocument[]);
    else setDocuments(step.documents?.filter((document) => document.visible) as IDocument[]);
  }, [step]);

  useEffect(() => {
    if (step.expand && cardRef?.current) {
      cardRef.current.scrollIntoView();
    }
  }, [cardRef, step.expand]);

  const progression =
    step.documents && step.count
      ? Math.round((((step.count.done as number) * 100) / step.documents.length) as number)
      : 0;

  const getStepPresignedFiles = async (step: IAgentStep) => {
    if (step.documents) {
      const documentsWithPath =
        currentUser?.type === 'support-user'
          ? step.documents.filter((document) => document.permission?.download)
          : step.documents.filter((document) => document.permission?.download && document.visible);

      if (documentsWithPath.length) {
        return await Promise.all(
          documentsWithPath.map(async (document) => {
            let url;

            if (!document.path && document.default) {
              url = await AWSService.getPresignedFile(
                'get',
                Config.bucket.templatesFolder as string,
                document.default as string,
              );
            } else if (!document.path && document.template) {
              url = await AWSService.getPresignedFile(
                'get',
                Config.bucket.templatesFolder,
                document.template as string,
              );
            } else {
              url = await AWSService.getPresignedFile('get', `${currentAgent?.agentId}`, document.documentId);
            }

            return {
              url,
              name: document.fullName,
            };
          }),
        );
      }

      return Promise.resolve([]);
    }
  };

  const downloadZIP = async () => {
    const zipper = JSZip.default();

    const stepFiles = {
      step: step.label?.replace('/', '-'),
      files: await getStepPresignedFiles(step),
    };

    if (stepFiles.files)
      await Promise.all(
        stepFiles.files.map(async (file) => {
          const fileResponse = await fetch(file.url);
          if (!fileResponse.ok) {
            return;
          }

          const fileBlob = await fileResponse.blob();
          zipper.file(`${file.name}.${extension(fileBlob.type)}`, fileBlob, { binary: true });
        }),
      );

    const zip = await zipper.generateAsync({ type: 'blob' });

    const zipName = `[Support]_Dossier Agent_FR_[Nom Agent]_${step.label}_[JJMMAAAA]`
      .replace('[Support]', Config.support.name)
      .replace('[Nom Agent]', currentAgent?.company?.profile?.name as string)
      .replace('[JJMMAAAA]', moment().format('DDMMYYYY'));

    saveAs(zip, zipName);
  };

  return (
    <Card className="step-card" innerRef={cardRef}>
      <CardHeader>
        <div className="step-title" onClick={toggleExpand} style={{ width: '43%' }}>
          <Avatar variant="square" className={classes.avatar}>
            {rank}
          </Avatar>
          <span className="step-label">{step.label}</span>
        </div>

        <div className="step-progress">
          <Progress value={progression}>{progression} %</Progress>
        </div>

        <div className="step-overview">
          <Card>
            <div style={{ color: Colors.status.todoAgent }}>{t('pages.documents.step.header.todoAgent')}</div>
            <div>
              (<span>{step.count?.todoAgent}</span>/{step.documents?.length})
            </div>
          </Card>
          <Card>
            <div style={{ color: Colors.status.todoSupport }}>{t('pages.documents.step.header.todoSupport')}</div>
            <div>
              (<span>{step.count?.todoSupport}</span>/{step.documents?.length})
            </div>
          </Card>
          <Card>
            <div style={{ color: Colors.status.done }}>{t('pages.documents.step.header.done')}</div>
            <div>
              (<span>{step.count?.done}</span>/{step.documents?.length})
            </div>
          </Card>
        </div>

        <div>
          <ButtonPopover
            Icon={FaDownload}
            onClick={downloadZIP}
            description={`${t('pages.documents.step.actions.zipStepFiles', { stepLabel: step.label })}`}
          />
          <ButtonPopover
            Icon={step.expand ? FaRegMinusSquare : FaRegPlusSquare}
            onClick={toggleExpand}
            description={
              step.expand ? t('pages.documents.step.actions.reduce') : t('pages.documents.step.actions.expand')
            }
          />
        </div>
      </CardHeader>

      <CardBody hidden={!step.expand}>
        <div className="document-table-header">
          <div style={{ width: '40%' }}>{t('pages.documents.step.header.document')}</div>
          <div style={{ width: '15%' }}>{t('pages.documents.step.header.agentStatus')}</div>
          <div style={{ width: '15%' }}>{t('pages.documents.step.header.supportStatus')}</div>
          <div style={{ width: '15%' }}>{t('pages.documents.step.header.complianceStatus')}</div>
          <div style={{ width: '15%' }}>{t('pages.documents.step.header.actions')}</div>
        </div>

        <div className="document-table-body">
          {documents?.map((document, index) => (
            <Document key={`document-${index}`} step={step} document={document} />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default Step;
