import PrivateRoute from '@components/PrivateRoute';
import Admin from '@pages/Admin';
import Agent from '@pages/Agent';
import ChangeProcess from '@pages/ChangeProcess';
import ConfirmationFailed from '@pages/ConfirmationFailed';
import Documents from '@pages/Documents';
import Error404 from '@pages/Error404';
import Formations from '@pages/Formations';
import GetStartedStepper from '@pages/GetStartedStepper';
import Login from '@pages/Login';
import Meetings from '@pages/Meetings';
import Overview from '@pages/Overview';
import PreProspectOverview from '@pages/PreProspectOverview';
import Profile from '@pages/Profile';
import Qualification from '@pages/Qualification';
import Support from '@pages/Support';
import SupportUsers from '@pages/SupportUsers';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

const RoutedContent = () => (
  <Switch>
    {/*    Root    */}
    <Redirect from="/" to="/login" exact />

    {/*    Login / Onboard    */}
    <Route path="/login" component={Login} />
    <Route path="/confirmation-failed" component={ConfirmationFailed} />
    <Route path="/confirmation-stepper" component={GetStartedStepper} />

    {/*    Prospect / Agent    */}
    <PrivateRoute exact agent path="/prospect" component={Overview} />
    <PrivateRoute exact agent path="/profile" component={Profile} />
    <PrivateRoute exact agent path="/documents" component={Documents} />
    <PrivateRoute exact agent path="/meetings" component={Meetings} />
    <PrivateRoute exact agent path="/formations" component={Formations} />
    <PrivateRoute exact agent path="/qualification" component={Qualification} />
    <PrivateRoute exact agent path="/pre-prospect" component={PreProspectOverview} />

    {/*    Support    */}
    <PrivateRoute exact support path="/support" component={Support} />
    <PrivateRoute exact support path="/admin" component={Admin} />
    <PrivateRoute support path="/agent" component={Agent} />
    <PrivateRoute support path="/support-users" component={SupportUsers} />
    <PrivateRoute support path="/change-process" component={ChangeProcess} />

    {/*    404    */}
    <Route path="/error-404" component={Error404} />
    <Redirect to="/error-404" />
  </Switch>
);

export default RoutedContent;
