import React from 'react';

export interface IPageConfigContext {
    setNavBarVisibility: (visibility: boolean) => void;
    setSideBarVisibility: (visibility: boolean) => void;
};

const PageConfigContext = React.createContext({
    setNavBarVisibility: (visibility: boolean) => { },
    setSideBarVisibility: (visibility: boolean) => { }
} as IPageConfigContext);

export {
    PageConfigContext
};
