import { createReducer } from 'typesafe-actions';

import { CoreState, CoreActionTypes, CoreAction } from '@store/core/core.types';

const initialState: CoreState = {};

const coreReducer = createReducer<CoreState, CoreAction>(initialState)
  .handleType(CoreActionTypes.SET_ERROR, (state, action) => ({
    ...state,
    error: action.error,
  }))
  .handleType(CoreActionTypes.CLEAR_ERROR, (state) => ({
    ...state,
    error: undefined,
  }))
  .handleType(CoreActionTypes.SET_SIDE_TAB, (state, action) => ({
    ...state,
    activeTab: action.tab,
  }))
  .handleType(CoreActionTypes.FETCH_CURRENT_RULES_REQUEST, (state, action) => ({
    ...state,
  }))
  .handleType(CoreActionTypes.FETCH_CURRENT_RULES_SUCCESS, (state, action) => ({
    ...state,
    rules: action.response,
  }))
  .handleType(CoreActionTypes.FETCH_CURRENT_RULES_FAILURE, (state, action) => ({
    ...state,
    error: action.error,
  }));

export { coreReducer };
