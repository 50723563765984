import macbookImage from '@assets/images/macbook.png';
import { EmptyLayout } from '@components/layout/EmptyLayout';
import Spinner from '@components/ui/Spinner';
import { ILoginRequest } from '@models/auth.model';
import { IUser } from '@models/user.model';
import { login, loginWithAuthorizationCode } from '@store/auth/auth.action';
import { AppState } from '@store/configuration';
import React, { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEye, FaLock, FaLongArrowAltRight, FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import { Config } from '../config';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const currentUser: IUser = useSelector((state: AppState) => state.user.currentUser as IUser);
  const accessToken: string = useSelector((state: AppState) => state.auth.accessToken as string);
  const authErrorResult: any = useSelector((state: AppState) => state.auth.error as any);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setVisibilityPassword] = useState(false);
  const [appReady, setAppReady] = useState(false);

  useEffect(() => {
    // Check if token is in URL fragment
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.has('code')) {
        return;
      }

      const authorizationCode = urlParams.get('code');
      if (authorizationCode) {
        dispatch(loginWithAuthorizationCode(authorizationCode, true, true));
        history.push('/support');
      } else {
        console.warn('[SSO] Authorization Code not found');
      }
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (accessToken && currentUser) {
      if (currentUser.type === 'user') {
        history.push('/prospect');
      }
      if (currentUser.type === 'support-user') {
        history.push('/support');
      }
    }
    setAppReady(true);
  }, [history, accessToken, currentUser, dispatch]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      doLogin(event);
    }
  };

  const doLogin = (event: MouseEvent | KeyboardEvent) => {
    event.preventDefault();
    dispatch(login({ email, password } as ILoginRequest));
  };

  const changeVisibility = () => {
    setVisibilityPassword(!showPassword);
  };

  const ssoUrl = `${Config.oauth.base}/authorize?response_type=code&client_id=${Config.oauth.dashboardClientId}&redirect_uri=${Config.oauth.redirectURI}&scope=read_write read_only admin legal keys read_all support_user_management`;
  const isAuth = Boolean(authErrorResult && authErrorResult.ok === false);

  if (!appReady) return <Spinner center />;

  return (
    <EmptyLayout>
      <div className="login-container">
        <div className="form-container">
          <div className="form-content">
            <div className="login-title">
              <span className="login-title-bold">{t('pages.login.title.bold')}</span> {t('pages.login.title.thin')}
            </div>

            <div className="line" />

            <Form className="login-form">
              <div className={isAuth ? 'login-inputs-error' : 'login-inputs'}>
                <FormGroup>
                  <Label for="emailAdress">{t('pages.login.form.email.label')}</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaUser />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      invalid={isAuth ? true : false}
                      onInput={(e) => setEmail(e.currentTarget.value)}
                      type="email"
                      autoComplete="email"
                      name="email"
                      id="emailAdress"
                      placeholder={t('pages.login.form.email.placeholder')}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <Label for="password">{t('pages.login.form.password.label')}</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaLock />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      invalid={isAuth ? true : false}
                      onInput={(e) => setPassword(e.currentTarget.value)}
                      onKeyDown={handleKeyDown}
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      name="password"
                      id="password"
                      placeholder={t('pages.login.form.password.placeholder')}
                    />
                    <InputGroupText>
                      <FaEye onClick={changeVisibility} />
                    </InputGroupText>
                    <FormFeedback>{t('pages.login.form.error.formfeedback')}</FormFeedback>
                  </InputGroup>
                </FormGroup>

                <div>
                  <Button color="primary" block onClick={doLogin}>
                    {t('pages.login.form.action.login')}
                  </Button>
                </div>

                <div className="line-login" />
              </div>

              <div>
                <Button color="secondary" block href={ssoUrl}>
                  {t('pages.login.form.action.sso')}
                  <FaLongArrowAltRight />
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="image-container">
          <img className="macbook-image" src={macbookImage} alt="" />
        </div>
      </div>
    </EmptyLayout>
  );
};

export default Login;
