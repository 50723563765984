import { AppState } from '@store/configuration';
import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';

const ErrorAlert = () => {
  const message = useSelector((state: AppState) => state.core.error);

  return (
    <Alert hidden={!message || message === ''} color="danger">
      {message}
    </Alert>
  );
};

export default ErrorAlert;
