import useToggle from '@hooks/useToggle';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Storage } from '@tools/localStorage';
import React, { useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

export const LanguageChoice = () => {
  const { t, i18n } = useTranslation();

  const [isDrawnerOpen, toggleDrawer] = useToggle(false);

  const changeLanguage = (language: string): void => {
    toggleDrawer();

    i18n.changeLanguage(language);
  };

  const flags: any = useMemo(
    () => ({
      fr: <ReactCountryFlag countryCode="FR" />,
      en: <ReactCountryFlag countryCode="GB" />,
      es: <ReactCountryFlag countryCode="ES" />,
      it: <ReactCountryFlag countryCode="IT" />,
    }),
    [],
  );

  const languages = [
    { flag: flags.fr, label: t('core.maintopbar.french'), lang: 'fr' },
    { flag: flags.en, label: t('core.maintopbar.english'), lang: 'en' },
    { flag: flags.es, label: t('core.maintopbar.spain'), lang: 'es' },
    { flag: flags.it, label: t('core.maintopbar.italian'), lang: 'it' },
  ];

  const actualLanguage = window.localStorage.getItem(Storage.LANGUAGE) as string;

  return (
    <>
      <Button onClick={toggleDrawer}>{flags[actualLanguage]}</Button>

      <Drawer anchor="right" open={isDrawnerOpen} onClose={toggleDrawer}>
        <List>
          {languages.map((language) => (
            <ListItem
              onClick={() => changeLanguage(language.lang)}
              style={{ paddingLeft: 40, paddingRight: 40 }}
              button
              key={language.label}>
              <ListItemIcon>{language.flag}</ListItemIcon>
              <ListItemText primary={language.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};
