import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { generate } from 'generate-password';

import { AppState } from '@store/configuration';
import { IAgent } from '@models/agent.model';
import { confirmProspect, finalizeProspect } from '@store/auth/auth.action';

import StepperComponent from '@components/StepperComponent';
import { EmptyLayout } from '@components/layout/EmptyLayout';
import { onBoardingSteps } from '@assets/data/onBoardingSteps';

const GetStartedStepper = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const [currentProspect, setCurrentProspect] = useState<IAgent | undefined>();

  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);
  const status = useSelector((state: AppState) => state.auth.status);

  useEffect(() => {
    const password = generate({ length: 10, numbers: true });

    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get('authorization_code');
      const email = urlParams.get('userEmail');
      const userId = urlParams.get('userId');
      const organizationId = urlParams.get('organizationId');

      if (authorizationCode && email && userId && organizationId) {
        dispatch(
          confirmProspect({
            authorizationCode,
            password,
            userId,
            email,
          }),
        );

        setCurrentProspect({
          legalRepresentant: {
            email,
          },
          organizationId,
          status: 'PRE_PROSPECT',
        });
      }
    }
  }, []);

  useEffect(() => {
    if (status.finalize.done && currentAgent) history.push('/pre-prospect');
  }, [status.finalize.done, currentAgent, history]);

  const createProspect = async (prospect: IAgent & { [key: string]: any }) => {
    const { confirmPassword, password, ...rest } = prospect;
    dispatch(
      finalizeProspect(
        {
          ...rest,
          code: prospect.code || '',
          company: {
            ...rest.company,
            isPartOfGroup: rest.company?.isPartOfGroup === 'yes',
            isRegistered: rest.company?.isRegistered === 'yes',
            isRegulated: rest.company?.isRegulated === 'yes',
          },
          organizationId: currentProspect?.organizationId,
          status: currentProspect?.status,
        },
        password,
        prospect?.company?.pitchDeck,
      ),
    );
  };

  return (
    <EmptyLayout>
      <StepperComponent
        onSubmit={createProspect}
        steps={onBoardingSteps}
        defaultValues={currentProspect}
        isLoading={status.finalize.inProgress}
        submitLabel={t('forms.core.save')}
        key="confirmProspect"
      />
    </EmptyLayout>
  );
};

export default GetStartedStepper;
