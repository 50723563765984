import {
  Avatar,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { IOnboardingSupportUserRequest, IOnboardingSupportUser } from '@models/user.model';
import { AppState } from '@store/configuration';
import { addSupportUser, fetchOrganization, updateSupportUser } from '@store/user/user.action';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaEnvelope, FaPhone, FaPlus, FaUser, FaUserTag } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const SupportUsers: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const organization = useSelector((state: AppState) => state.user.organization);

  const [currentSupportUser, setCucrrentSupportUser] = useState<IOnboardingSupportUser>();

  useEffect(() => {
    dispatch(fetchOrganization());
  }, []);

  const actualSupportUser = useMemo<IOnboardingSupportUser>(
    () =>
      organization?.supportUsers.find(
        (supportUser) => (supportUser as IOnboardingSupportUser).supportUserId === currentUser?.id,
      ) as IOnboardingSupportUser,
    [organization, currentUser],
  );

  const addNewSupportUser = (supportUser: IOnboardingSupportUserRequest) => {
    dispatch(addSupportUser({ ...supportUser, organizationId: organization?.organizationId as string }));
    setCucrrentSupportUser(undefined);
  };

  const doUpdateSupportUser = (supportUser: IOnboardingSupportUserRequest) => {
    if (supportUser.password === '') delete supportUser.password;

    const supportUserUpdated = organization?.supportUsers.find(
      (lSupportUser) => lSupportUser.username === supportUser.username,
    );

    if (supportUserUpdated) {
      dispatch(
        updateSupportUser({ ...supportUser, onboardingSupportUserId: supportUserUpdated.onboardingSupportUserId }),
      );
    }
    setCucrrentSupportUser(undefined);
  };

  const AboutSupportUser = ({
    supportUser,
    withAvatar,
  }: {
    supportUser: IOnboardingSupportUser;
    withAvatar?: boolean;
  }) => (
    <Card
      onClick={() => setCucrrentSupportUser(supportUser)}
      style={{
        margin: 10,
        padding: 30,
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
      }}>
      {withAvatar && (
        <Avatar style={{ width: 100, height: 100, alignContent: 'center', margin: 'auto' }}>
          <FaUser size={50} />
        </Avatar>
      )}

      {Boolean(supportUser.default) && (
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          <span>{t('pages.supportUsers.default')}</span>
          <span style={{ marginLeft: 10 }}>
            <FaCheckCircle />
          </span>
        </div>
      )}

      <CardContent>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item>
            <span style={{ marginRight: 10 }}>
              <FaUser />
            </span>
            <span>
              {supportUser?.firstname} {supportUser?.lastname}
            </span>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item style={{ marginRight: 10 }}>
            <FaUserTag />
          </Grid>
          <Grid item>{t(`forms.supportUser.role.items.${supportUser?.role}`)}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item style={{ marginRight: 10 }}>
            <FaEnvelope />
          </Grid>
          <Grid item>{supportUser?.username}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item style={{ marginRight: 10 }}>
            <FaPhone />
          </Grid>
          <Grid item>{supportUser?.phone}</Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const AddSupportUser = () => (
    <Card
      onClick={() => setCucrrentSupportUser({} as IOnboardingSupportUserRequest)}
      style={{
        margin: 10,
        minHeight: 130,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <FaPlus />
      <Typography variant="h6" gutterBottom>
        {t('pages.supportUsers.add')}
      </Typography>
    </Card>
  );

  const SupportUserForm: FunctionComponent<{ supportUser: IOnboardingSupportUser }> = ({ supportUser }) => {
    const { register, handleSubmit, errors, control } = useForm<IOnboardingSupportUserRequest>({
      defaultValues: supportUser,
    });

    const isNew = !Boolean(supportUser.username);

    return (
      <Modal style={{ width: '50%' }} centered={true} isOpen={true} toggle={() => setCucrrentSupportUser(undefined)}>
        <form
          onSubmit={isNew ? handleSubmit(addNewSupportUser) : handleSubmit(doUpdateSupportUser)}
          style={{ display: 'flex', flexDirection: 'column' }}>
          <ModalHeader>{t(`forms.supportUser.title.${isNew ? 'add' : 'update'}`)}</ModalHeader>
          <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              inputRef={register({ required: true })}
              name="firstname"
              label={t('forms.supportUser.firstname.label')}
              variant="outlined"
              style={{ margin: 10 }}
              InputLabelProps={{ required: true }}
              error={errors.firstname?.type === 'required'}
              helperText={errors.firstname && <p>{t('forms.supportUser.firstname.required')}</p>}
            />
            <TextField
              inputRef={register({ required: true })}
              name="lastname"
              label={t('forms.supportUser.lastname.label')}
              style={{ margin: 10 }}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.lastname?.type === 'required'}
              helperText={errors.lastname && <p>{t('forms.supportUser.lastname.required')}</p>}
            />
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <FormControl variant="outlined" style={{ margin: 10 }}>
                  <InputLabel id="role-select">{t('forms.supportUser.role.label')}</InputLabel>
                  <Select
                    labelId="role-select"
                    label={t('forms.supportUser.role.label')}
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={errors.role?.type === 'required'}>
                    <MenuItem value="sales">{t('forms.supportUser.role.items.sales')}</MenuItem>
                    <MenuItem value="accountManager">{t('forms.supportUser.role.items.accountManager')}</MenuItem>
                    <MenuItem value="legalCounsel">{t('forms.supportUser.role.items.legalCounsel')}</MenuItem>
                    <MenuItem value="complianceOfficer">{t('forms.supportUser.role.items.complianceOfficer')}</MenuItem>
                    <MenuItem value="implementationManager">
                      {t('forms.supportUser.role.items.implementationManager')}
                    </MenuItem>
                    <MenuItem value="technicalAccountManager">
                      {t('forms.supportUser.role.items.technicalAccountManager')}
                    </MenuItem>
                    <MenuItem value="director">{t('forms.supportUser.role.items.director')}</MenuItem>
                    <MenuItem value="administrator">{t('forms.supportUser.role.items.administrator')}</MenuItem>
                  </Select>
                  {errors.role && <FormHelperText error>{t('forms.supportUser.role.required')}</FormHelperText>}
                </FormControl>
              )}
            />

            <TextField
              inputRef={register({ required: true })}
              name="username"
              style={{ margin: 10 }}
              label={t('forms.supportUser.email.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.username?.type === 'required'}
              helperText={errors.username && <p>{t('forms.supportUser.email.required')}</p>}
            />

            <TextField
              inputRef={register({ required: isNew })}
              name="password"
              style={{ margin: 10 }}
              label={t('forms.supportUser.password.label')}
              variant="outlined"
              InputLabelProps={{ required: isNew }}
              error={errors.password?.type === 'required'}
              helperText={errors.password && <p>{t('forms.supportUser.password.required')}</p>}
            />

            <TextField
              inputRef={register({ required: true })}
              name="phone"
              style={{ margin: 10 }}
              label={t('forms.supportUser.phone.label')}
              variant="outlined"
              InputLabelProps={{ required: true }}
              error={errors.phone?.type === 'required'}
              helperText={errors.phone && <p>{t('forms.supportUser.phone.required')}</p>}
            />

            <Controller
              name="default"
              control={control}
              render={({ value, onChange }) => (
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <InputLabel style={{ marginLeft: 10 }}>{t('forms.supportUser.default.label')}</InputLabel>
                  <Switch
                    checked={value === 'true'}
                    onChange={(event) => onChange(`${event.target.checked}`)}
                    color="primary"
                  />
                </Grid>
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary" variant="outlined">
              {t(`forms.supportUser.submit.${isNew ? 'add' : 'update'}`)}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  };

  return (
    <Grid container direction="column" style={{ padding: 20 }}>
      <Typography variant="h6" style={{ textTransform: 'uppercase', fontWeight: 800 }} gutterBottom>
        {t('pages.supportUsers.myProfil')}
      </Typography>
      <Grid container direction="row">
        <Grid item style={{ padding: 16 }}>
          <AboutSupportUser withAvatar supportUser={actualSupportUser} />
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Typography variant="h6" style={{ textTransform: 'uppercase', fontWeight: 800 }} gutterBottom>
          {t('pages.supportUsers.myOrganization')}
        </Typography>
        <Grid container direction="row" alignItems="center" wrap="wrap">
          {organization?.supportUsers &&
            organization?.supportUsers
              .filter((supportUser) => supportUser.supportUserId !== actualSupportUser.supportUserId)
              .map((supportUser) => (
                <Grid key={(supportUser as IOnboardingSupportUser).supportUserId} item lg={3} style={{ padding: 16 }}>
                  <AboutSupportUser supportUser={supportUser as IOnboardingSupportUser} />
                </Grid>
              ))}
          <Grid item lg={3} style={{ padding: 16 }}>
            <AddSupportUser />
          </Grid>
        </Grid>
      </Grid>
      {currentSupportUser && <SupportUserForm supportUser={currentSupportUser} />}
    </Grid>
  );
};

export default SupportUsers;
