import DocumentModals, { DocumentModalsHandles } from '@components/DocumentModals';
import useMachine from '@hooks/useMachine';
import { Button, TextField } from '@material-ui/core';
import { DocumentStatus, IAgentStep, IDocument } from '@models/agent.model';
import { DocumentMachineAction } from '@models/machine.model';
import { IOnboardingSupportUser } from '@models/user.model';
import { publishComment, toggleDocumentComments } from '@store/agent/agent.action';
import { AWSService } from '@store/agent/agent.service';
import { AppState } from '@store/configuration';
import { Colors, StatusCategory } from '@tools/constants';
import { createDocumentStateMachine } from '@tools/document.state.machine';
import moment from 'moment';
import React, { FunctionComponent, KeyboardEvent, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FaCheck,
  FaCubes,
  FaDownload,
  FaEdit,
  FaEye,
  FaLink,
  FaMinusCircle,
  FaPaperPlane,
  FaPause,
  FaPlusCircle,
  FaTimes,
  FaTrash,
  FaUpload,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Card } from 'reactstrap';
import { Config } from '../config';
import { ButtonPopover } from './ui/ButtonPopover';

interface DocumentProps {
  document: IDocument;
  step: IAgentStep;
}

interface IAddCommentFromInputs {
  comment: string;
}

const Document: FunctionComponent<DocumentProps> = ({ step, document }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, reset } = useForm<IAddCommentFromInputs>();

  const modalsRef = useRef<DocumentModalsHandles>(null);

  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const currentAgent = useSelector((state: AppState) => state.agent.currentAgent);
  const currentProcess = useSelector((state: AppState) => state.agent.currentAgent?.currentProcess);
  const organization = useSelector((state: AppState) => state.user.organization);

  const discussionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (document.commentsIsExpand && discussionRef) {
      discussionRef.current?.scrollTo(0, discussionRef.current.scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.commentsIsExpand, document.comments?.length]);

  const openDocument = async () => {
    if (document.type === 'URL') {
      const newWindow = window.open(document.path || document.default, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    } else {
      if (!document.path && document.default) {
        document.presignedUrl = await AWSService.getPresignedFile(
          'get',
          Config.bucket.templatesFolder as string,
          document.default as string,
        );
      } else if (!document.path && document.template) {
        document.presignedUrl = await AWSService.getPresignedFile(
          'get',
          Config.bucket.templatesFolder,
          document.template as string,
        );
      } else {
        document.presignedUrl = await AWSService.getPresignedFile(
          'get',
          `${currentAgent?.agentId}`,
          document.documentId,
        );
      }

      document.mimeType = document.mimeType || document.template;

      modalsRef.current?.togglePreview();
    }
    readDocuemnt();
  };

  const stateMachine = useMachine(createDocumentStateMachine(), document);

  const currentSupportUser = useMemo<IOnboardingSupportUser>(
    () =>
      organization?.supportUsers.find(
        (supportUser) => (supportUser as IOnboardingSupportUser).supportUserId === currentUser?.id,
      ) as IOnboardingSupportUser,
    [organization, currentUser],
  );

  const can = (type: DocumentMachineAction) => {
    return stateMachine.can(type);
  };
  const send = (type: DocumentMachineAction, params?: any) => {
    if (step.process === currentProcess) stateMachine.send(type, params);
    else console.warn('[FORBIDDEN] Not current process');
  };

  const addComment = (values: IAddCommentFromInputs) => {
    dispatch(
      publishComment({
        stepLabel: step.label as string,
        documentName: document.name as string,
        comment: {
          content: values.comment,
          writeDate: Date.now(),
          role: currentUser?.type === 'user' ? 'CLIENT' : 'SUPPORT', // TODO for SUPPORT_N2
        },
      }),
    );
    discussionRef.current?.scrollTo(0, discussionRef.current.scrollHeight);
    reset({ comment: '' });
  };

  const removeDocument = () => {
    if (currentUser?.type === 'user') send(DocumentMachineAction.AGENT_REMOVE);
    if (currentUser?.type === 'support-user') send(DocumentMachineAction.COMPLIANCE_REMOVE);
  };

  const editDocument = () => {
    if (document.type === 'CREATE') modalsRef.current?.toggleCreate();
    if (document.type === 'GENERATE') modalsRef.current?.toggleGenerate();
  };

  const linkDocument = () => {
    modalsRef.current?.toggleLink();
  };

  const uploadDocument = () => {
    modalsRef.current?.toggleUpload();
  };

  const downloadDocument = async () => {
    const a = window.document.createElement('a');
    a.download = `${document.fullName}`;

    if (!document.path && document.default) {
      a.href = await AWSService.getPresignedFile(
        'get',
        Config.bucket.templatesFolder as string,
        document.default as string,
      );
    } else if (!document.path && document.template) {
      a.download = `[TEMPLATE] ${document.name}`;
      a.href = await AWSService.getPresignedFile('get', Config.bucket.templatesFolder, document.template as string);
    } else {
      a.href = await AWSService.getPresignedFile('get', `${currentAgent?.agentId}`, document.documentId);
    }

    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);

    readDocuemnt();
  };

  const downloadTemplate = async () => {
    const a = window.document.createElement('a');
    a.download = `${document.fullName}`;

    if (document.template) {
      a.download = `[TEMPLATE] ${document.name}`;
      a.href = await AWSService.getPresignedFile('get', Config.bucket.templatesFolder, document.template as string);

      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      window.document.body.appendChild(a);
      a.click();
      window.document.body.removeChild(a);
    }
  };

  const readDocuemnt = () => {
    if (currentUser?.type === 'user' && document.default) send(DocumentMachineAction.AGENT_READ, step);
  };

  const accept = () => {
    send(DocumentMachineAction.SUPPORT_ACCEPT, step);
  };

  const refuse = () => {
    send(DocumentMachineAction.SUPPORT_REFUSE);
  };

  const undoAcceptance = () => {
    send(DocumentMachineAction.SUPPORT_UNDO);
  };

  const approve = () => {
    send(DocumentMachineAction.COMPLIANCE_APPROVE);
  };

  const reject = () => {
    send(DocumentMachineAction.COMPLIANCE_REJECT);
  };

  const undoCompliance = () => {
    send(DocumentMachineAction.COMPLIANCE_UNDO);
  };

  const handleFile = (file: File) => {
    if (currentUser?.type === 'user') send(DocumentMachineAction.AGENT_UPLOAD, { file });
    if (currentUser?.type === 'support-user') send(DocumentMachineAction.SUPPORT_UPLOAD, { file });
  };

  const handleLink = (link: string) => {
    if (currentUser?.type === 'user') send(DocumentMachineAction.AGENT_LINK, { link });
    if (currentUser?.type === 'support-user') send(DocumentMachineAction.SUPPORT_LINK, { link });
  };

  const canTrash =
    document.permission?.trash &&
    (can(DocumentMachineAction.AGENT_REMOVE) || can(DocumentMachineAction.COMPLIANCE_REMOVE));
  const canUpload =
    document.permission?.upload &&
    (can(DocumentMachineAction.AGENT_UPLOAD) || can(DocumentMachineAction.SUPPORT_UPLOAD));
  const canEdit =
    document.permission?.edit && (can(DocumentMachineAction.AGENT_UPLOAD) || can(DocumentMachineAction.SUPPORT_UPLOAD));
  const canLink =
    document.permission?.link && (can(DocumentMachineAction.AGENT_LINK) || can(DocumentMachineAction.SUPPORT_LINK));

  const toggleComments = () => {
    dispatch(toggleDocumentComments(step.label as string, document.name as string));
  };

  const onCommentPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') handleSubmit(addComment)();
  };
  const n1SupportTypes = ['sales', 'accountManager', 'implementationManager', 'technicalAccountManager', 'director'];
  const n2SupportTypes = ['legalCounsel', 'complianceOfficer', 'director'];

  return (
    <div className="document-row">
      <DocumentModals
        ref={modalsRef}
        send={send}
        document={document}
        step={step}
        onUploadFile={handleFile}
        onLink={handleLink}
      />

      <div className="document-values">
        <div className="document-name" style={{ width: '40%' }}>
          <span style={{ backgroundColor: document.statusColor }} className="status-color" />
          <span style={{ marginRight: 10 }}>
            {document.name}{' '}
            {document.comments && document.comments.length > 0 && (
              <Badge style={{ marginLeft: 10 }} color="danger">
                {document.comments && document.comments.length}
              </Badge>
            )}
          </span>
          <div style={{ marginRight: 10, textAlign: 'left', fontWeight: 500 }}>{document.description}</div>
        </div>

        <div className="document-status" style={{ width: '15%' }}>
          <span>
            {t(`pages.documents.step.status.${document.agentInfo?.status}`)}
            {document.agentInfo?.done ? (
              <FaCheck style={{ color: Colors.status.done, marginLeft: 10 }} />
            ) : StatusCategory.todoSupport.includes(document.supportInfo?.status as DocumentStatus) ? (
              <FaPause style={{ width: 10, color: Colors.status.todoSupport, marginLeft: 10 }} />
            ) : (
              <FaTimes style={{ color: Colors.status.todoAgent, marginLeft: 10 }} />
            )}
          </span>
          <span>
            {document.agentInfo?.date ? `MàJ le: ${moment(document.agentInfo?.date as number).format('L')}` : '/'}
          </span>
        </div>

        <div className="document-status" style={{ width: '15%' }}>
          <span>
            {t(`pages.documents.step.status.${document.supportInfo?.status}`)}
            {document.supportInfo?.done ? (
              <FaCheck style={{ color: Colors.status.done, marginLeft: 10 }} />
            ) : StatusCategory.todoSupport.includes(document.supportInfo?.status as DocumentStatus) ? (
              <FaTimes style={{ color: Colors.status.todoAgent, marginLeft: 10 }} />
            ) : (
              <FaPause style={{ width: 10, color: Colors.status.todoSupport, marginLeft: 10 }} />
            )}
          </span>
          <span>
            {document.supportInfo?.date ? `MàJ le: ${moment(document.supportInfo?.date as number).format('L')}` : '/'}
          </span>
        </div>

        <div className="document-status" style={{ width: '15%' }}>
          <span>
            {t(`pages.documents.step.status.${document.complianceInfo?.status}`)}

            {document.complianceInfo?.status !== 'N/A' &&
              (document.complianceInfo?.done ? (
                <FaCheck style={{ color: Colors.status.done, marginLeft: 10 }} />
              ) : StatusCategory.todoSupport.includes(document.complianceInfo?.status as DocumentStatus) ? (
                <FaTimes style={{ color: Colors.status.todoAgent, marginLeft: 10 }} />
              ) : (
                <FaPause style={{ width: 10, color: Colors.status.todoSupport, marginLeft: 10 }} />
              ))}
          </span>
          <span>
            {document.complianceInfo?.status !== 'N/A' &&
              (document.supportInfo?.date
                ? `MàJ le: ${moment(document.supportInfo?.date as number).format('L')}`
                : '/')}
          </span>
        </div>

        <div className="document-actions" style={{ width: '15%' }}>
          <div className="user-actions">
            <ButtonPopover
              Icon={FaCubes}
              disabled={!document.permission?.template}
              onClick={downloadTemplate}
              description={t(`pages.documents.step.actions.template`)}
            />
            <ButtonPopover
              Icon={FaEye}
              disabled={!document.permission?.preview}
              onClick={openDocument}
              description={t(`pages.documents.step.actions.preview`)}
            />
            <ButtonPopover
              Icon={FaDownload}
              disabled={!document.permission?.download}
              onClick={downloadDocument}
              description={t(`pages.documents.step.actions.download`)}
            />
            <ButtonPopover
              Icon={FaEdit}
              disabled={!canEdit}
              onClick={editDocument}
              description={t(`pages.documents.step.actions.edit`)}
            />
            <ButtonPopover
              Icon={FaUpload}
              disabled={!canUpload}
              onClick={uploadDocument}
              description={t(`pages.documents.step.actions.upload`)}
            />
            <ButtonPopover
              Icon={FaLink}
              disabled={!canLink}
              onClick={linkDocument}
              description={t(`pages.documents.step.actions.link`)}
            />
            <ButtonPopover
              Icon={FaTrash}
              disabled={!canTrash}
              onClick={removeDocument}
              description={t(`pages.documents.step.actions.remove`)}
            />
          </div>
        </div>
      </div>

      <div className="document-validation-container">
        <div className="document-name" style={{ width: '40%' }} />
        <div className="document-status" style={{ width: '15%' }} />
        <div className="document-status" style={{ width: '15%' }}>
          {currentUser?.type === 'support-user' && n1SupportTypes.includes(currentSupportUser?.role) && (
            <span className="document-validation">
              {document.supportInfo?.status === 'TO_VERIFY' && (
                <Button variant="contained" color="primary" onClick={accept}>
                  {t('pages.documents.step.actions.validate')}
                </Button>
              )}
              {document.supportInfo?.status === 'TO_VERIFY' && (
                <Button variant="contained" color="secondary" onClick={refuse}>
                  {t('pages.documents.step.actions.refuse')}
                </Button>
              )}
              {(document.supportInfo?.status === 'ACCEPTED' || document.supportInfo?.status === 'REFUSED') && (
                <Button variant="contained" color="default" onClick={undoAcceptance}>
                  {t('pages.documents.step.actions.cancel')}
                </Button>
              )}
            </span>
          )}
        </div>
        <div className="document-status" style={{ width: '15%' }}>
          {currentUser?.type === 'support-user' && n2SupportTypes.includes(currentSupportUser?.role) && (
            <span className="document-validation">
              {document.complianceInfo?.status === 'TO_APPROVE' && (
                <Button variant="contained" color="primary" onClick={approve}>
                  {t('pages.documents.step.actions.validate')}
                </Button>
              )}
              {document.complianceInfo?.status === 'TO_APPROVE' && (
                <Button variant="contained" color="secondary" onClick={reject}>
                  {t('pages.documents.step.actions.refuse')}
                </Button>
              )}
              {(document.complianceInfo?.status === 'APPROVED' || document.complianceInfo?.status === 'REJECTED') && (
                <Button variant="contained" color="default" onClick={undoCompliance}>
                  {t('pages.documents.step.actions.cancel')}
                </Button>
              )}
            </span>
          )}
        </div>
        <div className="document-actions" style={{ width: '15%' }} />
      </div>

      <div className="comments-container">
        <div className="comments-header">
          <span style={{ marginRight: 10 }}>{t('pages.documents.step.comments.title')}</span>

          {document.commentsIsExpand && (
            <ButtonPopover
              Icon={FaMinusCircle}
              onClick={toggleComments}
              description={t('pages.documents.step.comments.reduce')}
            />
          )}
          {!document.commentsIsExpand && (
            <ButtonPopover
              Icon={FaPlusCircle}
              onClick={toggleComments}
              description={t('pages.documents.step.comments.expand')}
            />
          )}
        </div>
        {document.commentsIsExpand && (
          <div className="comments">
            {Number(document.comments?.length) > 0 && (
              <div className="discussion" ref={discussionRef}>
                {document.comments?.map((comment, index) => (
                  <Card
                    key={`comment-${index}`}
                    className={`comment ${
                      (comment.role === 'CLIENT' && currentUser?.type === 'user') ||
                      (comment.role === 'SUPPORT' && currentUser?.type === 'support-user')
                        ? 'right-comment'
                        : 'left-comment'
                    }`}>
                    {comment.content}
                  </Card>
                ))}
              </div>
            )}
            <div className="comment-form">
              <TextField
                inputRef={register({ required: true })}
                name="comment"
                label={t('pages.documents.step.comments.add.label')}
                variant="outlined"
                onKeyPress={onCommentPress}
                error={errors.comment?.type === 'required'}
              />
              <Button onClick={handleSubmit(addComment)} color="primary" variant="outlined">
                <FaPaperPlane />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Document;
