import { logout, setAccessToken } from '@store/auth/auth.action';
import { AppState } from '@store/configuration';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useHistory } from 'react-router';

interface PrivateRouteProps extends RouteProps {
  component: any;
  agent?: boolean;
  support?: boolean;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accessToken = useSelector((state: AppState) => state.auth.accessToken);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const [connected, setConnected] = useState(true);

  useEffect(() => {
    if (accessToken) {
      const accessTokenDecoded: any = jwt_decode(accessToken as string);
      const timestamp: number = Math.round(Date.now() / 1000);

      if (accessTokenDecoded && accessTokenDecoded.exp && timestamp < accessTokenDecoded.exp) {
        setConnected(true);
      } else {
        setConnected(false);
        dispatch(logout());
        history.push('/login');
      }
    }
  }, [accessToken, dispatch, history]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return connected ? (
          (currentUser?.type === 'user' && rest.agent) || (currentUser?.type === 'support-user' && rest.support) ? (
            <Component {...props} />
          ) : (
            <Redirect to={props.location} />
          )
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
