import { DocumentStatus } from '@models/agent.model';
import { Slide } from 'react-toastify';

export const Colors = {
  status: {
    todoAgent: '#ff7272',
    todoSupport: '#ffaa6a',
    done: '#05e657',
  },
  source: {
    agent: '#bdbdbd',
    support: '#389eff',
    compliance: '#05e657',
  },
  treezor: {
    blue: '#389eff',
  },
};

export const StatusCategory = {
  todoSupport: ['TO_VERIFY', 'TO_PROVIDE'] as DocumentStatus[],
  todoCompliance: ['TO_APPROVE'] as DocumentStatus[],
  done: ['APPROVED'] as DocumentStatus[],
};

export const ToastOptions = {
  autoClose: 2500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  transition:Slide
}